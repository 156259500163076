export interface TextAreaProps {
  name: string;
  value?: any;
  handleChange?: any;
  placeholder?: string;
  disable?: boolean;
  required?: boolean;
  ref?: string | any;
  cols?: number;
  rows?: number;
}

const TextArea: React.FC<TextAreaProps> = ({
  required,
  disable,
  value,
  handleChange,
  name,
  placeholder,
  ref,
  cols,
  rows,
}) => {
  return (
    <textarea
      ref={ref}
      cols={cols || 10}
      rows={rows || 10}
      required={required}
      disabled={disable}
      value={value}
      onChange={handleChange}
      name={name}
      placeholder={placeholder || "Place holder"}
      className="w-full font-rom rounded-md md:text-sm text-xs p-2 border-2 border-softGray focus:ring-1 focus:outline-none"
    />
  );
};

export default TextArea;
