import ToastHandler from "../../components/toast/ToastHandler";
import axiosCovid from "../../config/AxiosCovid";
import ApplyChip from "../../helpers/applyChip/ApplyChip";
import { queryparamsAuthData } from "../../pages/authenticationData/helpers/List";

class AuthDataRequest {
  static getAuthData = async (authDataDispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      let isStatusTrue: boolean = false;
      const status = query.get("status") || "";
      if (status?.length > 0) {
        isStatusTrue = true;
      }

      const results: any = await axiosCovid().get(
        isStatusTrue
          ? `labeler/authentication`
          : `labeler/authentication?status=6&status=7&status=8&status=9&status=10&status=11`,
        {
          params: {
            ...(query.get("status") !== null && {
              status: query.get("status"),
            }),
            ...(query.get("product_name") !== null && {
              product_id: query.get("product_name"),
            }),
            ...(query.get("category") !== null && {
              product_category_id: query.get("category"),
            }),
            ...(query.get("brand") !== null && {
              legit_check_brand_id: query.get("legit_check_brand_id"),
            }),
            ...(query.get("legit_check_code") !== null && {
              code: query.get("legit_check_code"),
            }),
            ...(query.get("transaction_from") !== null &&
            query.get("transaction_to") !== null
              ? {
                  created_at: `${query.get("transaction_from")},${query.get(
                    "transaction_from"
                  )}`,
                }
              : ""),
            ...(query.get("transaction_from") !== null &&
            query.get("transaction_to") === null
              ? { created_at: `${query.get("transaction_from")},2100-01-01` }
              : ""),
            ...(query.get("transaction_from") === null &&
            query.get("transaction_to") !== null
              ? { created_at: `2000-01-01,${query.get("transaction_to")}` }
              : ""),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "updated_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      ApplyChip(queryparamsAuthData, authDataDispatch);
      return results;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getAuthDetail = async (id: string) => {
    try {
      const res = await axiosCovid().get(`labeler/authentication/${id}`);
      const result = res.data;
      return result;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static patchAuthData = async (
    code: string,
    data: any,
    handleHideModal: any
  ) => {
    try {
      if (data?.update_product_type === "manual") {
        delete data.product_id;
      } else {
        delete data.product_category_id;
        delete data.name;
        delete data.legit_check_brand_id;
      }

      const res = await axiosCovid().patch(
        `labeler/authentication/${code}`,
        data
      );
      const result = res.data;
      ToastHandler("#00C62E", "Edit Complete");
      handleHideModal();
      return result;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };
}

export default AuthDataRequest;
