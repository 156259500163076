import React, { useReducer } from "react";
import { useQuery } from "react-query";
import { TypeRequest } from "../../../../apis/admin/type/TypeRequest";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import { optRoutes } from "../helpers/list";
import TypeCard from "./TypeCard";
import Loading from "../../../../components/loading/Loading";
import {
  initialTypeState,
  TypeContextProvider,
} from "../../../../store/admin/type/TypeContext";
import { TypeReducer } from "../../../../store/admin/type/TypeReducer";
import EditTypeCard from "./EditTypeCard";

const LegitChekType = () => {
  const [typeState, typeDispatch] = useReducer(TypeReducer, initialTypeState);
  const { showEdit } = typeState;

  const { data, isLoading } = useQuery(
    ["getType"],
    () => TypeRequest.getType(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return (
    <TypeContextProvider value={{ typeDispatch, typeState }}>
      <div className="pt-28">
        <DynamicBreadcrumb data={optRoutes} />

        {showEdit.status && <EditTypeCard />}

        {isLoading ? (
          <div className="mt-14 w-full justify-center flex">
            <Loading />
          </div>
        ) : (
          <div className="my-14">
            <div>
              <h1 className="text-lg">Legit Check Type</h1>
            </div>

            {data?.data?.data?.map((el: any) => (
              <TypeCard data={el} key={el.id} />
            ))}
          </div>
        )}
      </div>
    </TypeContextProvider>
  );
};

export default LegitChekType;
