import React, { useContext } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import ApplyChip from "../../../../helpers/applyChip/ApplyChip";
import SetQueryString from "../../../../helpers/query_searchParams/SetQueryString";
import NotificationContext from "../../../../store/admin/notification/NotificationContext";
import { queryparamsNotifSchedule } from "../helpers/list";

const FilterNotifSchedule = () => {
  const history = useHistory();
  const { pathname, search, hash } = useLocation();
  const queryClient = useQueryClient();
  const { notificationDispatch } = useContext(NotificationContext);

  const handleShowFilter = () => {
    notificationDispatch({ type: "show_filter_see_all" });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    SetQueryString(
      queryparamsNotifSchedule,
      value,
      name,
      search,
      pathname,
      history,
      hash
    );
  };

  const applyFilter = () => {
    queryClient.invalidateQueries("getNotificationSchedule");
    ApplyChip(queryparamsNotifSchedule, notificationDispatch);
    history.push({ pathname, search, hash });
    setTimeout(() => notificationDispatch({ type: "show_filter" }), 500);
  };
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div
        className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden w-2/5 h-2/5 shadow-xl transform transition-all md:max-w-2xl max-w-lg"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 py-7 flex justify-between items-center w-full h-1/4">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-inputColor"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full " />
        <div className="overflow-hidden h-2/4">
          {/* <div className="flex px-10 items-center md:h-1/2">
            <div className="mr-12 w-96">
              <h1 className="md:text-base text-sm text-inputColor">Status</h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="true">On Selling</option>
                <option value="false">Not Active</option>
              </select>
            </div>
            <div className="w-96">
              <h1 className="md:text-base text-sm text-inputColor">
                Available for
              </h1>
              <select
                onChange={handleChangeFilter}
                name="available_for"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray  focus:ring-1 focus:outline-none"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="0">All Users</option>
                <option value="1">Consignment</option>
                <option value="4">Cosignment VIP</option>
                <option value="2">Gift</option>
                <option value="3">Tester</option>
              </select>
            </div>
          </div>
          <hr className="w-ful " /> */}
          <div className="flex px-10 items-center h-full w-full">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-inputColor">
                Notification Type
              </h1>
              <select
                onChange={handleChangeFilter}
                name="notification_type"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray  focus:ring-1 focus:outline-none"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="0">Repeat</option>
                <option value="1">Single Send</option>
              </select>
            </div>
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex justify-center items-center h-1/4">
          <SmallButton
            type={"button"}
            color={"bg-softPink"}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={applyFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterNotifSchedule;
