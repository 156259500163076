import axios from 'axios'
import LocalStorage from './LocalStorage'
// import { getWithExpiry } from '../helpers/setToken/SetToken';

const axiosMercury = () => {
	let value = LocalStorage.AUTH_TOKEN
	// const value = getWithExpiry("AUTH_TOKEN");
	return axios.create({
		baseURL: process.env.REACT_APP_MERCURY_API_BASE_URL,
		headers: {
			Authorization: `Bearer ${value}`,
		},
	})
}

export default axiosMercury