import { ActionType, NavbarAction } from "./action";
import { IinitialNavbarState } from "./interfaces";

export const NavbarReducer = (
  state: IinitialNavbarState,
  action: NavbarAction
) => {
  switch (action.type) {
    case ActionType.ShowSidebar:
      document.body.classList[action.payload ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showSidebar: action.payload };
    case ActionType.ShowAuthenticationList:
      if (state.dropdownAuthentication) {
        return { ...state, dropdownAuthentication: false };
      } else {
        return { ...state, dropdownAuthentication: true };
      }
  }
};
