import React, { useEffect, useState } from "react";
import Input from "../../../../components/input/Input";
import CircularLoad from "../../../../components/loading/CircularLoad";
import { useFormik } from "formik";
import { formatRupiah } from "../../../../helpers/format_rupiah/FormatRupiah";
import MultiSelect from "react-multi-select-component";
import { useMutation, useQueryClient } from "react-query";
import { BrandRequest } from "../../../../apis/admin/brand/BrandRequest";
import { useHistory, useParams } from "react-router-dom";

interface IProps {
  data: any;
  dataCategoryGroup: any;
  isLoadingCategoryGroup: boolean;
}

const FormEditBrand: React.FC<IProps> = ({
  data,
  dataCategoryGroup,
  isLoadingCategoryGroup,
}) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const [categoryGroup, setCategoryGroup] = useState<any>([]);

  useEffect(() => {
    setValues({
      ...values,
      is_insurance_required: data.is_insurance_required,
      is_active: data.is_active,
      legit_check_type_id: data.legit_check_type_id !== null ? data.legit_check_type_id : '',
    });

    let temp: any[] = [];
    data.list_product_category_group.map((el: any) =>
      temp.push({ ...categoryGroup, label: el.name, value: el.id })
    );
    setCategoryGroup(temp);
  }, [data]);
  
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      is_insurance_required: "",
      legit_check_type_id: "",
      is_active: "",
    },
    onSubmit: async (values: any) => {
      const form_data = new FormData();
      form_data.append("is_active", values.is_active);
      form_data.append("legit_check_type_id", values.legit_check_type_id);
      form_data.append("is_insurance_required", values.is_insurance_required);
      for (const key in categoryGroup) {
        form_data.append(
          "list_product_category_group_id",
          categoryGroup[key].value
          );
        }
        await mutation.mutate(form_data);
      },
    });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (form_data: any) =>
      BrandRequest.editBrand(form_data, +params.id, setSubmitting, () =>
        history.goBack()
      ),
    {
      onMutate: () => {
        console.log("mutate edit brand");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getBrand");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="flex w-full justify-between mt-14 mb-24"
    >
      <div className="md:w-1/3 w-full h-full rounded-md border-2 border-softGray">
        <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">brand Image</h1>
        </div>
        <hr />

        <div className="md:mx-8 mx-5 my-7">
          <div>
            <h1 className="md:text-base text-sm text-mobile text-inputColor">
              Display Image
            </h1>
            <div className="w-full flex justify-center">
              <img
                src={data?.logo_image_url}
                alt="img"
                className="my-3 rounded-lg shadow-lg w-10/12"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
        <div className="rounded-md rounded-b-none border-2 border-softGray">
          <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Brand Information
            </h1>
          </div>
          <hr />

          <div className="md:flex w-full">
            <div className="md:px-8 mt-6 md:w-full flex justify-between">
              <div className="w-1/2 mr-7">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Brand
                  </h1>
                  <Input
                    name="name"
                    type="text"
                    disable={true}
                    handleChange={handleChange}
                    value={data.name}
                    placeholder="Place Holder"
                  />
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Price (Standard)
                  </h1>
                  <Input
                    name="price_without_insurance"
                    type={"text"}
                    disable={true}
                    handleChange={handleChange}
                    value={formatRupiah(data.price_without_insurance)}
                    placeholder="Place Holder"
                  />
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Service
                  </h1>
                  <select
                    onChange={handleChange}
                    value={values.is_insurance_required}
                    name={"is_insurance_required"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="false">Standard & Insurance</option>
                    <option value="true">Insurance</option>
                  </select>
                </div>
              </div>
              <div className="w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Status
                  </h1>
                  <select
                    onChange={handleChange}
                    value={values.is_active}
                    name={"is_active"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="true">Active</option>
                    <option value="false">Not Active</option>
                  </select>
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Price (Insurance)
                  </h1>
                  <Input
                    name="price"
                    type={"text"}
                    disable={true}
                    handleChange={handleChange}
                    value={formatRupiah(data.price)}
                    placeholder="Place Holder"
                  />
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Legit Check type
                  </h1>
                  <select
                    onChange={handleChange}
                    value={values.legit_check_type_id}
                    name={"legit_check_type_id"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="0" hidden>Select Here</option>
                    <option value="1">streetwear</option>
                    <option value="2">hype</option>
                    <option value="3">deluxe</option>
                    <option value="4">exclusive</option>
                    <option value="5">Luxury</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="my-1.5 w-full px-8 mb-7">
            <h1 className="md:text-base text-sm text-inputColor">
              Available for
            </h1>
            {isLoadingCategoryGroup ? (
              <div></div>
            ) : (
              <MultiSelect
                isLoading={isLoadingCategoryGroup ? true : false}
                debounceDuration={0}
                className="my-3 font-rom text-sm"
                options={dataCategoryGroup}
                value={categoryGroup}
                onChange={setCategoryGroup}
                labelledBy="Select"
              />
            )}
          </div>
        </div>

        <div className="rounded-md rounded-t-none border-2 border-t-0 border-softGray flex w-full md:h-18 h-16 mb-10">
          <div className="w-1/2 flex items-center justify-center border-r-2 border-softGray ">
            <button
              type="reset"
              onClick={() => handleReset({})}
              className="focus:outline-none"
            >
              <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                Clear
              </h1>
            </button>
          </div>
          <div className="w-1/2 flex items-center justify-center">
            <button type="submit" className="focus:outline-none">
              {isSubmitting ? (
                <CircularLoad color={"black"} height={16} width={16} />
              ) : (
                <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                  Save
                </h1>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormEditBrand;
