import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatRupiah } from "../../../../helpers/format_rupiah/FormatRupiah";

interface IProps {
  data: any;
}

const PackageListCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <div className="w-full h-auto border-2 border-softGray rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-4">
        <div className="border-r-2 border-softGray flex justify-center">
          <img
            src={data.image_path}
            alt="banner_img"
            className="w-32 m-1 rounded-md shadow-lg"
          />
        </div>

        <div className="flex flex-col pl-7 justify-evenly py-3 border-r-2 border-softGray">
          <h1 className="text-xl">{data.name}</h1>
          <h1 className="text-base font-rom">
            Available for:{" "}
            {data.available_for === 0
              ? "All Users"
              : data.available_for === 1
              ? "Consignment"
              : data.available_for === 2
              ? "Gift"
              : data.available_for === 3
              ? "Tester"
              : data.available_for === 4
              ? "Consignment VIP"
              : "-"}
          </h1>
          <div className="flex">
            <h1 className="mr-1 text-lg">Status :</h1>
            {data.is_available_to_buy ? (
              <h1 className="text-success text-lg">On Selling</h1>
            ) : (
              <h1 className="text-softPink text-lg">Not Active</h1>
            )}
          </div>
        </div>

        <div className="flex flex-col justify-evenly py-3 px-10 border-r-2 border-softGray">
          <h1 className="text-base font-rom">Package Price</h1>
          <h1 className="text-lg">{formatRupiah(data.buy_price)}</h1>
          <h1 className="text-base font-rom">
            Package for:
            {data.is_for_insurance ? " Insurance" : " Not Insurance"}
          </h1>
        </div>

        <div className="flex items-center justify-evenly mx-10">
          <div className="flex justify-evenly w-full">
            <h1
              onClick={() => history.push(`${url}/edit-package/${data.id}`)}
              className="text-base hover:text-softPink cursor-pointer"
            >
              Edit
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageListCard;
