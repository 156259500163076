import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQueries } from "../../helpers/query_searchParams/QuerySearchParams";

interface IPagination {
  offset: number;
  limit: number;
  length: number;
  total: number;
  dispatchContext: any;
}

const NonTablePagination: React.FC<IPagination> = ({
  offset,
  limit,
  length,
  total,
  dispatchContext,
}) => {
  const query = useQueries(useLocation);
  const history = useHistory();
  const { pathname, search } = useLocation();
  const offsetParams = query.get("offset");
  const limitParams = query.get("limit");

  const handleDownPage = () => {
    let totalOffset = Number(offsetParams) - Number(limitParams);
    dispatchContext({
      type: "handle_offset",
      payload: totalOffset,
    });
    let new_search: any =
      offsetParams !== null &&
      search.replace(`offset=${offsetParams}`, `offset=${totalOffset}`);
    history.push({
      pathname,
      search: new_search,
    });
  };

  const handleUpPage = () => {
    let totalOffset = Number(offsetParams) + Number(limitParams);
    dispatchContext({
      type: "handle_offset",
      payload: totalOffset,
    });
    let new_search: any =
      offsetParams !== null &&
      search.replace(`offset=${offsetParams}`, `offset=${totalOffset}`);
    history.push({
      pathname,
      search: new_search,
    });
  };

  const setRows = (e: any) => {
    let value = e.target.value;
    dispatchContext({
      type: "handle_limit",
      payload: Number(value),
    });
    dispatchContext({
      type: "handle_offset",
      payload: 0,
    });
    let new_search: any =
      offsetParams !== null &&
      limitParams !== null &&
      search.replace(
        `limit=${limitParams}&&offset=${offsetParams}`,
        `limit=${value}&&offset=0`
      );
    history.push({
      pathname,
      search: new_search,
    });
  };

  const handleToFirstPage = () => {
    dispatchContext({
      type: "handle_offset",
      payload: 0,
    });
    let new_search: any =
      offsetParams !== null &&
      search.replace(`offset=${offsetParams}`, `offset=0`);
    history.push({
      pathname,
      search: new_search,
    });
  };

  const handleToLastPage = () => {
    const summary =
      Number(limitParams) * Math.floor(total / Number(limitParams));
    dispatchContext({
      type: "handle_offset",
      payload: summary,
    });
    let new_search: any =
      offsetParams !== null &&
      search.replace(`offset=${offsetParams}`, `offset=${summary}`);
    history.push({
      pathname,
      search: new_search,
    });
  };

  return (
    <div className="flex w-full justify-between -mt-2 my-4">
      <div className="flex items-center">
        <h1 className="text-base">Rows</h1>
        <select
          onChange={setRows}
          name="rows"
          value={limitParams || "10"}
          className="h-12 ml-4 focus:outline-none text-base cursor-pointer"
        >
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="25">25</option>
        </select>
      </div>

      <div className="flex flex-col items-center">
        <div className="flex items-center">
          {Number(offsetParams) === 0 ? (
            <h1 className="text-gray-400 cursor-default mr-3">1</h1>
          ) : (
            <h1
              onClick={handleToFirstPage}
              className="cursor-pointer text-black hover:text-opacity-70  mr-3"
            >
              1
            </h1>
          )}
          <div className="h-12 w-12 mr-1 flex justify-center items-center rounded-full">
            {Number(offsetParams) === 0 ? (
              <h1 className="text-base text-gray-400 cursor-default">Prev</h1>
            ) : (
              <h1
                onClick={handleDownPage}
                className="text-base text-black cursor-pointer hover:text-opacity-70"
              >
                Prev
              </h1>
            )}
          </div>
          <div className="flex h-12 font-medium rounded-full">
            <div className="w-12 md:flex justify-center items-center hidden cursor-default leading-5 transition duration-150 ease-in rounded-full text-base">
              {Number(offsetParams) !== 0 && Number(limitParams) !== 0
                ? Number(offsetParams) / Number(limitParams) + 1
                : offset / limit + 1}
            </div>
          </div>
          <div className="h-12 w-12 ml-1 flex justify-center items-center rounded-full">
            {length === total - Number(offsetParams) ? (
              <h1 className="text-base text-gray-400 cursor-default">Next</h1>
            ) : (
              <h1
                onClick={handleUpPage}
                className="text-base text-black cursor-pointer hover:text-opacity-70"
              >
                Next
              </h1>
            )}
          </div>
          {length === total - Number(offsetParams) ? (
            <h1 className="text-gray-400 cursor-default ml-3">
              {`${Math.ceil(total / (limitParams as any))}`}
            </h1>
          ) : (
            <h1
              onClick={handleToLastPage}
              className="cursor-pointer text-black hover:text-opacity-70 ml-3"
            >
              {`${Math.ceil(total / Number(limitParams))}`}
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default NonTablePagination;
