import React, { useReducer } from "react";
import { listmenu, profile } from "../../assets/icons";
import LocalStorage from "../../config/LocalStorage";
import { ActionType } from "../../store/navbar/action";
import {
  initialNavbarState,
  NavbarContextProvider,
} from "../../store/navbar/NavbarContext";
import { NavbarReducer } from "../../store/navbar/NavbarReducer";
import Sidebar from "../sidebar/Sidebar";

function Navbar() {
  const [NavbarState, NavbarDispatch] = useReducer(
    NavbarReducer,
    initialNavbarState
  );
  const role = LocalStorage.ROLE;

  const renderTitle = (role: string) => {
    const helper: { [key: string]: string } = {
      admin: "Legit Check",
      inspector: "Legit Check",
      labeler: "Labeling Product",
    };
    const title: string = helper[role];
    return title;
  };
  
  return (
    <NavbarContextProvider value={{ NavbarState, NavbarDispatch }}>
      <Sidebar />

      <nav className="font-med fixed z-40 flex flex-wrap items-center justify-between xl:px-16 md:px-12 px-8 py-2 w-full md:h-20 h-14 bg-black shadow">
        <div className="w-full flex items-center justify-between">
          <div className="flex justify-between">
            <img
              src={listmenu}
              className="md:w-5 w-4 cursor-pointer"
              alt="menu"
              onClick={() =>
                NavbarDispatch({
                  payload: true,
                  type: ActionType.ShowSidebar,
                })
              }
            />
          </div>

          <div className="flex items-center mx-auto w-2/3 justify-center">
            <h1 className="text-softPink md:mr-1.5 mr-1 md:text-2xl text-base text-center">
              DEPATU
            </h1>
            <h1 className="text-white md:text-2xl text-base">
              {renderTitle(role)}
            </h1>
          </div>

          <div className="flex items-center justify-end">
            <img src={profile} className="md:w-6 md:h-6 w-4 h-4 ml-7" alt="-" />
          </div>
        </div>
      </nav>
    </NavbarContextProvider>
  );
}

export default Navbar;
