const location = document.location.search;
const queryParams = new URLSearchParams(location);

export let default_sort = queryParams.get("sort");
if (default_sort === "created_at") {
  default_sort = "Transaction Date";
} else if (default_sort === "updated_at") {
  default_sort = "Last Modified";
} else {
  default_sort = "Status";
}
