import React, { useContext } from "react";
import { formatRupiah } from "../../../../helpers/format_rupiah/FormatRupiah";
import TypeContext from "../../../../store/admin/type/TypeContext";

interface IProps {
  data: any;
}

const TypeCard: React.FC<IProps> = ({ data }) => {
  const {typeDispatch} = useContext(TypeContext);

  return (
    <div className="w-full h-auto border-2 border-softGray rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-5">
        <div className="border-r-2 items-center border-softGray flex justify-center">
          <div>
            <h1 className="font-rom">Legit Check Type</h1>
            <h1 className="text-xl mt-1.5">{data.name}</h1>
          </div>
        </div>

        <div className="flex flex-col col-span-3 justify-between px-8  border-r-2 h-32 border-softGray">
          <div className="h-1/3 flex justify-start items-center">
            <h1>Referral Reward :</h1>
            <h1 className="ml-0.5 text-softPink">{` ${
              data.referral_reward_percent * 100
            } %`}</h1>
          </div>
          <div className="h-2/3 w-full grid grid-cols-3 gap-10 items-center">
            <div className="border-r-2">
              <h1 className="font-rom ">Price (Insurance)</h1>
              <h1 className="mt-1.5">{formatRupiah(data.price | 0)}</h1>
            </div>
            <div className="border-r-2">
              <h1 className="font-rom">Price (Standard)</h1>
              <h1 className="mt-1.5">
                {formatRupiah(data.price_without_insurance | 0)}
              </h1>
            </div>
            <div className="">
              <h1 className="font-rom">Updated by</h1>
              <h1 className="mt-1.5">{data.updated_by}</h1>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-evenly mx-10">
          <div className="flex justify-evenly w-full">
            <h1
              onClick={() =>
                typeDispatch({
                  type: "show_edit",
                  payload: {
                    status: true,
                    id: data.id,
                  },
                })
              }
              className="text-base hover:text-softPink cursor-pointer"
            >
              Edit
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TypeCard;
