import ToastHandler from "../../../components/toast/ToastHandler";
import axiosCovid from "../../../config/AxiosCovid";
import ApplyChip from "../../../helpers/applyChip/ApplyChip";
import { queryparams as queryPackage } from "../../../pages/admin/package_list/helpers/List";

class PackageListRequest {
  static getPackageList = async (
    key: string,
    sortValue: string,
    isAsc: string,
    limit: number,
    offset: number,
    filter: any,
    packageListDispatch: any
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosCovid().get("admin/package-list", {
        params: {
          ...(query.get("is_available_to_buy") !== null && {
            is_available_to_buy:
              query.get("is_available_to_buy") || filter.status,
          }),
          ...(query.get("available_for") !== null && {
            available_for: query.get("available_for") || filter.available_for,
          }),
          ...(query.get("name") !== null && {
            name: query.get("name") || filter.package_name,
          }),
          limit: query.get("limit") !== null ? query.get("limit") : limit,
          offset: query.get("offset") !== null ? query.get("offset") : offset,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : sortValue
          } ${query.get("isAsc") !== null ? query.get("isAsc") : isAsc}`,
        },
      });
      ApplyChip(queryPackage, packageListDispatch);
      return results;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getPackageDetail = async (id: string) => {
    try {
      const res = await axiosCovid().get(`admin/package-list/${id}`);
      const result = res.data;
      return result;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static sendPackage = async (
    values: any,
    handleHideModal: any,
    setSubmitting: any
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().post(
        `admin/package-list/${values.package_list}/send-package`,
        {
          username: values.username,
          package_coupon_effect_id: values.package_coupon_effect_id,
          quantity: values.quantity,
          validity_period: values.validity_period,
        }
      );
      handleHideModal();
      setSubmitting(false);
      ToastHandler("#00C62E", "Sent Completed");
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
      setSubmitting(false);
    }
  };

  static addPackage = async (
    payload: any,
    setSubmitting: any,
    handleBack: any
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().post("admin/package-list", payload);
      ToastHandler("#00C62E", "Add Completed");
      setSubmitting(false);
      handleBack();
    } catch (error) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ToastHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ToastHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static editPackage = async (
    payload: any,
    setSubmitting: any,
    handleBack: any,
    id: number
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().patch(`admin/package-list/${id}`, payload);
      ToastHandler("#00C62E", "Edit Completed");
      setSubmitting(false);
      handleBack();
    } catch (error) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ToastHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ToastHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };
}

export default PackageListRequest;
