import React from "react";
import { useHistory, useParams } from "react-router-dom";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../../components/loading/Loading";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRouteseditNotifSchedule } from "../helpers/list";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Input from "../../../../components/input/Input";
import NewFilePonds from "../../../../components/input/NewFilepond";
import CircularLoad from "../../../../components/loading/CircularLoad";
import Toggle from "../../../../components/toggleBtn/Toggle";
import {
  loadOptionsBrand,
  loadOptionsCategory,
  loadOptionsCategoryGroup,
  loadOptionsProduct,
  loadOptionsProductStore,
  loadOptionsStore,
  loadOptionsTag,
  loadOptionsUsernameById,
  loadPackageList,
} from "../../../../helpers/loadOption/LoadOption";
import AsyncSelect from "react-select/async";
import FormHandleEditSchedule from "../helpers/form_handle/FormHandleEditSchedule";
import UseFetchNotifScheduleById from "../helpers/Fetch_hooks/UseFetchNotifScheduleById";

const EditNotifSchedule = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const { data, isLoading } = UseFetchNotifScheduleById();
  const {
    handleChange,
    handleSubmit,
    setValues,
    errors,
    values,
    touched,
    handleReset,
    isSubmitting,
    setFile,
    brand,
    setBrand,
    tag,
    setTag,
    category,
    setCategory,
    category_group,
    setCategoryGroup,
  } = FormHandleEditSchedule(data);

  return (
    <>
      <NavDetail
        title1={"Edit"}
        title2={"Notification Schedule"}
        handleBack={() => history.goBack()}
      />
      <div className="pt-28">
        <DynamicBreadcrumb data={optRouteseditNotifSchedule(params.id)} />
        {isLoading ? (
          <div className="w-full flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
              <div className="md:w-1/3 w-full h-full rounded-md border-2 border-softGray">
                <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                  <h1 className="font-medium md:text-lg text-base">
                    Notification Images
                  </h1>
                </div>
                <hr />

                <div className="md:mx-8 mx-5 md:my-6 my-5">
                  <div>
                    <h1 className="md:text-base text-sm text-mobile text-inputColor">
                      Display Image
                    </h1>
                    <h1 className="md:text-xs font-rom text-inputColor md:mt-2 mt-1 mb-1">
                      (4 x 3), (PNG,JPG,JPEG) max. 1 MB
                    </h1>
                    <div className="flex justify-center mt-2">
                      <img src={data?.image_url ? data.image_url : data.logo} alt="img" className="w-40" />
                    </div>
                    <NewFilePonds
                      setFiles={setFile}
                      imageType={["image/png", "image/jpg", "image/jpeg"]}
                      cropRatio={"4:3"}
                      maxFileSize={"1MB"}
                    />
                  </div>
                </div>
              </div>

              <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
                <div className="rounded-md rounded-b-none border-2 border-softGray">
                  <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
                    <h1 className="font-medium md:text-lg text-base">
                      Notification Information
                    </h1>
                  </div>
                  <hr className="border-t-2" />

                  <div className="md:flex w-full px-8 py-6">
                    <div className="md:w-full">
                      <div>
                        <h1 className="md:text-base text-sm mb-1 text-inputColor">
                          Notification for
                        </h1>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="gender"
                            name="gender1"
                            row
                            value={values.radio}
                            onChange={(e: any) =>
                              setValues({ ...values, radio: e.target.value })
                            }
                          >
                            <FormControlLabel
                              value="global"
                              control={
                                <Radio className="bg-softPink to-softPink" />
                              }
                              label="Global"
                            />
                            <FormControlLabel
                              value="personal"
                              control={<Radio />}
                              label="Personal"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                      {values.radio === "personal" ? (
                        <div className="flex w-full  my-2.5">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Send to (username)
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={loadOptionsUsernameById}
                              value={{
                                values: values.username.values,
                                label: values.username.label,
                              }}
                              onChange={(username: any) =>
                                setValues({ ...values, username })
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="my-2.5">
                          <h1 className="md:text-base text-sm mb-1 text-inputColor">
                            Notification Type
                          </h1>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="gender"
                              name="repeat"
                              row
                              value={values.type}
                              onChange={(e: any) =>
                                setValues({ ...values, type: e.target.value })
                              }
                            >
                              <FormControlLabel
                                value="single"
                                control={
                                  <Radio className="bg-softPink to-softPink" />
                                }
                                label="Single"
                              />
                              <FormControlLabel
                                value="repeat"
                                control={<Radio />}
                                label="Repeat"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      )}
                      <div className="flex w-full my-2.5">
                        {values.type === "repeat" &&
                        values.radio === "global" ? (
                          <div className="w-1/2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Repeat
                            </h1>
                            <select
                              onChange={handleChange}
                              name={"recurring_types"}
                              value={values.recurring_types}
                              className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                            >
                              <option value="every_day">Every Day</option>
                              <option value="every_monday">Every Monday</option>
                              <option value="every_tuesday">
                                Every Tuesday
                              </option>
                              <option value="every_wednesday">
                                Every Wednesday
                              </option>
                              <option value="every_thursday">
                                Every Thursday
                              </option>
                              <option value="every_friday">Every Friday</option>
                              <option value="every_saturday">
                                Every Saturday
                              </option>
                              <option value="every_sunday">Every Sunday</option>
                            </select>
                          </div>
                        ) : (
                          <div className="w-1/2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Date
                            </h1>
                            <Input
                              disable={false}
                              type={"date"}
                              name={"scheduled_sent_time"}
                              value={values.scheduled_sent_time}
                              handleChange={handleChange}
                              placeholder={"Place holder"}
                            />
                          </div>
                        )}
                        <div className="w-1/2 ml-8">
                          <h1 className="md:text-base text-sm text-inputColor">
                            Clock
                          </h1>
                          <Input
                            disable={false}
                            type={"time"}
                            name={"time"}
                            value={values.time}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                        </div>
                      </div>

                      <div className="flex w-full my-2.5">
                        <div className="w-full">
                          <h1 className="md:text-base text-sm text-inputColor">
                            Subject
                          </h1>
                          <Input
                            disable={false}
                            type={"text"}
                            name={"subject"}
                            value={values.subject}
                            handleChange={handleChange}
                            placeholder={"Place holder"}
                          />
                          {errors.subject && touched.subject && (
                            <p className="text-softPink text-xs font-rom -mt-2 mb-1">
                              {errors.subject}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="flex w-full mt-1.5">
                        <div className="w-full">
                          <h1 className="md:text-base text-sm text-inputColor">
                            Description
                          </h1>
                          <textarea
                            name="description"
                            placeholder={"Place holder"}
                            className="w-full border-softGray border rounded-md px-3 py-2 font-rom h-16 mt-1.5 text-sm focus:outline-none focus:ring-1"
                            value={values.description}
                            onChange={handleChange}
                          ></textarea>
                          {errors.description && touched.description && (
                            <p className="text-softPink text-xs font-rom -mt-1 mb-1">
                              {errors.description}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="flex w-full mt-1.5">
                        <div className="w-full">
                          <h1 className="md:text-base text-sm text-inputColor">
                            Notification Action
                          </h1>
                          <select
                            onChange={handleChange}
                            name={"notification_action"}
                            value={values.notification_action}
                            className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                          >
                            <option value="0">None</option>
                            <option value="view_product_detail">
                              Product Detail (Marketplace)
                            </option>
                            <option value="view_featured_product_list">
                              Featured Product
                            </option>
                            <option value="view_app_store_page">
                              Play Store or App Store / Update App
                            </option>
                            <option value="view_product_list">
                              Product List
                            </option>
                            <option value="view_package">
                              Legit Check Package
                            </option>
                            <option value="view_multi_brand_store">
                              Store (Multi Brand)
                            </option>
                            <option value="view_multi_brand_product">
                              Product Detail (Multi Brand)
                            </option>
                          </select>
                        </div>
                      </div>

                      {values.notification_action === "view_product_detail" && (
                        <div className="flex w-full  my-2.5">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Product Name / SKU
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={loadOptionsProduct}
                              value={{
                                value: values.product_detail.values,
                                label: values.product_detail.label,
                              }}
                              onChange={(product: any) =>
                                setValues({
                                  ...values,
                                  product_detail: product,
                                })
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}

                      {values.notification_action === "view_package" && (
                        <div className="flex w-full  my-2.5">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Package List
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={loadPackageList}
                              value={{
                                value: values.package_list.values,
                                label: values.package_list.label,
                              }}
                              onChange={(package_list: any) =>
                                setValues({ ...values, package_list })
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}

                      {values.notification_action === "view_product_list" && (
                        <div>
                          <div className="flex w-full my-2.5">
                            <div className="w-full">
                              <div className="flex justify-between">
                                <h1 className="md:text-base text-sm text-inputColor">
                                  Product Category Group
                                </h1>
                                <div className="flex">
                                  <h1 className="mr-3 self-end text-sm">
                                    Active
                                  </h1>
                                  <Toggle
                                    input={category_group}
                                    handleClick={() =>
                                      setCategoryGroup(!category_group)
                                    }
                                  />
                                </div>
                              </div>
                              <AsyncSelect
                                cacheOptions
                                isDisabled={!category_group}
                                defaultOptions
                                loadOptions={loadOptionsCategoryGroup}
                                value={{
                                  value: values.product_category_group.values,
                                  label: values.product_category_group.label,
                                }}
                                onChange={(product: any) =>
                                  setValues({
                                    ...values,
                                    product_category_group: product,
                                  })
                                }
                                className="my-3.5 font-rom text-sm"
                              />
                            </div>
                          </div>

                          <div className="flex w-full my-2.5">
                            <div className="w-full">
                              <div className="flex justify-between">
                                <h1 className="md:text-base text-sm text-inputColor">
                                  Product Category
                                </h1>
                                <div className="flex">
                                  <h1 className="mr-3 self-end text-sm">
                                    Active
                                  </h1>
                                  <Toggle
                                    input={category}
                                    handleClick={() => setCategory(!category)}
                                  />
                                </div>
                              </div>
                              <AsyncSelect
                                cacheOptions
                                defaultOptions
                                isDisabled={!category}
                                loadOptions={loadOptionsCategory}
                                value={{
                                  value: values.product_category.values,
                                  label: values.product_category.label,
                                }}
                                onChange={(product: any) =>
                                  setValues({
                                    ...values,
                                    product_category: product,
                                  })
                                }
                                className="my-3.5 font-rom text-sm"
                              />
                            </div>
                          </div>

                          <div className="flex w-full my-2.5">
                            <div className="w-full">
                              <div className="flex justify-between">
                                <h1 className="md:text-base text-sm text-inputColor">
                                  Brand
                                </h1>
                                <div className="flex">
                                  <h1 className="mr-3 self-end text-sm">
                                    Active
                                  </h1>
                                  <Toggle
                                    input={brand}
                                    handleClick={() => setBrand(!brand)}
                                  />
                                </div>
                              </div>
                              <AsyncSelect
                                cacheOptions
                                isDisabled={!brand}
                                defaultOptions
                                loadOptions={loadOptionsBrand}
                                value={{
                                  value: values.brand.values,
                                  label: values.brand.label,
                                }}
                                onChange={(product: any) =>
                                  setValues({
                                    ...values,
                                    brand: product,
                                  })
                                }
                                className="my-3.5 font-rom text-sm"
                              />
                            </div>
                          </div>

                          <div className="flex w-full my-2.5">
                            <div className="w-full">
                              <div className="flex justify-between">
                                <h1 className="md:text-base text-sm text-inputColor">
                                  Tags
                                </h1>
                                <div className="flex">
                                  <h1 className="mr-3 self-end text-sm">
                                    Active
                                  </h1>
                                  <Toggle
                                    input={tag}
                                    handleClick={() => setTag(!tag)}
                                  />
                                </div>
                              </div>
                              <AsyncSelect
                                cacheOptions
                                isDisabled={!tag}
                                defaultOptions
                                loadOptions={loadOptionsTag}
                                value={{
                                  value: values.tag.values,
                                  label: values.tag.label,
                                }}
                                onChange={(tag: any) =>
                                  setValues({
                                    ...values,
                                    tag: tag,
                                  })
                                }
                                className="my-3.5 font-rom text-sm"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      {values.notification_action ===
                        "view_multi_brand_store" && (
                        <div className="flex w-full  my-2.5">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Store
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={loadOptionsStore}
                              value={{
                                value: values.store.value,
                                label: values.store.label,
                              }}
                              onChange={(store: any) =>
                                setValues({
                                  ...values,
                                  store: store,
                                })
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}

                      {values.notification_action ===
                        "view_multi_brand_product" && (
                        <div className="flex w-full  my-2.5">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Product Name / SKU
                            </h1>
                            <AsyncSelect
                              cacheOptions
                              defaultOptions
                              loadOptions={loadOptionsProductStore}
                              value={{
                                value: values.product_store.value,
                                label: values.product_store.label,
                              }}
                              onChange={(product: any) =>
                                setValues({
                                  ...values,
                                  product_store: product,
                                })
                              }
                              className="my-3.5 font-rom text-sm"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="rounded-md rounded-t-none border-2 border-t-0 border-softGray flex w-full md:h-18 h-16 mb-10">
                  <div className="w-1/2 flex items-center justify-center border-r-2 border-softGray ">
                    <button
                      type="reset"
                      onClick={() => {
                        handleReset({});
                        setFile(null);
                        const removeFile: any = document.getElementsByClassName(
                          "filepond--file-action-button filepond--action-remove-item"
                        );
                        removeFile[0].click();
                      }}
                      className="focus:outline-none"
                    >
                      <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                        Clear
                      </h1>
                    </button>
                  </div>
                  <div className="w-1/2 flex items-center justify-center">
                    <button type="submit" className="focus:outline-none">
                      {isSubmitting ? (
                        <CircularLoad color={"black"} height={16} width={16} />
                      ) : (
                        <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                          Edit
                        </h1>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default EditNotifSchedule;
