import React, { useReducer } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../../components/chip/Chip";
import CircularLoad from "../../../../components/loading/CircularLoad";
import Loading from "../../../../components/loading/Loading";
import NonTablePagination from "../../../../components/pagination/NonTablePagination";
import Sorting from "../../../../components/sorting/Sorting";
import {
  initialPackageListState,
  PackageListContextProvider,
} from "../../../../store/admin/package_list/PackageListContext";
import { PackageListReducer } from "../../../../store/admin/package_list/PackageListReducer";
import {
  default_sort_package,
  optRoutes,
  optSorting,
  queryparams,
} from "../helpers/List";
import PackageListRequest from "../../../../apis/admin/package_list/PackageListRequest";
import PackageListCard from "./PackageListCard";
import SendPackage from "./SendPackage";
import PackageListFilter from "./PackageListFilter";
import { useQueries } from "../../../../helpers/query_searchParams/QuerySearchParams";
import DefaultSort from "../../../../helpers/default_sort/DefaultSort";

const PackageList = () => {
  const query = useQueries(useLocation);
  const history = useHistory();
  const [packageListState, packageListDispatch] = useReducer(
    PackageListReducer,
    initialPackageListState
  );
  const { isAsc, sortValue, limit, offset, filter, showSend, showFilter } =
    packageListState;

  const { data, isLoading, isFetching } = useQuery(
    [
      "getPackageList",
      sortValue,
      isAsc,
      limit,
      offset,
      filter,
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      query.get("available_for"),
      query.get("is_available_to_buy"),
      query.get("name"),
    ],
    () =>
      PackageListRequest.getPackageList(
        "getPackageList",
        sortValue,
        isAsc,
        limit,
        offset,
        filter,
        packageListDispatch
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return (
    <PackageListContextProvider
      value={{ packageListDispatch, packageListState }}
    >
      <div className="pt-28">
        <DynamicBreadcrumb data={optRoutes} />

        {showSend && <SendPackage />}
        {showFilter && <PackageListFilter />}

        <div className="md:mt-12 mt-10 ">
          <div className="flex justify-end">
            <h1
              onClick={() =>
                packageListDispatch({
                  type: "show_send",
                })
              }
              className="hover:text-softPink cursor-pointer text-base"
            >
              Send Package
            </h1>
            <h1
              onClick={() => history.push("/package-list/add-package")}
              className="ml-2.5 hover:text-softPink cursor-pointer text-base"
            >
              Add Package
            </h1>
          </div>
          <div className="md:flex justify-between mt-2.5 hidden">
            <div className="flex">
              <h1
                onClick={() => packageListDispatch({ type: "show_filter" })}
                className="text-base mr-5 cursor-pointer hover:text-softPink"
              >
                Show Filter
              </h1>
            </div>
            <Sorting
              dispatchContext={packageListDispatch}
              stateContext={packageListState}
              option={optSorting}
              defaultSort={DefaultSort(default_sort_package)}
            />
          </div>
          <Chip
            dispatchContext={packageListDispatch}
            stateContext={packageListState}
            queryParams={queryparams}
          />
          <div className="flex justify-end my-4">
            {isFetching ? (
              <div className="flex items-center">
                <CircularLoad color="#DF5354" height={20} width={20} />
                <h1 className="ml-2">Updating</h1>
              </div>
            ) : (
              <h1>Auto Update</h1>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div className="w-full">
            {data?.data?.data?.map((el: any) => (
              <PackageListCard data={el} key={el.id} />
            ))}
          </div>
        )}
        <NonTablePagination
          offset={offset}
          limit={limit}
          total={data?.data?.meta?.total}
          length={data?.data?.data?.length}
          dispatchContext={packageListDispatch}
        />
      </div>
    </PackageListContextProvider>
  );
};

export default PackageList;
