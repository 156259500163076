import { ActionType, AuthenticationAction } from "./action";
import { IAuthenticationState } from "./state";

export const AuthenticationReducer = (
  state: IAuthenticationState,
  action: AuthenticationAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case ActionType.ShowDeleteGuide:
      let deleted_guide = action.payload;
      document.body.classList[deleted_guide.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDeleteGuide: deleted_guide };
    case ActionType.ShowAddPicture:
      let showAddPicture = state.showAddPicture;
      document.body.classList[!showAddPicture ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddPicture: !showAddPicture };
    case ActionType.ShowCancelAddPicture:
      let showCancelAddPicture = state.showCancelAddPicture;
      document.body.classList[!showCancelAddPicture ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showCancelAddPicture: !showCancelAddPicture };
    case ActionType.ShowAddGuideDetail:
      let showAddGuideDetail = state.showAddGuideDetail;
      document.body.classList[!showAddGuideDetail ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddGuideDetail: !showAddGuideDetail };
  }
};
