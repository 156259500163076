const optRoutes = [
  {
    id: 1,
    path: "/consignment",
    name: "Home",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
  },
  {
    id: 2,
    path: "/consignment",
    name: "Consignment",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
  },
];

const optRoutesDetail = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/consignment",
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
      name: "Consignment",
    },
    {
      id: 3,
      path: `/consignment/${id}`,
      name: "Detail",
    },
  ];
};

const default_sort_consignment = [
  { real_name: "created_at", temp_name: "Date" },
  { real_name: "store_name", temp_name: "Store Name" },
];

const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
  {
    value: "store_name",
    name: "Store Name",
  },
];

export { optRoutes, optSorting, optRoutesDetail, default_sort_consignment };
