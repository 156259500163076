import { createContext } from "react";
import { IAuthenticationContext, IAuthenticationState } from "./state";

export const initialAuthenticationState: IAuthenticationState = {
  showFilter: false,
  chip: [],
  showDeleteGuide: {
    status: false,
    id: 0,
  },
  showAddPicture: false,
  showCancelAddPicture: false,
  showAddGuideDetail: false,
};

const AuthenticationContext = createContext<IAuthenticationContext>({
  AuthenticationState: initialAuthenticationState,
  AuthenticationDispatch: () => undefined,
});

export const AuthenticationContextProvider = AuthenticationContext.Provider;
export const AuthenticationContextConsumer = AuthenticationContext.Consumer;

export default AuthenticationContext;
