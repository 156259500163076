import ToastHandler from "../../../components/toast/ToastHandler";
import axiosCelestine from "../../../config/AxiosCelestine";
import axiosCovid from "../../../config/AxiosCovid";
import ApplyChip from "../../../helpers/applyChip/ApplyChip";
import { queryparams } from "../../../pages/admin/brand/helpers/list";

export class BrandRequest {
  static getBrand = async (
    key: string,
    sortValue: string,
    isAsc: string,
    limit: number,
    offset: number,
    filter: any,
    Dispatch: any
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results = await axiosCovid().get("admin/legit-check-brand", {
        params: {
          ...(query.get("is_active") !== null && {
            is_active: query.get("is_active") || filter.status,
          }),
          ...(query.get("legit_check_type_id") !== null && {
            legit_check_type_id:
              query.get("legit_check_type_id") || filter.legit_check_type,
          }),
          ...(query.get("brand") !== null && {
            id: query.get("brand") || filter.brand,
          }),
          limit: query.get("limit") !== null ? query.get("limit") : limit,
          offset: query.get("offset") !== null ? query.get("offset") : offset,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : sortValue
          } ${query.get("isAsc") !== null ? query.get("isAsc") : isAsc}`,
        },
      });
      ApplyChip(queryparams, Dispatch);
      return results;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getBrandDetail = async (id: number) => {
    try {
      const results = await axiosCovid().get(`admin/legit-check-brand/${id}`);
      const result = results.data;
      return result;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getCategoryGroup = async () => {
    const loadopt: any = [];
    try {
      const { data } = await axiosCelestine().get(
        "admin/product-category-group",
        {
          params: {
            limit: 30,
          },
        }
      );
      await data.data.map((el: any) =>
        loadopt.push({ label: el.name, value: el.id })
      );
      return loadopt;
    } catch (err) {
      console.log(err);
    }
  };

  static editBrand = async (
    payload: any,
    id: number,
    setSubmitting: any,
    goBack: any
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().patch(`admin/legit-check-brand/${id}`, payload);
      ToastHandler("#00C62E", "Edit Completed");
      setSubmitting(false);
      goBack();
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
      setSubmitting(false);
    }
  };

  static resyncProductBrand = async () => {
    try {
      await axiosCovid().post(`admin/legit-check-brand/update`);
      ToastHandler("#00C62E", "Resync Completed");
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };
}
