import ToastHandler from "../../../components/toast/ToastHandler";
import axiosCovid from "../../../config/AxiosCovid";

export class TypeRequest {
  static getType = async () => {
    try {
      const results = await axiosCovid().get("admin/legit-check-type", {
        params: {
          sort: "id asc",
        },
      });
      return results;
    } catch (error) {
      ToastHandler("#ed4846", error.response.data.errors);
    }
  };

  static getTypeDetail = async (id: number) => {
    try {
      const results = await axiosCovid().get(`admin/legit-check-type/${id}`);
      const res = results.data;
      return res;
    } catch (error) {
      ToastHandler("#ed4846", error.response.data.errors);
    }
  };

  static editType = async (
    id: number,
    payload: any,
    typeDispatch: any,
    setSubmitting: (status: boolean) => void
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().patch(`admin/legit-check-type/${id}`, {
        price: payload.price,
        price_without_insurance: payload.price_without_insurance,
        referral_reward_percent: payload.referral_reward_percent / 100,
        max_referral_reward: payload.max_referral_reward,
      });
      setSubmitting(false);
      typeDispatch({
        type: "show_edit",
        payload: {
          id: 0,
          status: false,
        },
      });
      ToastHandler("#00c62e", "Edit Completed");
    } catch (error) {
      setSubmitting(false);
      ToastHandler("#ed4846", error.response.data.errors);
    }
  };
}
