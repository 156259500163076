import React from "react";
import CircularLoad from "../loading/CircularLoad";

interface ButtonProps {
  text: string;
  type: string;
  handleClick?: any;
  isSubmitting?: boolean;
  dirty?: boolean;
  color?: string;
  hoverColor?: string;
  loadColor?: string;
  textColor?: string;
  border?: string;
  borderColor?: string;
  height?: number | string;
  width?: string;
  rounded?: string;
  cursor?: string;
  disabled?: boolean;
  fontSize?: "rom" | "med" | "bold";
}

const SmallButton: React.FC<ButtonProps> = ({
  handleClick,
  isSubmitting,
  color,
  hoverColor,
  loadColor,
  textColor,
  text,
  dirty,
  type,
  border,
  borderColor,
  height,
  width,
  rounded,
  cursor,
  disabled,
  fontSize,
}) => {
  return (
    <button
      type={type as any}
      disabled={disabled || isSubmitting || dirty === false ? true : false}
      onClick={handleClick}
      className={
        "focus:ring-0 focus:outline-none " +
        `${color ? color : "bg-softPink"} ${
          rounded ? rounded : "rounded-full"
        }  h-${height ? height : 9} ${cursor && `cursor-${cursor}`} w-${
          width ? width : 36
        }  ` +
        (isSubmitting || dirty === false || disabled === true
          ? "cursor-not-allowed"
          : `hover:${hoverColor ? hoverColor : "bg-opacity-70"}`) +
        (border ? ` ${border} ${borderColor}` : "")
      }
    >
      {isSubmitting ? (
        <div className="flex justify-center">
          <CircularLoad
            color={loadColor ? loadColor : "#ffffff"}
            height={17}
            width={17}
          />
        </div>
      ) : (
        <h1
          className={
            "md:text-base text-sm " +
            (textColor ? textColor : "text-white ") +
            ` font-${fontSize}`
          }
        >
          {text}
        </h1>
      )}
    </button>
  );
};

export default SmallButton;
