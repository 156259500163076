export const TypeReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show_edit":
      let payload = action.payload;
      document.body.classList[payload.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showEdit: payload };
  }
};
