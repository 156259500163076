import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import NewChip from "../../../../components/chip/NewChip";
import Loading from "../../../../components/loading/Loading";
import NotificationContext from "../../../../store/admin/notification/NotificationContext";
import { queryparamsNotifSchedule } from "../helpers/list";
import UseFetchNotifSchedule from "../helpers/Fetch_hooks/UseFetchNotifSchedule";
import DeleteNotif from "./DeleteNotif";
import FilterNotifSchedule from "./FilterNotifSchedule";
import NotificationTable from "./NotificationTable";
import CircularLoad from "../../../../components/loading/CircularLoad";

const Schedule = () => {
  const { notificationState, notificationDispatch } =
    useContext(NotificationContext);
  const { showDelete, showFilter } = notificationState;
  const history = useHistory();
  const { data, isLoading, isFetching } = UseFetchNotifSchedule();

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : (
        <div>
          {showDelete?.status && <DeleteNotif />}
          {showFilter && <FilterNotifSchedule />}
          <div className="flex mt-5 mb-3 justify-between">
            <h1
              onClick={() => notificationDispatch({ type: "show_filter" })}
              className="text-base mr-5 cursor-pointer hover:text-softPink"
            >
              Show Filter
            </h1>
            <div>
              {isFetching ? (
                <div className="flex items-center">
                  <CircularLoad color="#DF5354" height={20} width={20} />
                  <h1 className="ml-2">Updating</h1>
                </div>
              ) : (
                <h1>Auto Update</h1>
              )}
            </div>
          </div>
          <div className="mb-7">
            <NewChip
              dispatchContext={notificationDispatch}
              stateContext={notificationState}
              queryParams={queryparamsNotifSchedule}
            />
          </div>
          <NotificationTable data={data} />
          <div
            onClick={() =>
              history.push("notification/add-notification-schedule")
            }
            className="bottom-0 right-0 shadow-md cursor-pointer fixed mx-16 mb-14 bg-softPink hover:bg-opacity-80 h-14 w-14 rounded-full flex justify-center items-center"
          >
            <h1 className="text-white text-3xl">+</h1>
          </div>
        </div>
      )}
    </div>
  );
};

export default Schedule;
