const SetQueryString = (
  array: any[],
  new_value: string,
  name: string,
  search: string,
  pathname: string,
  history: any,
  hash?: string
) => {
  const location = document.location.search;
  const query = new URLSearchParams(location);
  let new_search;
  for (const key in array) {
    let value = query.get(array[key].real_name);
    if (value !== null && array[key].show_name === name) {
      new_search = search.replace(
        `${array[key].real_name}=${value}`,
        `${array[key].real_name}=${new_value}`
      );
    } else if (array[key].show_name === name) {
      new_search = `${search}&&${array[key].real_name}=${new_value}`;
    }
  }
  return history.push({ pathname, search: new_search, hash  });
};

export default SetQueryString;
