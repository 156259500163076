import React from "react";
import { Link } from "react-router-dom";
import { store, wallet } from "../../../assets/icons/Web";

interface IProps {
  dropdownAuthentication: boolean;
  handleShowSideBar: () => void;
}

const AuthenticationSidebar: React.FC<IProps> = ({
  dropdownAuthentication,
  handleShowSideBar,
}) => {
  return (
    <div>
      {dropdownAuthentication && (
        <li className="py-2 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={wallet}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf("/authentication/on-progress") !==
                -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to="/authentication/on-progress"
            >
              On Progress
            </Link>
          </div>
        </li>
      )}

      {dropdownAuthentication && (
        <li className="py-2 mt-5 ml-7 flex flex-row flex-wrap justify-between items-center">
          <div className="flex">
            <img
              src={store}
              className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
              alt="icon"
            />
            <Link
              onClick={handleShowSideBar}
              className={
                "md:text-lg text-sm font-medium items-center cursor-pointer " +
                (window.location.href.indexOf("/authentication/on-hold") !== -1
                  ? "text-black hover:text-gray-300"
                  : "text-gray-600 hover:text-gray-300")
              }
              to="/authentication/on-hold"
            >
              On Hold
            </Link>
          </div>
        </li>
      )}

      {dropdownAuthentication && <hr className="md:my-5 my-3 md:min-w-full" />}
    </div>
  );
};

export default AuthenticationSidebar;
