export enum ActionType {
  ShowSidebar,
  ShowAuthenticationList,
}

export interface ShowSidebar {
  type: ActionType.ShowSidebar;
  payload: boolean;
}

export interface ShowAuthenticationList {
  type: ActionType.ShowAuthenticationList;
}

export type NavbarAction = ShowSidebar | ShowAuthenticationList;
