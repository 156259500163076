import React, { useReducer } from "react";
import { Route, Switch } from "react-router-dom";
import Notification from "../../../pages/admin/notification/Notification";
import AddNotifSchedule from "../../../pages/admin/notification/schedule/AddNotifSchedule";
import EditNotifSchedule from "../../../pages/admin/notification/schedule/EditNotifSchedule";
import {
  initialNotificationState,
  NotificationContextProvider,
} from "../../../store/admin/notification/NotificationContext";
import { NotificationReducer } from "../../../store/admin/notification/NotificationReducer";

const NotificationRoute = () => {
  const [notificationState, notificationDispatch] = useReducer<any>(
    NotificationReducer,
    initialNotificationState
  );
  return (
    <NotificationContextProvider
      value={{ notificationState, notificationDispatch }}
    >
      <div className="font-med">
        <Switch>
          <Route path="/notification" exact component={Notification} />
          <Route
            path="/notification/add-notification-schedule"
            exact
            component={AddNotifSchedule}
          />
          <Route
            path="/notification/edit-notification-schedule/:id"
            exact
            component={EditNotifSchedule}
          />
        </Switch>
      </div>
    </NotificationContextProvider>
  );
};

export default NotificationRoute;
