import React from "react";
import { Route, Switch } from "react-router-dom";
import AuthenticationData from "../../../pages/authenticationData/authDataComponent/AuthenticationData";
import AuthenticationDataDetail from "../../../pages/authenticationData/authDataDetailComponent/AuthenticationDataDetail";
import SimpleReactLightbox from "simple-react-lightbox";

const AuthenticationDataRoute = () => {
  return (
    <SimpleReactLightbox>
      <div className="font-med">
        <Switch>
          <Route
            path="/authentication-data"
            exact
            component={AuthenticationData}
          />
          <Route
            path="/authentication-data/:id"
            exact
            component={AuthenticationDataDetail}
          />
        </Switch>
      </div>
    </SimpleReactLightbox>
  );
};

export default AuthenticationDataRoute;
