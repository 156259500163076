import React, { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import AsyncSelect from "react-select/async";
import ApplyChip from "../../../../helpers/applyChip/ApplyChip";
import { loadLegitCheckBrand } from "../../../../helpers/loadOption/LoadOption";
import BrandContext from "../../../../store/admin/brand/BrandContext";
import { useHistory, useLocation } from "react-router-dom";
import { queryparams } from "../helpers/list";
import SetQueryString from "../../../../helpers/query_searchParams/SetQueryString";

const FilterBrand = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const queryClient = useQueryClient();
  const context = useContext(BrandContext);
  const { brandDispatch, brandState } = context;
  const { filter } = brandState;
  const [brand, setBrand] = useState({
    label: "",
    values: "",
  });

  const handleShowFilter = () => {
    brandDispatch({ type: "show_filter" });
    brandDispatch({ type: "reset_filter" });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    brandDispatch({
      type: "handle_filter",
      payload: { ...filter, [name]: value },
    });
    SetQueryString(queryparams, value, name, search, pathname, history);
  };

  const applyFilter = () => {
    queryClient.invalidateQueries("getBrand");
    ApplyChip(queryparams, brandDispatch);
    history.push({ pathname, search });
    setTimeout(() => brandDispatch({ type: "show_filter" }), 1000);
  };

  const handleChangeBrand = (brand: any) => {
    setBrand(brand);
    SetQueryString(
      [{ real_name: "brand", show_name: "brand" }],
      brand.value,
      "brand",
      search,
      pathname,
      history
    );
  };

  useEffect(() => {
    brandDispatch({
      type: "handle_filter",
      payload: {
        ...filter,
        brand: brand.label,
      },
    });
  }, [brand]);

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div
        className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-auto h-3/5 shadow-xl transform transition-all md:max-w-2xl max-w-lg"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 py-7 flex justify-between items-center w-full h-1/5">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-inputColor"
            id="modal-headline"
          >
            Filter
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleShowFilter}
          />
        </div>
        <hr className="w-full " />
        <div className="overflow-hidden h-3/5">
          <div className="flex px-10 items-center md:h-1/2">
            <div className="w-full">
              <h1 className="md:text-base text-sm text-inputColor">Brand</h1>
              <AsyncSelect
                name="brand"
                cacheOptions
                defaultOptions
                loadOptions={loadLegitCheckBrand}
                onChange={handleChangeBrand}
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
          <hr className="w-ful " />
          <div className="flex px-10 items-center md:h-1/2">
            <div className="mr-12 w-96">
              <h1 className="md:text-base text-sm text-inputColor">
                Legit Check Type
              </h1>
              <select
                onChange={handleChangeFilter}
                name="legit_check_type"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="1">Streetwear</option>
                <option value="2">Hype</option>
                <option value="3">Deluxe</option>
                <option value="4">Exclusive</option>
                <option value="5">Luxury</option>
              </select>
            </div>
            <div className="w-96">
              <h1 className="md:text-base text-sm text-inputColor">Status</h1>
              <select
                onChange={handleChangeFilter}
                name="status"
                className="md:h-10 h-8 font-rom w-full cursor-pointer rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option selected disabled hidden>
                  Select Here
                </option>
                <option value="true">Active</option>
                <option value="false">Not Active</option>
              </select>
            </div>
          </div>
        </div>

        <hr className="w-full " />
        <div className="flex justify-center items-center h-1/5">
          <SmallButton
            type={"button"}
            color={"bg-softPink"}
            textColor={"text-white"}
            hoverColor={"bg-red-300"}
            handleClick={applyFilter}
            text={"Apply"}
            loadColor={"white"}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterBrand;
