import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import ReactTooltip from "react-tooltip";
import { edit } from "../../../../assets/icons";

interface IProps {
  data: any;
}

const OnProgressTable: React.FC<IProps> = ({ data }) => {
  const history = useHistory();

  return (
    <div className="w-full overflow-auto">
      <table className="w-full border relative text-center text-sm overflow-auto">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            <th className="border-r h-18 px-10">
              <div className="flex justify-center items-center">
                <h1>Last Modified</h1>
              </div>
            </th>
            <th className="border-r h-18 px-10">
              <div className="flex justify-center items-center">
                <h1>Submitted At</h1>
              </div>
            </th>
            <th className="border-r h-18 px-10">
              <div className="flex justify-center items-center">
                <h1>LC Code</h1>
              </div>
            </th>
            <th className="border-r h-18 px-10">
              <div className="flex justify-center items-center">
                <h1>Username</h1>
              </div>
            </th>
            <th className="border-r h-18 w-1/6 px-10">
              <div className="flex justify-center items-center">
                <h1>Product</h1>
              </div>
            </th>
            <th className="border-r h-18 px-10">
              <div className="flex justify-center items-center">
                <h1>Brand & Category</h1>
              </div>
            </th>
            <th className="border-r h-18 px-10">
              <div className="flex justify-center items-center">
                <h1>Status</h1>
              </div>
            </th>
            <th className="px-3">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((el: any, idx:number) => (
            <tr key={idx} className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r">
                {moment(el.updated_at).format("ll hh:mm a")}
              </td>
              <td className="border-r">
                {moment(el.created_at).format("ll hh:mm a")}
              </td>
              <td className="border-r">{el.code || "-"}</td>
              <td className="border-r">{el.username || "-"}</td>
              <td className="border-r">{el.name || "-"}</td>
              <td className="border-r">{`${el.legit_check_brand_name} (${el.category})`}</td>

              <td className="border-r font-med text-yellow-400">
                {el.status_message?.split("_").join(" ")}
              </td>

              <td className="">
                <div className="flex justify-evenly">
                  <div>
                    <img
                      data-tip
                      data-for="detail"
                      className="cursor-pointer md:w-5 w-4"
                      onClick={() =>
                        history.push(`/authentication/${el.code}`)
                      }
                      src={edit}
                      alt="detail"
                    />
                    <ReactTooltip id="detail" type="info">
                      <span>Detail</span>
                    </ReactTooltip>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OnProgressTable;
