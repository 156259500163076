import React from "react";
import Input from "../../../../../components/input/Input";
import { loadCouponEffect } from "../../../../../helpers/loadOption/LoadOption";
import { handleChangeCoupon } from "../../helpers/FormHandle";
import AsyncSelect from "react-select/async";

interface ExclusiceProps {
  setCheckExclusive: (check: boolean) => void;
  setExclusive: (exclusive: any) => void;
  exclusive: any;
}

const ExclusiveCard: React.FC<ExclusiceProps> = ({
  exclusive,
  setCheckExclusive,
  setExclusive,
}) => {
  return (
    <div className="my-5">
      <div className="flex justify-between items-end">
        <h1 className="text-2xl">Exclusive</h1>
        <h1
          onClick={() => setCheckExclusive(false)}
          className="hover:text-softPink cursor-pointer"
        >
          Delete
        </h1>
      </div>
      <hr className="my-3" />
      <div className="pt-2">
        <div className="flex justify-between">
          <div className="w-1/2">
            <h1 className="md:text-base text-sm text-inputColor">Quantity</h1>
            <Input
              name="name"
              type="number"
              value={exclusive.quantity}
              handleChange={(e: any) =>
                setExclusive({
                  ...exclusive,
                  quantity: +e.target.value,
                })
              }
              placeholder="Place Holder"
            />
          </div>
          <div className="w-1/2 ml-7">
            <h1 className="md:text-base text-sm text-inputColor">
              Validity Period (Day)
            </h1>
            <Input
              name="name"
              type="number"
              value={exclusive.validity_period}
              handleChange={(e: any) =>
                setExclusive({
                  ...exclusive,
                  validity_period: +e.target.value,
                })
              }
              placeholder="Place Holder"
            />
          </div>
        </div>
        <div className="w-full">
          <div>
            <h1 className="md:text-base text-sm text-inputColor">
              Package for
            </h1>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={{
                value: exclusive.package_coupon_effect_id,
                label: exclusive.package_coupon_name,
              }}
              loadOptions={(inputValue: any) =>
                loadCouponEffect(inputValue, "exclusive")
              }
              onChange={(coupon) =>
                handleChangeCoupon(coupon, "exclusive", setExclusive, exclusive)
              }
              className="my-3.5 font-rom text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveCard;
