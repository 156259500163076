import { useFormik } from "formik";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cancel } from "../../../../assets/icons";
import PackageListContext from "../../../../store/admin/package_list/PackageListContext";
import * as Yup from "yup";
import Input from "../../../../components/input/Input";
import SmallButton from "../../../../components/button/SmallButton";
import AsyncSelect from "react-select/async";
import {
  loadCouponEffect,
  loadOptionsUsername,
  loadPackageList,
} from "../../../../helpers/loadOption/LoadOption";
import PackageListRequest from "../../../../apis/admin/package_list/PackageListRequest";

const SendPackage = () => {
  const context = useContext(PackageListContext);
  const { packageListDispatch } = context;

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    dirty,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      username: "",
      package_coupon_effect_id: "",
      quantity: "",
      validity_period: "",
      package_list: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("username is required"),
      validity_period: Yup.number()
        .required("Validity Period is required")
        .min(1, "Validity Period minimum is 1"),
      quantity: Yup.number()
        .required("Quantity is required")
        .min(1, "quantity minimum is 1"),
      package_coupon_effect_id: Yup.number().required(
        "package Effect is required"
      ),
      package_list: Yup.number().required("Package list is required"),
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      PackageListRequest.sendPackage(values, handleHideModal, setSubmitting),
    {
      onMutate: () => {
        console.log("mutatte");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getTopup");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  const handleChangeUsername = (username: any) => {
    setValues({ ...values, username: username.values });
  };
  const handleChangePackage = (package_list: any) => {
    setValues({ ...values, package_list: package_list.values });
  };
  const handleChangeCoupon = (coupon: any) => {
    setValues({ ...values, package_coupon_effect_id: coupon.values });
  };

  const handleHideModal = () => {
    packageListDispatch({ type: "show_send" });
    handleReset({});
  };

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70 pt-14">
      <form
        onSubmit={handleSubmit}
        className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden shadow-xl transform transition-all md:max-w-2xl max-w-lg"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 py-5 flex justify-between w-full">
          <div className="flex">
            <h1
              className="md:text-2xl text-lg leading-6 font-medium text-inputColor"
              id="modal-headline"
            >
              Send
            </h1>
            <h1
              className="md:text-2xl text-lg ml-1.5 font-medium text-softPink"
              id="modal-headline"
            >
              Package
            </h1>
          </div>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={handleHideModal}
          />
        </div>
        <hr className="w-full " />

        <div className="flex px-10 py-3">
          <div className="w-full">
            <h1 className="md:text-base text-sm text-inputColor">Username</h1>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadOptionsUsername}
              onChange={handleChangeUsername}
              className="my-3.5 font-rom text-sm"
            />
            {touched.username && errors.username ? (
              <p className="text-softPink font-rom text-xs">
                {errors.username}
              </p>
            ) : null}
          </div>
        </div>
        <hr className="w-full " />
        <div className="flex px-10 py-3">
          <div className="md:w-full w-34">
            <h1 className="md:text-base text-sm text-inputColor">
              Package List
            </h1>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadPackageList}
              onChange={handleChangePackage}
              className="my-3.5 font-rom text-sm"
            />
            {touched.package_list && errors.package_list ? (
              <p className="text-softPink font-rom text-xs">
                {errors.package_list}
              </p>
            ) : null}
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex px-10 py-3">
          <div className="md:w-full w-34">
            <h1 className="md:text-base text-sm text-inputColor">
              Package Effect
            </h1>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={(inputValue) => loadCouponEffect(inputValue)}
              onChange={handleChangeCoupon}
              className="my-3.5 font-rom text-sm"
            />
            {touched.package_coupon_effect_id &&
            errors.package_coupon_effect_id ? (
              <p className="text-softPink font-rom text-xs">
                {errors.package_coupon_effect_id}
              </p>
            ) : null}
          </div>
        </div>
        <hr className="w-full" />
        <div className="flex px-10 py-3">
          <div className="mr-12 w-96">
            <h1 className="md:text-base text-sm text-inputColor">Quantity</h1>
            <Input
              type={"number"}
              placeholder={"Place holder"}
              name={"quantity"}
              handleChange={handleChange}
            />
            {touched.quantity && errors.quantity ? (
              <p className="text-softPink font-rom text-xs">
                {errors.quantity}
              </p>
            ) : null}
          </div>
          <div className="w-96">
            <h1 className="md:text-base text-sm text-inputColor">
              Validity Period (Day)
            </h1>
            <Input
              type={"number"}
              placeholder={"Place holder"}
              name={"validity_period"}
              handleChange={handleChange}
            />
            {touched.validity_period && errors.validity_period ? (
              <p className="text-softPink font-rom text-xs">
                {errors.validity_period}
              </p>
            ) : null}
          </div>
        </div>
        <hr className="w-full" />

        <div className="flex justify-center my-5 outline-none">
          <SmallButton
            isSubmitting={isSubmitting}
            loadColor={"white"}
            dirty={dirty}
            type={"submit"}
            color={"bg-softPink"}
            hoverColor={"bg-red-300"}
            text={"Send"}
            textColor={"text-white"}
          />
        </div>
      </form>
    </div>
  );
};

export default SendPackage;
