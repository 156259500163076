import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import Pagination from "../../../components/pagination/NewPagination";
import { variant, edit } from "../../../assets/icons";
import AuthDataContext from "../../../store/authentication_data/AuthDataContext";
import AuthDataEdit from "./authDataEditComponent/AuthDataEdit";

interface HeadCell {
  id: string;
  label: string;
  disablePadding: boolean;
}

const headCells: HeadCell[] = [
  { id: "code", label: "Legit Check Code", disablePadding: true },
  { id: "Name", label: "Product Name", disablePadding: true },
  { id: "Brand", label: "Brand & Category", disablePadding: true },
  { id: "Status", label: "Status ", disablePadding: true },
  { id: "Date", label: "Transaction Date", disablePadding: true },
  { id: "Modified", label: "Last Modified", disablePadding: true },
  { id: "Actions", label: "Action", disablePadding: true },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow className="h-24">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            className={classes.tableHead}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  dataProps?: [] | any;
}

const EnhancedTable: React.FC<IProps> = ({ dataProps }) => {
  const meta = dataProps?.data?.meta;
  const data = dataProps?.data?.data;

  const history = useHistory();
  const { url } = useRouteMatch();
  const classes = useStyles();
  const context = useContext(AuthDataContext);
  const { authDataDispatch, authDataState } = context;
  const { showEdit } = authDataState;
  return (
    <div className={classes.root}>
      {showEdit.status && <AuthDataEdit />}

      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {data?.map((row: any) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className="h-40"
                >
                  <TableCell
                    component="th"
                    align="center"
                    scope="row"
                    className={classes.tableCell}
                  >
                    {row.code}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <div>
                      <h1>{row.legit_check_brand_name}</h1>
                      <h1>( {row.category} )</h1>
                    </div>
                  </TableCell>

                  {row.result === "authentic" ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_selling}
                    >
                      {row.result}
                    </TableCell>
                  ) : row.result === "fake" ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_reject}
                    >
                      <div>
                        <h1>{row?.result}</h1>
                      </div>
                    </TableCell>
                  ) : row.result === "indefinable" && row.status === 8 ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_waiting}
                    >
                      <div>
                        <h1>{row?.result}</h1>
                        <h1 className="text-xs font-rom text-black">
                          (No Refund)
                        </h1>
                      </div>
                    </TableCell>
                  ) : row.result === "indefinable" && row.status === 9 ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_waiting}
                    >
                      <div>
                        <h1>{row?.result}</h1>
                        <h1 className="text-xs font-rom text-black">
                          (Refund)
                        </h1>
                      </div>
                    </TableCell>
                  ) : row.result === "invalid" && row.status === 10 ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_reject}
                    >
                      <div>
                        <h1>{row?.result}</h1>
                        <h1 className="text-xs font-rom text-black">
                          (Refund)
                        </h1>
                      </div>
                    </TableCell>
                  ) : row.result === "indefinable" && row.status === 11 ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_waiting}
                    >
                      <div>
                        <h1>{row?.result}</h1>
                        <h1 className="text-xs font-rom text-black">
                          (Expired)
                        </h1>
                      </div>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  <TableCell align="center" className={classes.tableCell}>
                    {moment(row.created_at).format("llll")}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {moment(row.updated_at).format("llll")}
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    className={
                      row.product_id !== null
                        ? classes.tableCellAction
                        : classes.tableCell
                    }
                  >
                    <div className="flex justify-evenly">
                      <div>
                        <img
                          data-tip
                          data-for="edit"
                          className="cursor-pointer md:w-6"
                          onClick={() =>
                            authDataDispatch({
                              type: "show_edit",
                              payload: {
                                code: row.code,
                                status: true,
                              },
                            })
                          }
                          src={edit}
                          alt="edit"
                        />
                        <ReactTooltip id="edit" type="dark">
                          <span>Edit</span>
                        </ReactTooltip>
                      </div>
                      <div>
                        <a
                          href={`${url}/${row.code}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            data-tip
                            data-for="detail"
                            className="cursor-pointer md:w-5"
                            // onClick={() => history.push(`${url}/${row.code}`)}
                            src={variant}
                            alt="detail"
                          />
                          <ReactTooltip id="detail" type="dark">
                            <span>Detail</span>
                          </ReactTooltip>
                        </a>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        // lengthData={data?.length}
        // metaTotal={meta?.total}
        // dispatchContext={authDataDispatch}
        // stateContext={authDataState}
        isTable={true}
        length={data?.length}
        total={meta.total}
      />
    </div>
  );
};

export default EnhancedTable;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "3%",
      marginBottom: "3%",
    },
    table: {
      minWidth: 750,
    },
    searchInput: {
      width: "75%",
      padding: "0.5%",
      margin: "1% 10% 3% 0%",
    },
    tableCell: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      fontFamily: "NeueHaasDisplay",
    },
    tableCellAction: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      backgroundColor: "#00C62E",
      fontSize: 14,
      width: "10%",
      fontFamily: "NeueHaasDisplay",
    },
    tableCell_status_selling: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#00C62E",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableCell_status_reject: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#DF5354",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableCell_status_waiting: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#FFBA00",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableHead: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 16,
      fontFamily: "NeueHaasDisplayMediu",
    },
  })
);
