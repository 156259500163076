import { useFormik } from "formik";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import ConsignmentRequest from "../../../../apis/admin/consignment/ConsignmentRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import Input from "../../../../components/input/Input";
import ConsignmentContext from "../../../../store/admin/consignment/ConsignmentContext";
import * as Yup from "yup";
import ToastHandler from "../../../../components/toast/ToastHandler";

const ShowUpgrade = () => {
  const { consignmentDispatch, consignmentState } =
    useContext(ConsignmentContext);
  const { showUpgrade } = consignmentState;

  const goBack = () => {
    consignmentDispatch({
      type: "show_upgrade",
      payload: {
        status: false,
        id: "",
      },
    });
    handleReset({});
  };

  const {
    dirty,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      upgrade: "",
    },
    validationSchema: Yup.object({
      upgrade: Yup.string().required("Please write Upgrade Consignment"),
    }),
    onSubmit: async (values: any) => {
      if (values.upgrade === "Upgrade Consignment") {
        await mutation.mutate();
      } else {
        ToastHandler("#DF5354", "Invalid Confirmation text");
      }
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      ConsignmentRequest.upgradeConsignment(
        showUpgrade.id,
        goBack,
        setSubmitting
      ),
    {
      onMutate: () => {
        console.log("mutatte");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getConsignment");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70 pt-14">
      <div className="bg-white md:w-1/3 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center px-8 py-6">
          <div className="flex items-center">
            <h1 className="text-xl flex">Upgrade</h1>
            <h1 className="ml-2 text-xl text-softPink">Consignment</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-8">
          <div>
            <div className="flex justify-between my-5">
              <h1 className="md:text-base text-sm font-rom">
                Please write <b>"Upgrade Consignment"</b> to confirm this
                consignment become VIP.
              </h1>
            </div>

            <div className="flex justify-between">
              <h1 className="md:text-base text-sm text-inputColor">
                Write here
              </h1>
            </div>
            <Input
              type={"text"}
              handleChange={handleChange}
              disable={false}
              name={"upgrade"}
              placeholder={"Place holder"}
            />
            {touched.upgrade && errors.upgrade ? (
              <h1 className="text-softPink font-rom mb-1 -mt-1 text-sm">
                {errors.upgrade}
              </h1>
            ) : null}

            <div className="flex justify-between">
              <h1 className="md:text-sm text-xs font-rom text-placeholderColor">
                Note : You can't downgrade this consignment anymore once you
                upgraded.
              </h1>
            </div>
          </div>

          <div className="flex justify-center my-7 outline-none">
            <SmallButton
              isSubmitting={isSubmitting}
              loadColor={"white"}
              dirty={dirty}
              type={"submit"}
              color={"bg-softPink"}
              hoverColor={"bg-red-300"}
              text={"Upgrade"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShowUpgrade;
