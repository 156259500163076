import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  home,
  logout,
  product,
  store,
  transaction,
  withdraw,
} from "../../assets/icons/Web/index";
import { cancel, down, up } from "../../assets/icons";
import NavbarContext from "../../store/navbar/NavbarContext";
import LocalStorage from "../../config/LocalStorage";
import { CrossStorageClient } from "cross-storage";
import { validateRole } from "../../helpers/checkRole/CheckRole";
import { AcceptedRole } from "../../routes/interfaces";
import AuthenticationSidebar from "./sub_sidebar/AuthenticationSidebar";
import { ActionType } from "../../store/navbar/action";

const Sidebar: React.FC = () => {
  const history = useHistory();
  const context = useContext(NavbarContext);
  const { NavbarState, NavbarDispatch } = context;
  const { showSidebar, dropdownAuthentication } = NavbarState;
  const role = LocalStorage.ROLE;

  const handleShowSideBar = () => {
    NavbarDispatch({
      payload: false,
      type: ActionType.ShowSidebar,
    });
  };

  const handleDropdownAuthentication = () => {
    NavbarDispatch({
      type: ActionType.ShowAuthenticationList,
    });
  };

  const storage = new CrossStorageClient(
    process.env.REACT_APP_CROSS_STORAGE_URL || "",
    {}
  );

  const handleLogout = () => {
    LocalStorage.AUTH_TOKEN = "";
    storage
      .onConnect()
      .then(function () {
        return storage.del("AUTH_TOKEN");
      })
      .then(function () {
        return storage.del("ROLE");
      });
    history.push("/login");
  };

  const renderUsername = (role: string) => {
    const helper: { [key: string]: string } = {
      admin: "Admin",
      labeler: "Labeler",
      inspector: "Inspector",
    };
    const username: string = helper[role];
    return username;
  };

  return (
    <div>
      <div
        className={
          "w-full " +
          (showSidebar &&
            "fixed inset-0 bg-black bg-opacity-70 z-50 cursor-pointer")
        }
      >
        <nav
          className={
            "shadow-xl fixed flex flex-wrap md:w-1/4 w-10/12 z-50 py-6 duration-500 transform ease-in-out transition h-screen bg-white overflow-auto " +
            (!showSidebar && "-translate-x-full")
          }
        >
          <div className="flex-col md:items-stretch md:min-h-full items-center justify-between w-full font-med">
            <div className="flex justify-between w-full md:my-1 md:px-7 px-5">
              <h1
                onClick={handleShowSideBar}
                className="md:block text-left md:text-2xl cursor-default text-base font-med"
              >
                {renderUsername(role)}
              </h1>
              <img
                src={cancel}
                alt="back"
                onClick={handleShowSideBar}
                className="md:w-6 w-4 md:h-6 h-4 cursor-pointer"
              />
            </div>
            <hr className="mt-5 w-full" />

            <div
              className={
                "md:-ml-32 w-full lg:ml-0 md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:my-4 pb:10 absolute z-40 items-center flex-1 md:px-7 px-5 md:mt-3 mt-1"
              }
            >
              <ul className="md:flex-col md:min-w-full my-3 flex flex-col list-none">
                {validateRole(role, AcceptedRole.Labeler) && (
                  <li className="mb-6">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center  " +
                        (window.location.href.indexOf(
                          "/authentication-data"
                        ) !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/authentication-data"
                    >
                      <img
                        src={home}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />{" "}
                      Authentication Data
                    </Link>
                    <hr className="mt-6" />
                  </li>
                )}

                {validateRole(role, AcceptedRole.Inspector) && (
                  <div>
                    <div className="flex flex-row flex-wrap justify-between items-center">
                      <div className="flex">
                        <img
                          src={home}
                          className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                          alt="icon"
                        />
                        <h1
                          onClick={handleDropdownAuthentication}
                          className={
                            "md:text-lg text-sm font-medium items-center cursor-pointer " +
                            (window.location.href.indexOf(
                              "/marketplace/default"
                            ) !== -1
                              ? "text-black hover:text-gray-300"
                              : "text-gray-600 hover:text-gray-300")
                          }
                        >
                          Authentication
                        </h1>
                      </div>
                      <div>
                        <img
                          onClick={handleDropdownAuthentication}
                          src={dropdownAuthentication ? up : down}
                          className="w-4 mr-1 cursor-pointer"
                          alt="down"
                        />
                      </div>
                    </div>
                    <hr className="my-5 md:min-w-full" />
                    <AuthenticationSidebar
                      dropdownAuthentication={dropdownAuthentication}
                      handleShowSideBar={handleShowSideBar}
                    />
                  </div>
                )}

                {validateRole(role, AcceptedRole.Admin) && (
                  <li className="mb-6">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center  " +
                        (window.location.href.indexOf("/consignment") !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to={{
                        pathname: "/consignment",
                        search:
                          "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
                      }}
                    >
                      <img
                        src={transaction}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />{" "}
                      Consignment
                    </Link>
                    <hr className="mt-6" />
                  </li>
                )}

                {validateRole(role, AcceptedRole.Admin) && (
                  <li className="mb-6">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center  " +
                        (window.location.href.indexOf("/package-list") !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to={{
                        pathname: "/package-list",
                        search:
                          "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
                      }}
                    >
                      <img
                        src={product}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Package List
                    </Link>
                    <hr className="mt-6" />
                  </li>
                )}

                {validateRole(role, AcceptedRole.Admin) && (
                  <li className="mb-6">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center  " +
                        (window.location.href.indexOf("/legit-check-brand") !==
                        -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to={{
                        pathname: "/legit-check-brand",
                        search:
                          "limit=10&&offset=0&&sort=is_active&&isAsc=desc",
                      }}
                    >
                      <img
                        src={store}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Legit Check Brand
                    </Link>
                    <hr className="mt-6" />
                  </li>
                )}

                {validateRole(role, AcceptedRole.Admin) && (
                  <li className="mb-6">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center  " +
                        (window.location.href.indexOf("/legit-check-type") !==
                        -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to="/legit-check-type"
                    >
                      <img
                        src={withdraw}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Legit Check Type
                    </Link>
                    <hr className="mt-6" />
                  </li>
                )}

                {validateRole(role, AcceptedRole.Admin) && (
                  <li className="mb-6">
                    <Link
                      onClick={handleShowSideBar}
                      className={
                        "flex flex-row md:text-lg text-sm font-medium items-center  " +
                        (window.location.href.indexOf("/notification") !== -1
                          ? "text-black hover:text-gray-300"
                          : "text-gray-600 hover:text-gray-300")
                      }
                      to={{
                        pathname: "/notification",
                        hash: "general",
                      }}
                    >
                      <img
                        src={withdraw}
                        className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                        alt="icon"
                      />
                      Notification
                    </Link>
                    <hr className="mt-6" />
                  </li>
                )}

                <li className="mb-6">
                  <h1
                    onClick={handleLogout}
                    className={
                      "flex flex-row md:text-lg text-sm font-medium items-center cursor-pointer " +
                      (window.location.href.indexOf("/login") !== -1
                        ? "text-black hover:text-gray-300"
                        : "text-gray-600 hover:text-gray-300")
                    }
                  >
                    <img
                      src={logout}
                      className="md:w-7 w-4 md:h-7 h-4 md:mr-5 mr-4"
                      alt="icon"
                    />
                    Logout
                  </h1>
                  <hr className="mt-6" />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
