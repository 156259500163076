import ToastHandler from "../../../components/toast/ToastHandler";
import axiosCovid from "../../../config/AxiosCovid";
import qs from "qs";
export class AuthenticationRequest {
  static getAuthenticationOnProgress = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const status = [2, 3, 5];
      const results = await axiosCovid().get(`inspector/authentication`, {
        params: {
          //   ...(query.get("scope") !== null && {
          //     scope: query.get("scope"),
          //   }),
          status: status.map((el) => el),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "updated_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      });
      //   ApplyChipTS(queryparamsCreditEffect, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };
  static getAuthenticationOnHold = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const status = [4];
      const results = await axiosCovid().get(`inspector/authentication`, {
        params: {
          //   ...(query.get("scope") !== null && {
          //     scope: query.get("scope"),
          //   }),
          status: status.map((el) => el),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "updated_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false });
        },
      });
      //   ApplyChipTS(queryparamsCreditEffect, dispatch, ActionType.HandleChip);
      const result = results.data;
      return result;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getAuthenticationDetail = async (code: string) => {
    try {
      const results = await axiosCovid().get(
        `inspector/authentication/${code}`
      );
      const result = results.data;
      return result;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getGuideDetail = async (
    brand: { value: number; label: string },
    category_id: number,
    title?: string
  ) => {
    try {
      const results = await axiosCovid().get(`inspector/guide-detail`, {
        params: {
          is_example: false,
          product_category_id: category_id,
          legit_check_brand_id: brand.value || 1,
          limit: 100,
          title,
          sort: "title asc",
        },
      });
      const result = results.data.data;
      return result;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static addComment = async (code: string, comment: string) => {
    try {
      const results = await axiosCovid().post(
        `inspector/authentication/${code}/comment`,
        { message: comment }
      );
      return results;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static addGuideDetail = async (code: string, guide_id: number[]) => {
    try {
      for (const id of guide_id) {
        await axiosCovid().post(
          `inspector/authentication/${code}/guide-detail/add`,
          { guide_detail_id: id }
        );
      }
      return "success";
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static deleteGuideDetail = async (code: string, id: number) => {
    try {
      const results = await axiosCovid().post(
        `inspector/authentication/${code}/guide-detail/delete`,
        { deleted_guide_detail_id: id.toString() }
      );
      return results;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static addAdditionalPicture = async (
    code: string,
    add_picture_note: string
  ) => {
    try {
      const results = await axiosCovid().post(
        `inspector/authentication/${code}/need-additional-picture`,
        { add_picture_note }
      );
      return results;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static cancelAdditionalPicture = async (code: string, password: string) => {
    try {
      const results = await axiosCovid().post(
        `inspector/authentication/${code}/need-additional-picture/cancel`,
        { password }
      );
      return results;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static sendNotificationSingle = async (code: string) => {
    try {
      const results = await axiosCovid().post(
        `inspector/authentication/${code}/need-additional-picture/notification`
      );
      return results;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static sendNotificationAll = async () => {
    try {
      const results = await axiosCovid().post(
        `inspector/authentication/notification/need-additional-picture`
      );
      return results;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static startAuthenticating = async (code: string) => {
    try {
      const results = await axiosCovid().post(
        `/inspector/authentication/${code}/authenticating`
      );
      return results;
    } catch (error: any) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };
}
