import React, { useContext } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { vip } from "../../../../assets/icons";
import ConsignmentContext from "../../../../store/admin/consignment/ConsignmentContext";

interface IProps {
  data: any;
}

const ConsignmentCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const context = useContext(ConsignmentContext);
  const { consignmentDispatch } = context;

  return (
    <div className="w-full h-auto border-2 border-softGray rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-4">
        <div className="border-r-2 border-softGray flex justify-center">
          <img
            src={data.logo_image_path}
            alt="banner_img"
            className="w-24 m-1 rounded-md shadow-lg"
          />
        </div>

        <div className="flex flex-col items-start pl-7 justify-center border-r-2 border-softGray">
          <div className="flex items-center">
            <h1 className="text-xl">{data.store_name}</h1>
            {data.is_vip && <img src={vip} alt="vip-store" className="w-10 mx-2" />}
          </div>
          <h1 className="text-base font-rom mt-3">Owner: {data.owner_name}</h1>
          <div className="flex mt-1">
            <h1 className="mr-1 text-base">Status :</h1>
            {data.is_verified ? (
              <h1 className="text-success text-base">Verified</h1>
            ) : data.is_expired ? (
              <h1 className="text-softPink text-base">Expired</h1>
            ) : !data.is_verified && !data.is_expired ? (
              <h1 className="text-yellow-400 text-base">Pending</h1>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex flex-col items-start justify-center px-10 border-r-2 border-softGray">
          <h1 className="text-base font-rom">Consignment type</h1>
          <h1 className="text-xl">
            {data.consignment_store && data.reseller_store
              ? "Consignment & Reseller"
              : data.reseller_store
              ? "Reseller"
              : data.consignment_store
              ? "Consignment"
              : ""}
          </h1>
        </div>

        <div className="flex items-center justify-evenly mx-10">
          {data.is_verified && !data.is_vip ? (
            <div className="flex justify-evenly w-full">
              <h1
                onClick={() =>
                  consignmentDispatch({
                    type: "show_upgrade",
                    payload: {
                      status: true,
                      id: data.id,
                    },
                  })
                }
                className="text-base hover:text-softPink cursor-pointer"
              >
                Upgrade
              </h1>
              <h1
                onClick={() => history.push(`${url}/${data.id}`)}
                className="text-base hover:text-softPink cursor-pointer"
              >
                Detail
              </h1>
            </div>
          ) : !data.is_verified && !data.is_expired ? (
            <div className="flex justify-evenly w-full">
              <h1
                onClick={() =>
                  consignmentDispatch({
                    type: "show_accept",
                    payload: {
                      status: true,
                      id: data.id,
                    },
                  })
                }
                className="text-base hover:text-softPink cursor-pointer"
              >
                Accept
              </h1>
              <h1
                onClick={() =>
                  consignmentDispatch({
                    type: "show_reject",
                    payload: {
                      status: true,
                      id: data.id,
                    },
                  })
                }
                className="text-base hover:text-softPink cursor-pointer"
              >
                Reject
              </h1>

              <h1
                onClick={() => history.push(`${url}/${data.id}`)}
                className="text-base hover:text-softPink cursor-pointer"
              >
                Detail
              </h1>
            </div>
          ) : (
            <h1
              onClick={() => history.push(`${url}/${data.id}`)}
              className="text-base hover:text-softPink cursor-pointer"
            >
              Detail
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConsignmentCard;
