import { createContext } from "react";
import { INotificationContext, IinitialNotificationState } from "./interfaces";

export const initialNotificationState: IinitialNotificationState = {
  showDelete: {
    id: "",
    status: false,
  },
  showFilter: false,
  chip: [],
};

const NotificationContext = createContext<INotificationContext>({
  notificationState: initialNotificationState,
  notificationDispatch: () => {},
});

export const NotificationContextProvider = NotificationContext.Provider;
export const NotificationContextConsumer = NotificationContext.Consumer;

export default NotificationContext;
