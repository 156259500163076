import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { AuthenticationRequest } from "../../../apis/admin/authentication/AuthenticationRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../components/chip/Chip";
import CircularLoad from "../../../components/loading/CircularLoad";
import Loading from "../../../components/loading/Loading";
import NewPagination from "../../../components/pagination/NewPagination";
import NewSorting from "../../../components/sorting/NewSorting";
import { useQueries } from "../../../helpers/query_searchParams/QuerySearchParams";
import { ActionType } from "../../../store/admin/authentication/action";
import AuthenticationContext from "../../../store/admin/authentication/context";
import {
  optRoutesAuthOnProgress,
  queryParamsAuthentication,
} from "./helpers/list";
import OnProgressTable from "./authentication_components/OnProgressTable";

const AuthenticationOnProgress = () => {
  const query = useQueries(useLocation);
  const { AuthenticationDispatch, AuthenticationState } = useContext(
    AuthenticationContext
  );
  const { data, isLoading, isFetching } = useQuery(
    [
      "getAuthenticationOnProgress",
      query.get("username"),
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
    ],
    () =>
      AuthenticationRequest.getAuthenticationOnProgress(AuthenticationDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
    }
  );

  return (
    <div>
      <section id="top">
        <DynamicBreadcrumb data={optRoutesAuthOnProgress} />
        {/* {showFilter && <TransactionTicketFilter />} */}
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="md:mt-12 mt-10">
              <div className="md:mt-12 mt-10">
                <div className="flex justify-between mt-2">
                  <div className="flex items-end">
                    <h1
                      onClick={() =>
                        AuthenticationDispatch({
                          type: ActionType.ShowFilter,
                        })
                      }
                      className="md:text-base text-sm mr-5 mb-1 cursor-pointer hover:text-softPink"
                    >
                      Show Filter
                    </h1>
                  </div>
                  <NewSorting
                    option={[{ value: "created_at", name: "Date" }]}
                  />
                </div>

                <Chip
                  queryParams={queryParamsAuthentication}
                  ActionType={ActionType}
                  dispatchContext={AuthenticationDispatch}
                  stateContext={AuthenticationState}
                />
              </div>
              <div className="flex justify-end my-4">
                {isFetching ? (
                  <div className="flex items-center">
                    <CircularLoad color="#DF5354" height={20} width={20} />
                    <h1 className="ml-2">Updating</h1>
                  </div>
                ) : (
                  <h1 className="md:text-base text-sm">Auto Update</h1>
                )}
              </div>
            </div>
            <div className="flex flex-col w-full mt-8 mb-12">
              <OnProgressTable data={data} />
              <NewPagination
                length={data?.data?.length}
                total={data?.meta?.total}
                isTable={true}
              />
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default AuthenticationOnProgress;
