export const optRoutesAuthOnProgress = [
  {
    id: 1,
    path: "/admin/home",
    name: "Home",
  },
  {
    id: 2,
    path: "/authentication/on-progress",
    name: "Authentication (On Progress)",
  },
];

export const optRoutesAuthOnHold = [
  {
    id: 1,
    path: "/admin/home",
    name: "Home",
  },
  {
    id: 2,
    path: "/authentication/on-hold",
    name: "Authentication (On Hold)",
  },
];

export const optRoutesAuthOnProgressDetail = (code: string) => {
  return [
    {
      id: 1,
      path: "/admin/home",
      name: "Home",
    },
    {
      id: 2,
      path: "/authentication/on-progress",
      name: "Authentication",
    },
    {
      id: 3,
      path: `/authentication/${code}`,
      name: "Details",
    },
  ];
};

export const queryParamsAuthentication: any[] = [
  { real_name: "username", show_name: "username" },
  { real_name: "ticket_bundle_id", show_name: "ticket_name" },
  { real_name: "status", show_name: "status" },
  {
    real_name: "transaction_from",
    show_name: "transaction_from",
  },
  { real_name: "transaction_to", show_name: "transaction_to" },
];
