import moment from "moment";
import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { down, up } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import AuthDataContext from "../../../../store/authentication_data/AuthDataContext";

interface IProps {
  data: any;
}

const TransactionInformation: React.FC<IProps> = ({ data }) => {
  const [dropdown, setdropdown] = useState<boolean>(true);
  const context = useContext(AuthDataContext);
  const { authDataDispatch } = context;
  const params: { id: string } = useParams();

  return (
    <div className="w-full h-full">
      <div className="rounded-md border-2 border-softGray">
        <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">
            Transaction Information
          </h1>

          <div className="flex">
            <div className="flex justify-between items-center">
              {dropdown ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={() => setdropdown(!dropdown)}
                />
              ) : (
                <img
                  src={down}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={() => setdropdown(!dropdown)}
                />
              )}
            </div>
          </div>
        </div>
        {dropdown ? <hr className="border-t-2" /> : ""}
        {dropdown ? (
          <div className="">
            <div className="md:mx-8 mx-5 md:mt-6 mt-3 md:pt-0 pt-2">
              <div>
                <h1 className="md:text-base text-sm text-inputColor">
                  Legit Check Code
                </h1>
                <Input
                  disable={true}
                  type={"text"}
                  name={"code"}
                  value={data.code}
                  placeholder={"Place holder"}
                />
              </div>
            </div>

            <div className="md:flex justify-between md:px-8 px-5 md:mt-4 mt-3 md:pt-0 pt-2 w-full">
              <div className="w-1/2 mr-5">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Product Name
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"code"}
                    value={data?.name}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
              <div className="w-1/2 ml-5">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Brand
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"code"}
                    value={data?.legit_check_brand_name}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex justify-between md:px-8 px-5 md:mt-4 mt-3 md:pt-0 pt-2 w-full">
              <div className="w-1/2 mr-5">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Category
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"code"}
                    value={data?.category}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
              <div className="w-1/2 ml-5">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Status
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"result"}
                    value={data?.result}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex justify-between md:px-8 px-5 md:mt-4  mt-3  md:mb-5 mb-3 md:pt-0 pt-2 w-full">
              <div className="w-1/2 mr-5">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Transaction Date
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"date"}
                    value={moment(data?.created_at).format("llll")}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
              <div className="w-1/2 ml-5">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Last Modified
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"updated_at"}
                    value={moment(data?.updated_at).format("llll")}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>

            <div className="border-t-2 border-softGray flex w-full md:h-16 h-14 items-center justify-center mt-8">
              <button
                onClick={() => {
                  authDataDispatch({
                    type: "show_edit",
                    payload: {
                      code: params.id,
                      status: true,
                    },
                  });
                }}
                type="submit"
                className="focus:outline-none"
              >
                <h1 className="md:text-lg text-base cursor-pointer hover:text-softPink">
                  Edit
                </h1>
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TransactionInformation;
