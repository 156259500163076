import React, { useReducer } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { BrandRequest } from "../../../../apis/admin/brand/BrandRequest";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../../components/chip/Chip";
import CircularLoad from "../../../../components/loading/CircularLoad";
import NonTablePagination from "../../../../components/pagination/NonTablePagination";
import Sorting from "../../../../components/sorting/Sorting";
import {
  BrandContextProvider,
  initialBrandState,
} from "../../../../store/admin/brand/BrandContext";
import { BrandReducer } from "../../../../store/admin/brand/BrandReducer";
import {
  default_sort_brand,
  optRoutes,
  optSorting,
  queryparams,
} from "../helpers/list";
import Loading from "../../../../components/loading/Loading";
import BrandCard from "./BrandCard";
import FilterBrand from "./FilterBrand";
import { useQueries } from "../../../../helpers/query_searchParams/QuerySearchParams";
import DefaultSort from "../../../../helpers/default_sort/DefaultSort";

const Brand = () => {
  const query = useQueries(useLocation);
  const [brandState, brandDispatch] = useReducer(
    BrandReducer,
    initialBrandState
  );
  const { isAsc, sortValue, limit, offset, filter, showFilter } = brandState;
  const { data, isLoading, isFetching } = useQuery(
    [
      "getBrand",
      sortValue,
      isAsc,
      limit,
      offset,
      filter,
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      query.get("is_active"),
      query.get("brand"),
      query.get("legit_check_type"),
    ],
    () =>
      BrandRequest.getBrand(
        "getBrand",
        sortValue,
        isAsc,
        limit,
        offset,
        filter,
        brandDispatch
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return (
    <BrandContextProvider value={{ brandDispatch, brandState }}>
      <div className="pt-28">
        <DynamicBreadcrumb data={optRoutes} />

        {showFilter && <FilterBrand />}
        <div className="md:mt-12 mt-10 ">
          <div className="flex justify-end">
            <h1
              onClick={BrandRequest.resyncProductBrand}
              className="ml-2.5 hover:text-softPink cursor-pointer text-base"
            >
              Resync Product Brands
            </h1>
          </div>
          <div className="md:flex justify-between mt-2.5 hidden">
            <div className="flex">
              <h1
                onClick={() => brandDispatch({ type: "show_filter" })}
                className="text-base mr-5 cursor-pointer hover:text-softPink"
              >
                Show Filter
              </h1>
            </div>
            <Sorting
              dispatchContext={brandDispatch}
              stateContext={brandState}
              option={optSorting}
              defaultSort={DefaultSort(default_sort_brand)}
            />
          </div>
          <Chip
            dispatchContext={brandDispatch}
            stateContext={brandState}
            queryParams={queryparams}
          />
          <div className="flex justify-end my-4">
            {isFetching ? (
              <div className="flex items-center">
                <CircularLoad color="#DF5354" height={20} width={20} />
                <h1 className="ml-2">Updating</h1>
              </div>
            ) : (
              <h1>Auto Update</h1>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div className="w-full">
            {data?.data?.data?.map((el: any) => (
              <BrandCard data={el} key={el.id} />
            ))}
          </div>
        )}
        <NonTablePagination
          offset={offset}
          limit={limit}
          total={data?.data?.meta?.total}
          length={data?.data?.data?.length}
          dispatchContext={brandDispatch}
        />
      </div>
    </BrandContextProvider>
  );
};

export default Brand;
