import { createContext } from "react";
import { IConsignmentContext, IinitialConsignmentState } from "./interfaces";

export const initialConsignmentState: IinitialConsignmentState = {
  showAccept: {
    id: "",
    status: false,
  },
  showReject: {
    id: "",
    status: false,
  },
  showUpgrade: {
    id: "",
    status: false,
  },
  showFilter: false,
  isAsc: 'desc',
  sortValue:"created_at",
  limit: 10,
  offset: 0 ,
  chip: [],
};

const ConsignmentContext = createContext<IConsignmentContext>({
  consignmentState: initialConsignmentState,
  consignmentDispatch: () => {},
});

export const ConsignmentContextProvider = ConsignmentContext.Provider;
export const ConsignmentContextConsumer = ConsignmentContext.Consumer;

export default ConsignmentContext;
