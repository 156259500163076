import React from "react";
import Input from "../../../../../components/input/Input";
import { loadCouponEffect } from "../../../../../helpers/loadOption/LoadOption";
import { handleChangeCoupon } from "../../helpers/FormHandle";
import AsyncSelect from "react-select/async";

interface IProps {
  setCheckStreetwear: (check: boolean) => void;
  setStreetwear: (streetwear: any) => void;
  streetwear: any;
}

const StreetwearCard: React.FC<IProps> = ({
  setCheckStreetwear,
  setStreetwear,
  streetwear,
}) => {
  return (
    <div className="my-5">
      <div className="flex justify-between items-end">
        <h1 className="text-2xl">Streetwear</h1>
        <h1
          onClick={() => setCheckStreetwear(false)}
          className="hover:text-softPink cursor-pointer"
        >
          Delete
        </h1>
      </div>
      <hr className="my-3" />
      <div className="pt-2">
        <div className="flex justify-between">
          <div className="w-1/2">
            <h1 className="md:text-base text-sm text-inputColor">Quantity</h1>
            <Input
              name="quantity"
              type="number"
              value={streetwear.quantity}
              handleChange={(e: any) =>
                setStreetwear({
                  ...streetwear,
                  quantity: +e.target.value,
                })
              }
              placeholder="Place Holder"
            />
          </div>
          <div className="w-1/2 ml-7">
            <h1 className="md:text-base text-sm text-inputColor">
              Validity Period (Day)
            </h1>
            <Input
              name="validity_period"
              type="number"
              value={streetwear.validity_period}
              handleChange={(e: any) =>
                setStreetwear({
                  ...streetwear,
                  validity_period: +e.target.value,
                })
              }
              placeholder="Place Holder"
            />
          </div>
        </div>
        <div className="w-full">
          <div>
            <h1 className="md:text-base text-sm text-inputColor">
              Package Effect
            </h1>
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={(inputValue: any) =>
                loadCouponEffect(inputValue, "streetwear")
              }
              value={{
                value: streetwear.package_coupon_effect_id,
                label: streetwear.package_coupon_name,
              }}
              onChange={(coupon) =>
                handleChangeCoupon(
                  coupon,
                  "streetwear",
                  setStreetwear,
                  streetwear
                )
              }
              className="my-3.5 font-rom text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StreetwearCard;
