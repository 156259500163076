import React from "react";
import Input from "../../../../../components/input/Input";
import { loadCouponEffect } from "../../../../../helpers/loadOption/LoadOption";
import { handleChangeCoupon } from "../../helpers/FormHandle";
import AsyncSelect from "react-select/async";

interface DeluxeCardProps {
  deluxe: any;
  setDeluxe: (deluxe: any) => void;
  setCheckDeluxe: (check: boolean) => void;
}

const DeluxeCard: React.FC<DeluxeCardProps> = ({
  deluxe,
  setDeluxe,
  setCheckDeluxe,
}) => {
  return (
    <div className="my-5">
      <div className="flex justify-between items-end">
        <h1 className="text-2xl">Deluxe</h1>
        <h1
          onClick={() => setCheckDeluxe(false)}
          className="hover:text-softPink cursor-pointer"
        >
          Delete
        </h1>
      </div>
      <hr className="my-3" />
      <div className="pt-2">
        <div className="flex justify-between">
          <div className="w-1/2">
            <h1 className="md:text-base text-sm text-inputColor">Quantity</h1>
            <Input
              name="name"
              type="number"
              value={deluxe.quantity}
              handleChange={(e: any) =>
                setDeluxe({
                  ...deluxe,
                  quantity: +e.target.value,
                })
              }
              placeholder="Place Holder"
            />
          </div>
          <div className="w-1/2 ml-7">
            <h1 className="md:text-base text-sm text-inputColor">
              Validity Period (Day)
            </h1>
            <Input
              name="name"
              type="number"
              value={deluxe.validity_period}
              handleChange={(e: any) =>
                setDeluxe({
                  ...deluxe,
                  validity_period: +e.target.value,
                })
              }
              placeholder="Place Holder"
            />
          </div>
        </div>
        <div className="w-full">
          <div>
            <h1 className="md:text-base text-sm text-inputColor">
              Package Effect
            </h1>
            <AsyncSelect
              cacheOptions
              defaultOptions
              value={{
                value: deluxe.package_coupon_effect_id,
                label: deluxe.package_coupon_name,
              }}
              loadOptions={(inputValue: any) =>
                loadCouponEffect(inputValue, "deluxe")
              }
              onChange={(coupon) =>
                handleChangeCoupon(coupon, "deluxe", setDeluxe, deluxe)
              }
              className="my-3.5 font-rom text-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeluxeCard;
