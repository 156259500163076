export const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/notification",
    name: "Notification",
    hash: "general",
  },
];

export const optRoutesAddNotifSchedule = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/notification",
    name: "Notification",
    hash: "schedule",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
  },
  {
    id: 3,
    path: "/notification/add-notification-schedule",
    name: "Add Notification Schedule",
  },
];

export const optRouteseditNotifSchedule = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/notification",
      name: "Notification",
      hash: "schedule",
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
    },
    {
      id: 3,
      path: `/notification/edit-notification-schedule/${id}`,
      name: "Edit Notification Schedule",
    },
  ];
};

export const queryparamsNotifSchedule: any[] = [
  { real_name: "is_scheduled", show_name: "notification_type" },
  // { real_name: "pre_order", show_name: "status" },
  // { real_name: "seller_id", show_name: "username" },
];
