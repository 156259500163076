import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import NotificationRequest from "../../../../../apis/admin/notification/NotificationRequest";

const UseFetchNotifScheduleById = () => {
  const params: { id: string } = useParams();

  const { data, isLoading } = useQuery(
    ["getNotificationScheduledById", params.id],
    () => NotificationRequest.getNotificationScheduledById(params.id),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default UseFetchNotifScheduleById;
