import { useFormik } from "formik";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import AuthenticationContext from "../../../../store/admin/authentication/context";
import { ActionType } from "../../../../store/admin/authentication/action";
import ToastHandler from "../../../../components/toast/ToastHandler";
import { AuthenticationRequest } from "../../../../apis/admin/authentication/AuthenticationRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import { useParams } from "react-router-dom";
import Input from "../../../../components/input/Input";

const CancelAdditionalPictureModal = () => {
  const queryClient = useQueryClient();
  const params: { code: string } = useParams();
  const { AuthenticationDispatch } = useContext(AuthenticationContext);

  const goBack = () => {
    AuthenticationDispatch({
      type: ActionType.ShowCancelAddPicture,
    });
  };

  const { values, handleSubmit, handleChange, errors, touched, dirty } =
    useFormik({
      initialValues: {
        password: "",
      },
      validationSchema: Yup.object({
        password: Yup.string().required("Password is Required"),
      }),
      onSubmit: async () => {
        await mutation.mutate();
      },
    });

  const mutation = useMutation(
    () =>
      AuthenticationRequest.cancelAdditionalPicture(
        params.code,
        values.password
      ),
    {
      onMutate: () => {
        console.log("mutate cancel additional picture");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(
            `getAuthentication-${params.code}`
          );
          ToastHandler("#00C62E", "Cancel Additional Picture Completed");
          goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white md:w-1/4 w-10/12 rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center px-10 py-7">
          <div className="flex items-center">
            <h1 className="text-xl flex">Cancel</h1>
            <h1 className="ml-2 text-xl text-softPink">Additional Picture</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 py-5 w-full">
          <div className="my-2.5">
            <h1 className="md:text-base text-sm text-inputColor">Password</h1>
            <Input
              type="password"
              name="password"
              handleChange={handleChange}
            />
            {errors.password && touched.password && (
              <p className="text-softPink text-xs font-rom ">
                {errors.password}
              </p>
            )}
          </div>

          <div className="flex justify-center items-center mt-6 mb-2 outline-none">
            <SmallButton
              isSubmitting={mutation.isLoading}
              dirty={dirty}
              height={10}
              loadColor={"white"}
              type={"submit"}
              color={"bg-softPink"}
              hoverColor={"bg-red-300"}
              text={"Submit"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CancelAdditionalPictureModal;
