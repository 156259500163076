export const handleChangeCoupon = (
  coupon: any,
  type: any,
  setState: any,
  state: any
) => {
  switch (type) {
    case "streetwear":
      setState({
        ...state,
        package_coupon_effect_id: coupon.values,
        package_coupon_name: coupon.label,
        legit_check_type_name: coupon.type,
      });
      break;
    case "hype":
      setState({
        ...state,
        package_coupon_effect_id: coupon.values,
        package_coupon_name: coupon.label,
        legit_check_type_name: coupon.type,
      });
      break;
    case "deluxe":
      setState({
        ...state,
        package_coupon_effect_id: coupon.values,
        package_coupon_name: coupon.label,
        legit_check_type_name: coupon.type,
      });
      break;
    case "exclusive":
      setState({
        ...state,
        package_coupon_effect_id: coupon.values,
        package_coupon_name: coupon.label,
        legit_check_type_name: coupon.type,
      });
      break;
    case "luxury":
      setState({
        ...state,
        package_coupon_effect_id: coupon.values,
        package_coupon_name: coupon.label,
        legit_check_type_name: coupon.type,
      });
      break;
    default:
      break;
  }
};
