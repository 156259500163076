import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import ConsignmentRequest from "../../../../apis/admin/consignment/ConsignmentRequest";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesDetail } from "../helpers/list";
import Loading from "../../../../components/loading/Loading";
import ConsignmentInfo from "./ConsignmentInfo";

const ConsignmentDetail = () => {
  const history = useHistory();
  const params: { id: string } = useParams();

  const { data, isLoading } = useQuery(
    "getConsignmentDetail",
    () => ConsignmentRequest.getConsignmentDetail(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return (
    <div>
      <NavDetail
        title1={"Consignment"}
        title2={"Detail"}
        handleBack={() => history.goBack()}
      />
      <div className="md:pt-28">
        <DynamicBreadcrumb data={optRoutesDetail(params.id)} />

        {isLoading ? (
          <div className="flex justify-center mt-10 w-full">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
              <ConsignmentInfo data={data} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConsignmentDetail;
