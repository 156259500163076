export const AuthDataReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show_edit":
      let edit = action.payload;
      document.body.classList[edit.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showEdit: edit };
    case "show_filter":
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case "handle_chip":
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
