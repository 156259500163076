import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { asc, desc } from "../../assets/icons";
import { useQueries } from "../../helpers/query_searchParams/QuerySearchParams";

interface IProps {
  option: any[];
  defaultSort?: any;
  dispatchContext: any;
  stateContext: any;
}

const Sorting: React.FC<IProps> = ({
  option,
  defaultSort,
  dispatchContext,
  stateContext,
}) => {
  const query = useQueries(useLocation);
  const { search, pathname } = useLocation();
  const history = useHistory();

  const handleAsc = () => {
    const prevAsc: any = query.get("isAsc");
    if (prevAsc === "asc") {
      let new_search;
      prevAsc !== null
        ? (new_search = search.replace(`isAsc=${prevAsc}`, "isAsc=desc"))
        : (new_search = `${search}&&isAsc=desc`);
      history.push({ pathname, search: new_search });
      dispatchContext({
        type: "handle_asc",
        payload: "desc",
      });
    } else {
      let new_search;
      prevAsc !== null
        ? (new_search = search.replace(`isAsc=${prevAsc}`, "isAsc=asc"))
        : (new_search = `${search}&&isAsc=asc`);
      history.push({ pathname, search: new_search });
      dispatchContext({
        type: "handle_asc",
        payload: "asc",
      });
    }
  };

  const handleSort = (event: any) => {
    const prevSort: any = query.get("sort");
    let target = event.target.value;
    dispatchContext({
      type: "handle_sort",
      payload: target,
    });
    let new_search;
    prevSort !== null
      ? (new_search = search.replace(`sort=${prevSort}`, `sort=${target}`))
      : (new_search = `${search}&&sort=${target}`);
    history.push({ pathname, search: new_search });
  };

  return (
    <div className="flex justify-end items-center">
      {query.get("isAsc") === "asc" ? (
        <img
          className="cursor-pointer md:w-7 w-5"
          onClick={handleAsc}
          src={asc}
          alt="icon"
        />
      ) : (
        <img
          className="cursor-pointer md:w-7 w-5"
          onClick={handleAsc}
          src={desc}
          alt="icon"
        />
      )}

      <div className="border-2 border-softGray h-10 w-28 rounded-md rounded-r-none flex justify-center items-center ml-5">
        <h1 className="text-base font-rom">Sort by</h1>
      </div>
      <div className="border-2 border-l-0 border-softGray h-10 w-44 rounded-md rounded-l-none flex justify-around items-center">
        <select
          onChange={handleSort}
          name="sorting"
          className="w-full h-full text-base font-rom my-3 px-2 shadow-sm transition duration-100 ease-in-out rounded-md rounded-l-none focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 cursor-pointer"
        >
          {defaultSort !== undefined && (
            <option value={defaultSort as any} selected hidden>
              {defaultSort}
            </option>
          )}
          {option.map((el: any, idx: number) => (
            <option key={idx} value={el.value}>
              {el.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Sorting;
