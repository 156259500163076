const optRoutes = [
  {
    id: 1,
    path: "/package-list",
    name: "Home",
  },
  {
    id: 2,
    path: "/package-list",
    name: "Package List",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
  },
];

const optRoutesAdd = [
  {
    id: 1,
    path: "/package-list",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
    name: "Home",
  },
  {
    id: 2,
    path: "/package-list",
    search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
    name: "Package List",
  },
  {
    id: 3,
    path: `/package-list/add-package`,
    name: "Add Package",
  },
];

const optRoutesEdit = (id: string) => {
  return [
    {
      id: 1,
      path: "/package-list",
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
      name: "Home",
    },
    {
      id: 2,
      path: "/package-list",
      search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
      name: "Package List",
    },
    {
      id: 3,
      path: `/package-list/edit-package/${id}`,
      name: "Edit Package",
    },
  ];
};

const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
  {
    value: "buy_price",
    name: "Buy Price",
  },
  {
    value: "real_price",
    name: "Real Price",
  },
];

export const queryparams: any[] = [
  { real_name: "is_available_to_buy", show_name: "status" },
  { real_name: "available_for", show_name: "available_for" },
  { real_name: "name", show_name: "package_name" },
];

export const default_sort_package = [
  { real_name: "created_at", temp_name: "Date" },
  { real_name: "buy_price", temp_name: "Buy Price" },
  { real_name: "real_price", temp_name: "Real Price" },
];

export { optRoutes, optSorting, optRoutesAdd, optRoutesEdit };
