export interface ToggleProps {
    input: boolean
    handleClick: () => void
}
 
const Toggle: React.FC<ToggleProps> = ({input, handleClick}) => {
    return ( 
        <div className="flex justify-between items-center">
        <div className={(!input ? 'md:w-8 md:h-4 w-5 h-2.5 flex items-center rounded-full p-1 duration-300 ease-in-out bg-gray-500' : 'md:w-8 md:h-4 w-5 h-2.5 flex items-center rounded-full p-1 duration-300 ease-in-out bg-softPink')}>
            <div className={(!input ? 'bg-white md:w-3 w-2 md:h-3 h-1.5 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer' : 'bg-white md:w-3 w-2 md:h-3 h-1.5 rounded-full shadow-md transform duration-300 ease-in-out cursor-pointer md:translate-x-3 translate-x-1.5')} onClick={handleClick} ></div>
        </div>
    </div>
     );
}
 
export default Toggle;