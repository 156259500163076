import moment from "moment";
import React, { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AuthenticationRequest } from "../../../apis/admin/authentication/AuthenticationRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Input from "../../../components/input/Input";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import ToastHandler from "../../../components/toast/ToastHandler";
import { downloadFile } from "../../../helpers/download_file";
import { optRoutesAuthOnProgressDetail } from "./helpers/list";
import { SRLWrapper } from "simple-react-lightbox";
import TextArea from "../../../components/input/TextArea";
import CircularLoad from "../../../components/loading/CircularLoad";
import SmallButton from "../../../components/button/SmallButton";
import AuthenticationContext from "../../../store/admin/authentication/context";
import { ActionType } from "../../../store/admin/authentication/action";
import GuideDetailModal from "./authentication_components/GuideDetailModal";
import DeleteGuideModal from "./authentication_components/DeleteGuideModal";
import AddAdditionalPictureModal from "./authentication_components/AddAdditionalPictureModal";
import CancelAdditionalPictureModal from "./authentication_components/CancelAdditionalPictureModal";

const AuthenticationDetail = () => {
  const history = useHistory();
  const params: { code: string } = useParams();
  const queryClient = useQueryClient();
  const { AuthenticationDispatch, AuthenticationState } = useContext(
    AuthenticationContext
  );
  const {
    showAddGuideDetail,
    showDeleteGuide,
    showAddPicture,
    showCancelAddPicture,
  } = AuthenticationState;
  const [comment, setComment] = useState<string>("");

  const { data, isLoading } = useQuery(
    [`getAuthentication-${params.code}`],
    () => AuthenticationRequest.getAuthenticationDetail(params.code),
    {
      refetchOnWindowFocus: false,
      staleTime: 10000,
      refetchInterval: 5000,
    }
  );

  const downloadImg = (url: string) => {
    downloadFile(`${"image"}.jpeg`, url, () =>
      ToastHandler("#00C62E", "Success Download Image")
    );
  };

  const downloadAllImg = (listImg: any[]) => {};

  const mutationComment = useMutation(
    () => AuthenticationRequest.addComment(params.code, comment),
    {
      onMutate: () => {
        console.log("mutate add comment");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(
            `getAuthentication-${params.code}`
          );
          ToastHandler("#00C62E", "Add Comment Completed");
          setComment("");
        }
      },
    }
  );

  const mutationSendNotif = useMutation(
    () => AuthenticationRequest.sendNotificationSingle(params.code),
    {
      onMutate: () => {
        console.log("mutate send notif");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(
            `getAuthentication-${params.code}`
          );
          ToastHandler("#00C62E", "Send Notification Completed");
        }
      },
    }
  );

  const mutationStartAuthenticating = useMutation(
    () => AuthenticationRequest.startAuthenticating(params.code),
    {
      onMutate: () => {
        console.log("mutate start authenticating");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(
            `getAuthentication-${params.code}`
          );
          ToastHandler("#00C62E", "Authenticating Completed");
        }
      },
    }
  );

  return (
    <>
      <div className="fixed top-0 z-40">
        <NavDetail
          title1={"Authentication"}
          title2={"Details"}
          handleBack={() => history.goBack()}
        />
      </div>
      <div>
        <DynamicBreadcrumb data={optRoutesAuthOnProgressDetail(params.code)} />
        {showAddGuideDetail && (
          <GuideDetailModal category_id={data.product_category_id} />
        )}
        {showDeleteGuide.status && <DeleteGuideModal />}
        {showAddPicture && <AddAdditionalPictureModal />}
        {showCancelAddPicture && <CancelAdditionalPictureModal />}
        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <section>
            <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
              <div id="user" className="md:w-1/3 w-full">
                <div className="w-full rounded-md border-2 border-softGray">
                  <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5 bg">
                    <h1 className="font-medium md:text-xl text-sm">
                      User Information
                    </h1>
                  </div>
                  <hr />
                  <div className="md:mx-8 mx-5 md:my-5 my-3 md:pt-0 pt-1">
                    <div>
                      <h1 className="md:text-base text-sm text-inputColor">
                        Username
                      </h1>
                      <Input
                        disable={true}
                        type={"text"}
                        name={"username"}
                        value={data?.username}
                        placeholder={"Place holder"}
                      />
                    </div>
                    {/* <div className="md:my-3 my-2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Email
                      </h1>
                      <Input
                        disable={true}
                        type={"text"}
                        name={"Customer"}
                        value={data?.code}
                        placeholder={"Place holder"}
                      />
                    </div>
                    <div className="md:my-3 my-2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Gender
                      </h1>
                      <Input
                        disable={true}
                        type={"text"}
                        name={"Customer"}
                        value={data?.code}
                        placeholder={"Place holder"}
                      />
                    </div>
                    <div className="md:my-3 my-2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Phone
                      </h1>
                      <Input
                        disable={true}
                        type={"text"}
                        name={"Customer"}
                        value={data?.code}
                        placeholder={"Place holder"}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div
                id="authentication"
                className="md:w-detailContainer md:mt-0 mt-10 w-full h-full"
              >
                <div className="w-full h-full">
                  <div className="rounded-md border-2 border-softGray">
                    <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                      <h1 className="font-medium md:text-xl text-base">
                        Authentication Information
                      </h1>
                    </div>
                    <hr />
                    <div>
                      <div className="md:mx-8 mx-5 md:mt-5 mt-3 md:pt-0 pt-1">
                        <div>
                          <h1 className="md:text-base text-sm text-inputColor">
                            LC Code
                          </h1>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"Customer"}
                            value={data?.code}
                            placeholder={"Place holder"}
                          />
                        </div>
                      </div>

                      <div className="md:flex w-full md:my-3 my-2">
                        <div className="md:mx-8 mx-5 md:w-1/2">
                          <div>
                            <h1 className="md:text-base text-sm text-inputColor">
                              Username
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"username"}
                              value={data?.username || "-"}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Product Name
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"name"}
                              value={data?.name}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Category
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"Category"}
                              value={data.category || "-"}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Is Insurance
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"is_insurance"}
                              value={data.is_insurance ? "Yes" : "No" || "-"}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Submitted Date & Time
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"created_at"}
                              value={
                                moment(data.created_at).format("LL hh:mm a") ||
                                "-"
                              }
                              placeholder={"Place holder"}
                            />
                          </div>
                        </div>

                        <div className="md:mx-8 mx-5 md:w-1/2">
                          <div>
                            <h1 className="md:text-base text-sm text-inputColor">
                              Status
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"status"}
                              value={data?.status_message || "-"}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Brand
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"legit_check_brand_name"}
                              value={data?.legit_check_brand_name}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Payment Type
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"payment_type"}
                              value={data?.invoice?.payment_type}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Is Rewarded
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"is_rewarded"}
                              value={data?.is_rewarded ? "Yes" : "No"}
                              placeholder={"Place holder"}
                            />
                          </div>
                          <div className="md:my-3 my-2">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Last Modified
                            </h1>
                            <Input
                              disable={true}
                              type={"text"}
                              name={"Date"}
                              value={moment(data.updated_at).format(
                                "LL hh:mm a"
                              )}
                              placeholder={"Place holder"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="pictures" className="w-full mb-8">
              <div className="w-full rounded-md border-2 border-softGray">
                <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5 bg">
                  <h1 className="font-medium md:text-xl text-sm">
                    Uploaded Pictures
                  </h1>
                  <h1
                    onClick={() => downloadAllImg(data.authentication_image)}
                    className="font-medium md:text-base text-xs cursor-pointer hover:text-softPink"
                  >
                    Download All Images
                  </h1>
                </div>
                <hr />
                <SRLWrapper>
                  <div className="grid xl:grid-cols-6 sm:grid-cols-4 grid-cols-2 md:gap-6 gap-5 md:p-8 p-5">
                    {data.authentication_image.map((el: any, idx: number) => (
                      <div
                        key={idx}
                        className="flex flex-col justify-center items-center"
                      >
                        <img
                          key={idx}
                          src={el.original}
                          alt="-"
                          className="rounded-md shadow-md cursor-pointer"
                        />
                        <h1
                          onClick={() => downloadImg(el.original)}
                          className="font-rom md:text-base text-sm mt-2.5 hover:text-softPink cursor-pointer"
                        >
                          Download
                        </h1>
                      </div>
                    ))}
                  </div>
                </SRLWrapper>
              </div>
            </div>
            <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
              <div id="comment" className="md:w-1/3 w-full">
                <div className="w-full rounded-md rounded-b-none border-2 border-softGray">
                  <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                    <h1 className="font-medium md:text-xl text-sm">Comments</h1>
                  </div>
                  <hr />
                  <div className="md:mx-8 mx-5 md:my-5 my-3 pt-1 max-h-96 overflow-y-auto">
                    {data.comment_list.map((el: any, idx: number) => (
                      <div key={idx} className="my-3">
                        <aside className="flex justify-between items-end md:px-2">
                          <h1
                            className={
                              "md:text-base text-sm " +
                              (el.by === "Checker"
                                ? "text-softPink"
                                : "text-black")
                            }
                          >
                            {el.by}
                          </h1>
                          <h1 className="md:text-sm font-rom text-xs">
                            {moment(el.time).fromNow()}
                          </h1>
                        </aside>
                        <div className="md:px-2">
                          <h1 className="md:text-base font-rom text-sm mt-1 break-words">
                            {el.message}
                          </h1>
                        </div>
                        <hr className="mt-3 md:mx-2" />
                      </div>
                    ))}
                  </div>
                  <div className="md:mx-8 mx-5 pt-2 mb-6">
                    <TextArea
                      value={comment}
                      rows={4}
                      name="comment"
                      handleChange={(e: any) => setComment(e.target.value)}
                    />
                  </div>
                </div>
                <div className="border-2 md:h-16 h-14 rounded-b-md border-t-0 border-softGray flex justify-center items-center">
                  <h1
                    onClick={() => mutationComment.mutate()}
                    className="cursor-pointer hover:text-softPink"
                  >
                    {mutationComment.isLoading ? (
                      <CircularLoad color={"#DF5354"} height={20} width={20} />
                    ) : (
                      "Comment"
                    )}
                  </h1>
                </div>
              </div>

              <div
                id="details"
                className="md:w-detailContainer md:mt-0 mt-10 w-full h-full"
              >
                <div className="w-full h-full">
                  <div className="rounded-md border-2 rounded-b-none border-softGray">
                    <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                      <h1 className="font-medium md:text-xl text-base">
                        Authentication details
                      </h1>
                    </div>
                    <hr />
                    <div>
                      <div className="md:mx-8 mx-5 md:mt-6 mt-4 md:pb-2 pb-3">
                        <div className="md:w-1/2">
                          <div className="flex justify-between">
                            <h1 className="md:text-base text-sm text-inputColor">
                              Status
                            </h1>
                            {data?.status_message === "In queueing." && (
                              <div>
                                {mutationStartAuthenticating.isLoading ? (
                                  <CircularLoad
                                    color="#ed4846"
                                    height={20}
                                    width={20}
                                  />
                                ) : (
                                  <h1
                                    onClick={() =>
                                      mutationStartAuthenticating.mutate()
                                    }
                                    className="md:text-sm text-xs text-inputColor hover:text-softPink cursor-pointer"
                                  >
                                    Authenticating
                                  </h1>
                                )}
                              </div>
                            )}
                          </div>
                          <Input
                            disable={true}
                            type={"text"}
                            name={"status"}
                            value={data?.status_message}
                            placeholder={"Place holder"}
                          />
                        </div>
                        <hr className="mt-2 mb-5" />
                        <div className="md:w-1/2 pb-1">
                          <h1 className="md:text-base text-sm text-inputColor">
                            Send Guides
                          </h1>
                          <h1 className="md:text-sm text-xs font-rom text-breadscrumbColor">
                            These are the guide(s) you've sent to user
                          </h1>
                          <div className="my-3">
                            <SmallButton
                              handleClick={() =>
                                AuthenticationDispatch({
                                  type: ActionType.ShowAddGuideDetail,
                                })
                              }
                              width="2/3"
                              borderColor="border-softPink"
                              color="white"
                              border="border"
                              hoverColor="bg-softGray"
                              textColor="text-softPink"
                              text="Choose Guide to Send"
                              type="button"
                              fontSize="rom"
                              rounded="rounded-md"
                            />
                          </div>
                        </div>
                        <section className="grid xl:grid-cols-6 sm:grid-cols-4 grid-cols-2 md:gap-6 gap-5 md:my-6 my-3">
                          {data.guide_detail.map((el: any, idx: number) => (
                            <div
                              key={idx}
                              className="border border-softGray flex flex-col items-center"
                            >
                              <img
                                src={el.summary_image_path}
                                alt="-"
                                className="rounded-t-md"
                              />
                              <div className="w-full flex justify-center items-center my-5">
                                <h1 className="font-rom md:text-sm text-xs text-center">
                                  {el.title}
                                </h1>
                              </div>
                              <div className="border-t w-full flex justify-center items-center py-2.5">
                                <h1
                                  onClick={() =>
                                    AuthenticationDispatch({
                                      type: ActionType.ShowDeleteGuide,
                                      payload: { status: true, id: el.id },
                                    })
                                  }
                                  className="font-rom md:text-sm text-xs cursor-pointer hover:text-softPink"
                                >
                                  Delete
                                </h1>
                              </div>
                            </div>
                          ))}
                        </section>
                      </div>
                    </div>
                  </div>
                  <div className="rounded-md rounded-t-none md:h-16 h-14 flex justify-center items-center border-2 border-t-0">
                    {data.status === 2 ||
                    data.status === 3 ||
                    data.status === 5 ? (
                      <div className="h-full w-full flex items-center justify-center">
                        <h1
                          onClick={() =>
                            AuthenticationDispatch({
                              type: ActionType.ShowAddPicture,
                            })
                          }
                          className="cursor-pointer hover:text-softPink font-rom md:text-base text-sm"
                        >
                          Ask to Additional Picture
                        </h1>
                      </div>
                    ) : (
                      <div className="flex justify-center w-full h-full">
                        {/* <div className="flex items-center justify-center border-r h-full w-1/2">
                          <h1
                            onClick={() =>
                              AuthenticationDispatch({
                                type: ActionType.ShowCancelAddPicture,
                              })
                            }
                            className="cursor-pointer hover:text-softPink font-rom md:text-base text-sm"
                          >
                            Cancel Additional Picture
                          </h1>
                        </div> */}
                        <div className="flex items-center justify-center h-full">
                          <h1
                            onClick={() => mutationSendNotif.mutate()}
                            className="cursor-pointer hover:text-softPink font-rom md:text-base text-sm"
                          >
                            {mutationSendNotif.isLoading ? (
                              <CircularLoad
                                color="#ed4846"
                                height={20}
                                width={20}
                              />
                            ) : (
                              "Send Reminder Notification"
                            )}
                          </h1>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default AuthenticationDetail;
