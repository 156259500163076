import SimpleReactLightbox from "simple-react-lightbox";
import React, { useReducer } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthenticationOnHold from "../../../pages/admin/authentication/AuthenticationOnHold";
import AuthenticationOnProgress from "../../../pages/admin/authentication/AuthenticationOnProgress";
import AuthenticationDetail from "../../../pages/admin/authentication/AuthenticationDetail";
import {
  AuthenticationContextProvider,
  initialAuthenticationState,
} from "../../../store/admin/authentication/context";
import { AuthenticationReducer } from "../../../store/admin/authentication/reducer";

const AuthenticationRoute = () => {
  const [AuthenticationState, AuthenticationDispatch] = useReducer(
    AuthenticationReducer,
    initialAuthenticationState
  );
  return (
    <SimpleReactLightbox>
      <AuthenticationContextProvider
        value={{ AuthenticationState, AuthenticationDispatch }}
      >
        <div className="font-med md:pt-28 pt-20 ">
          <Switch>
            <Route
              path="/authentication/on-progress"
              exact
              component={AuthenticationOnProgress}
            />
            <Route
              path="/authentication/on-hold"
              exact
              component={AuthenticationOnHold}
            />
            <Route
              path="/authentication/:code"
              exact
              component={AuthenticationDetail}
            />
            <Redirect
              from="/authentication"
              to={"/authentication/on-progress"}
            />
          </Switch>
        </div>
      </AuthenticationContextProvider>
    </SimpleReactLightbox>
  );
};

export default AuthenticationRoute;
