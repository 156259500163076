import React, { useContext, useMemo, useState } from "react";
import { cancel } from "../../../../assets/icons";
import AsyncSelect from "react-select/async";
import { loadLegitCheckBrandInspector } from "../../../../helpers/loadOption/LoadOption";
import Input from "../../../../components/input/Input";
import { AuthenticationRequest } from "../../../../apis/admin/authentication/AuthenticationRequest";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Loading from "../../../../components/loading/Loading";
import SmallButton from "../../../../components/button/SmallButton";
import AuthenticationContext from "../../../../store/admin/authentication/context";
import { ActionType } from "../../../../store/admin/authentication/action";
import { useParams } from "react-router-dom";
import ToastHandler from "../../../../components/toast/ToastHandler";
import debounce from "lodash.debounce";

interface IProps {
  category_id: number;
}

const GuideDetailModal: React.FC<IProps> = ({ category_id }) => {
  const queryClient = useQueryClient();
  const params: { code: string } = useParams();
  const { AuthenticationDispatch } = useContext(AuthenticationContext);
  const [brand, setBrand] = useState({ value: 1, label: "Air Jordan" });
  const [title, setTitle] = useState("");
  const [selectedGuides, setSelectedGuides] = useState<any[]>([]);

  const { data, isLoading } = useQuery(
    ["getGuideDetail", brand, title],
    () => AuthenticationRequest.getGuideDetail(brand, category_id, title),
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleSelectedGuide = (id: number) => {
    const selected: any[] = [...selectedGuides];
    const idx = selected.find((guide_id) => guide_id === id);
    if (idx) {
      const new_selected_guide = selected.filter((el) => el !== idx);
      setSelectedGuides(new_selected_guide);
    } else {
      setSelectedGuides(selected.concat(id));
    }
  };

  const goBack = () => {
    AuthenticationDispatch({
      type: ActionType.ShowAddGuideDetail,
    });
  };

  const mutation = useMutation(
    () => AuthenticationRequest.addGuideDetail(params.code, selectedGuides),
    {
      onMutate: () => {
        console.log("mutate add guides");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(
            `getAuthentication-${params.code}`
          );
          ToastHandler("#00C62E", "Send Guides Completed");
          goBack();
        }
      },
    }
  );

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const debouncedEventHandler = useMemo(
    () =>
      debounce(
        (e: React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(e),
        1000
      ),
    [title]
  );

  const handleChangeBrand = (e: any) => {
    setBrand(e);
    setTitle("");
  };
  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center items-center bg-black bg-opacity-70">
      <form
        className="bg-white rounded-lg shadow-xl w-10/12 md:w-1/2 h-5/6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div className="px-10 h-12% flex justify-between items-center w-full">
          <h1
            className="md:text-2xl text-lg leading-6 font-medium text-inputColor"
            id="modal-headline"
          >
            Send Guides
          </h1>
          <img
            src={cancel}
            alt="back"
            className="cursor-pointer md:w-5 w-3"
            onClick={goBack}
          />
        </div>
        <hr className="w-full" />
        <div className="h-76% overflow-auto">
          {isLoading ? (
            <div className="flex justify-center items-center h-4/5">
              <Loading />
            </div>
          ) : (
            <section className="h-full">
              <div className="flex px-10 items-center pt-5 pb-3">
                <div className="w-full md:flex">
                  <div className="md:w-1/2 w-full">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Brand
                    </h1>
                    <AsyncSelect
                      name="brand"
                      cacheOptions
                      defaultOptions
                      loadOptions={loadLegitCheckBrandInspector}
                      onChange={handleChangeBrand}
                      value={brand}
                      className="my-3.5 font-rom text-sm"
                    />
                  </div>
                  <div className="md:w-1/2 w-full md:ml-8">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Search by Guide Title
                    </h1>
                    <Input
                      type={"text"}
                      placeholder="Search"
                      name={"title"}
                      handleChange={debouncedEventHandler}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="grid xl:grid-cols-4 grid-cols-2 md:gap-6 gap-5 md:my-6 my-3 pt-4 pb-8 px-10">
                {data.map((el: any, idx: number) => (
                  <div
                    onClick={() => handleSelectedGuide(el.id)}
                    key={idx}
                    className={
                      "border-2 rounded-md flex flex-col items-center cursor-pointer hover:bg-opacity-10 hover:bg-black shadow-sm " +
                      (selectedGuides.find((id) => id === el.id)
                        ? " border-red-500"
                        : "border-softGray")
                    }
                  >
                    <img
                      src={el.summary_image_path}
                      alt="-"
                      className="rounded-t-md"
                    />
                    <div className="w-full flex justify-center items-center my-5 px-2">
                      <h1 className="font-rom md:text-sm text-xs text-center">
                        {el.title}
                      </h1>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
        </div>
        <hr />
        <div className="px-10 h-12% flex justify-center items-center w-full">
          <SmallButton
            isSubmitting={mutation.isLoading}
            text={"Send"}
            type={"button"}
            handleClick={() => mutation.mutateAsync()}
            height={10}
          />
        </div>
      </form>
    </div>
  );
};

export default GuideDetailModal;
