import React, { useContext, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TypeRequest } from "../../../../apis/admin/type/TypeRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import Input from "../../../../components/input/Input";
import TypeContext from "../../../../store/admin/type/TypeContext";
import Loading from "../../../../components/loading/Loading";
import { useFormik } from "formik";
import * as Yup from "yup";

const EditTypeCard = () => {
  const {
    typeDispatch,
    typeState: {
      showEdit: { id },
    },
  } = useContext(TypeContext);

  const { data, isLoading } = useQuery(
    "getTypeDetail",
    () => TypeRequest.getTypeDetail(id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );
  
  useEffect(() => {
    setValues({
      ...values,
      price: data?.price,
      price_without_insurance: data?.price_without_insurance,
      referral_reward_percent: data?.referral_reward_percent * 100,
      max_referral_reward: data?.max_referral_reward,
    });
    return function () {
      handleReset({});
    };
  }, [data]);

  const {
    values,
    setValues,
    dirty,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      price: 0,
      price_without_insurance: 0,
      referral_reward_percent: 0,
      max_referral_reward: 0,
    },
    validationSchema: Yup.object({
      price: Yup.number()
        .required("Price is required")
        .min(1, "Price minimum is IDR 1"),
      price_without_insurance: Yup.number()
        .required("Price is required")
        .min(1, "Price minimum is IDR 1"),
      referral_reward_percent: Yup.number()
        .required("referral reward is required")
        .min(1, "referral reward minimum is IDR 1"),
        max_referral_reward: Yup.number()
        .required("max referral reward is required")
        .min(1, "max referral reward minimum is IDR 1"),
        
    }),
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => TypeRequest.editType(id, values, typeDispatch, setSubmitting),
    {
      onMutate: () => {
        console.log("mutate edit type");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getType");
        if (error) {
          handleReset({});
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70 pt-14">
      <div className="bg-white w-2/5 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center px-8 py-6">
          <div className="flex items-center">
            <h1 className="text-xl flex">Edit</h1>
            <h1 className="ml-1 text-xl text-softPink">Type</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-4"
            onClick={() => {
              typeDispatch({
                type: "show_edit",
                payload: {
                  id: 0,
                  status: false,
                },
              });
            }}
          />
        </div>
        <hr />
        {isLoading ? (
          <div className="w-full h-52 flex justify-center items-center">
            <Loading />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="my-5">
            <div className="flex w-full px-8">
              <div className="w-1/2 mr-6">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Legit Check Type
                  </h1>
                  <Input
                    type={"text"}
                    disable={true}
                    name={"accept"}
                    placeholder={"Place holder"}
                    value={data.name}
                  />
                </div>

                <div className="w-full mt-0.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Price (Insurance)
                  </h1>
                  <Input
                    type={"number"}
                    handleChange={handleChange}
                    disable={false}
                    name={"price"}
                    placeholder={"Place holder"}
                    value={values.price}
                  />
                  {touched.price && errors.price ? (
                    <h1 className="text-softPink font-rom mb-1 -mt-1 text-sm">
                      {errors.price}
                    </h1>
                  ) : null}
                </div>
       
                <div className="w-full mt-0.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Max. Referral Reward
                  </h1>
                  <Input
                    type={"number"}
                    handleChange={handleChange}
                    disable={false}
                    name={"max_referral_reward"}
                    placeholder={"Place holder"}
                    value={values.max_referral_reward}
                  />
                  {touched.price && errors.price ? (
                    <h1 className="text-softPink font-rom mb-1 -mt-1 text-sm">
                      {errors.price}
                    </h1>
                  ) : null}
                </div>
       
              </div>

              <div className="w-1/2">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Referral Reward (%)
                  </h1>
                  <Input
                    type={"number"}
                    handleChange={handleChange}
                    disable={false}
                    name={"referral_reward_percent"}
                    value={values.referral_reward_percent}
                    placeholder={"Place holder"}
                  />
                  {touched.referral_reward_percent &&
                  errors.referral_reward_percent ? (
                    <h1 className="text-softPink font-rom mb-1 -mt-1 text-sm">
                      {errors.referral_reward_percent}
                    </h1>
                  ) : null}
                </div>
                <div className="w-full mt-0.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Price (Standard)
                  </h1>
                  <Input
                    type={"number"}
                    handleChange={handleChange}
                    disable={false}
                    value={values.price_without_insurance}
                    name={"price_without_insurance"}
                    placeholder={"Place holder"}
                  />
                  {touched.price_without_insurance &&
                  errors.price_without_insurance ? (
                    <h1 className="text-softPink font-rom mb-1 -mt-1 text-sm">
                      {errors.price_without_insurance}
                    </h1>
                  ) : null}
                </div>
              </div>
            </div>
            <hr className="mt-5" />

            <div className="flex justify-center my-7 outline-none">
              <SmallButton
                isSubmitting={isSubmitting}
                loadColor={"white"}
                dirty={dirty}
                type={"submit"}
                color={"bg-softPink"}
                hoverColor={"bg-red-300"}
                text={"Edit"}
                textColor={"text-white"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditTypeCard;
