import { useFormik } from "formik";
import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import AuthenticationContext from "../../../../store/admin/authentication/context";
import { ActionType } from "../../../../store/admin/authentication/action";
import ToastHandler from "../../../../components/toast/ToastHandler";
import { AuthenticationRequest } from "../../../../apis/admin/authentication/AuthenticationRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import { useParams } from "react-router-dom";
import Select from "react-select";
import TextArea from "../../../../components/input/TextArea";

const noteOpt = [
  {
    label: "Please reupload and recapture pictures accordingly...",
    value:
      "Please reupload and recapture pictures accordingly to the sample pictures given. Make sure the lighting is good, optimal clearness and clean pictures. (Mohon untuk foto dan upload kembali foto anda sesuai dengan contoh foto yang diberikan. Pastikan foto di pencahayaan yang bagus, terang dan jelas.)",
  },
  {
    label: "As per your pictures uploaded, found to be suspicious...",
    value:
      "As per your pictures uploaded, found to be suspicious that the pictures uploaded are not the same products mixed in one legit check services. In order to maintain our credibility, it will be released as INDEFINABLE or INDEFINABLE NO REFUND depends on the terms. (Berdasarkan foto yang diupload, terdapat kecurigaan atas foto yang diunggah ke sistem kami, produk yang diunggah bukan merupakan satu produk atau terjadi pencampuran produk. Demi menjaga kredibilitas, kami merilis hadil Indefinable atau Indefinable No Refund tergantung kondisi.)",
  },
  {
    label: "Custom Notes",
    value: "",
  },
];

const AddAdditionalPictureModal = () => {
  const queryClient = useQueryClient();
  const params: { code: string } = useParams();
  const { AuthenticationDispatch } = useContext(AuthenticationContext);

  const goBack = () => {
    AuthenticationDispatch({
      type: ActionType.ShowAddPicture,
    });
  };

  const {
    values,
    handleSubmit,
    handleChange,
    errors,
    touched,
    dirty,
    setValues,
  } = useFormik({
    initialValues: {
      note: { label: "", value: "" },
    },
    validationSchema: Yup.object({
      note: Yup.object().shape({
        value: Yup.string().required("Note is Required"),
      }),
    }),
    onSubmit: async () => {
      await mutation.mutate();
    },
  });

  const mutation = useMutation(
    () =>
      AuthenticationRequest.addAdditionalPicture(
        params.code,
        values.note.value
      ),
    {
      onMutate: () => {
        console.log("mutate add additional picture");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(
            `getAuthentication-${params.code}`
          );
          ToastHandler("#00C62E", "Ask to Add Additional Picture Completed");
          goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white md:w-1/3 w-10/12 rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center px-10 py-7">
          <div className="flex items-center">
            <h1 className="text-xl flex">Add</h1>
            <h1 className="ml-2 text-xl text-softPink">Additional Picture</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        <form onSubmit={handleSubmit} className="px-10 py-5 w-full">
          <div className="my-2.5">
            <h1 className="md:text-base text-sm text-inputColor">Note Type</h1>
            <Select
              options={noteOpt}
              onChange={(e: any) => setValues({ ...values, note: e })}
              className="font-rom mt-2"
            />
          </div>
          {values.note.label && (
            <div>
              <h1 className="md:text-base text-sm text-inputColor">
                Description
              </h1>
              <div className="mt-1.5">
                <TextArea
                  disable={values.note.label === "Custom Notes" ? false : true}
                  rows={7}
                  name="note.value"
                  value={values.note.value}
                  handleChange={handleChange}
                />
              </div>
              {errors.note && touched.note && (
                <p className="text-softPink text-xs font-rom ">
                  {errors.note.value}
                </p>
              )}
            </div>
          )}

          <div className="flex justify-center items-center mt-6 mb-2 outline-none">
            <SmallButton
              isSubmitting={mutation.isLoading}
              dirty={dirty}
              loadColor={"white"}
              type={"submit"}
              color={"bg-softPink"}
              hoverColor={"bg-red-300"}
              text={"Accept"}
              textColor={"text-white"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAdditionalPictureModal;
