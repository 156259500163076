import React from "react";
import { useHistory } from "react-router-dom";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesAdd } from "../helpers/List";
import FormAddPackage from "./FormAddPackage";

const AddPackage = () => {
  const history = useHistory();

  return (
    <>
      <NavDetail
        title1={"Add"}
        title2={"Package"}
        handleBack={() => history.goBack()}
      />
      <div className="md:pt-28">
        <DynamicBreadcrumb data={optRoutesAdd} />

        <FormAddPackage />
      </div>
    </>
  );
};

export default AddPackage;
