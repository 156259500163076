import { createContext } from "react";
import { IinitialBrandState, IBrandContext } from "./interfaces";

export const initialBrandState: IinitialBrandState = {
  showFilter: false,
  isAsc: "desc",
  sortValue: "is_active",
  limit: 10,
  offset: 0,
  filter: {
    legit_check_type: "",
    brand: "",
    status: "",
  },
  chip: [],
};

const BrandContext = createContext<IBrandContext>({
  brandState: initialBrandState,
  brandDispatch: () => {},
});

export const BrandContextProvider = BrandContext.Provider;
export const BrandContextConsumer = BrandContext.Consumer;

export default BrandContext;
