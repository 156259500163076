import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Input from "../../../../components/input/Input";
import * as Yup from "yup";
import CircularLoad from "../../../../components/loading/CircularLoad";
import NewFilePonds from "../../../../components/input/NewFilepond";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { loadCouponEffect } from "../../../../helpers/loadOption/LoadOption";
import AsyncSelect from "react-select/async";
import PackageListRequest from "../../../../apis/admin/package_list/PackageListRequest";
import { useHistory } from "react-router-dom";
import { handleChangeCoupon } from "../helpers/FormHandle";

const FormAddPackage = () => {
  const history = useHistory();
  const [files, setFiles] = useState<any>(null);
  const [check, setCheck] = useState<any>({
    streetwear: false,
    hype: false,
    deluxe: false,
    exclusive: false,
    luxury: false,
  });

  const [streetwear, setStreetwear] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });

  const [hype, setHype] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });

  const [deluxe, setDeluxe] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });

  const [exclusive, setExclusive] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });

  const [luxury, setLuxury] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });

  const form_data = new FormData();
  const {
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      real_price: 0,
      buy_price: 0,
      is_available_to_buy: "true",
      available_for: "0",
      description: "",
      is_for_insurance: "true",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      real_price: Yup.number()
        .required("Real Price is required")
        .min(1, "Minimum price is IDR 1"),
      buy_price: Yup.number()
        .required("Buy Price is required")
        .min(1, "Minimum price is IDR 1"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values: any) => {
      files !== null && form_data.append("image", files);
      form_data.append("name", values.name);
      form_data.append("real_price", values.real_price);
      form_data.append("buy_price", values.buy_price);
      form_data.append("description", values.description);
      form_data.append("available_for", values.available_for);
      form_data.append("is_available_to_buy", values.is_available_to_buy);
      form_data.append("is_for_insurance", values.is_for_insurance);
      check.streetwear &&
        streetwear.quantity &&
        streetwear.validity_period &&
        streetwear.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(streetwear));
      check.hype &&
        hype.quantity &&
        hype.validity_period &&
        hype.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(hype));
      check.deluxe &&
        deluxe.quantity &&
        deluxe.validity_period &&
        deluxe.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(deluxe));
      check.exclusive &&
        exclusive.quantity &&
        exclusive.validity_period &&
        exclusive.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(exclusive));
      check.luxury &&
        luxury.quantity &&
        luxury.validity_period &&
        luxury.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(luxury));
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      PackageListRequest.addPackage(form_data, setSubmitting, () =>
        history.goBack()
      ),
    {
      onMutate: () => {
        console.log("mutate add package");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getPackageList");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    check: any,
    setCheck: any
  ) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  return (
    <form onSubmit={handleSubmit} className="flex w-full justify-between my-14">
      <div className="md:w-1/3 w-full h-full rounded-md border-2 border-softGray">
        <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">Package Image</h1>
        </div>
        <hr />

        <div className="md:mx-8 mx-5 my-7">
          <div>
            <h1 className="md:text-base text-sm text-mobile text-inputColor">
              Display Image
            </h1>
            <h1 className="md:text-xs font-rom text-inputColor md:mt-2 mt-1 mb-1">
              (4 x 3), (PNG,JPG,JPEG) max. 1 MB
            </h1>
            <NewFilePonds
              setFiles={setFiles}
              imageType={["image/png", "image/jpg", "image/jpeg"]}
              cropRatio={"4:3"}
              maxFileSize={"1MB"}
            />
          </div>
        </div>
      </div>

      <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
        <div className="rounded-md rounded-b-none border-2 border-softGray">
          <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Package List Information
            </h1>
          </div>
          <hr />

          <div className="md:flex w-full">
            <div className="md:px-8 mt-6 md:w-full flex justify-between">
              <div className="w-1/2 mr-7">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">Name</h1>
                  <Input
                    name="name"
                    type="text"
                    handleChange={handleChange}
                    placeholder="Place Holder"
                  />
                  {errors.name && touched.name && (
                    <p className="text-softPink text-xs font-rom -mt-2">
                      {errors.name}
                    </p>
                  )}
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Real Price
                  </h1>
                  <Input
                    name="real_price"
                    type={"number"}
                    handleChange={handleChange}
                    placeholder="Place Holder"
                  />
                  {errors.real_price && touched.real_price && (
                    <p className="text-softPink text-xs font-rom -mt-2">
                      {errors.real_price}
                    </p>
                  )}
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Package Status
                  </h1>
                  <select
                    onChange={handleChange}
                    name={"is_available_to_buy"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="true">On Selling</option>
                    <option value="false">Not Active</option>
                  </select>
                </div>
              </div>
              <div className="w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Package for
                  </h1>
                  <select
                    onChange={handleChange}
                    name={"is_for_insurance"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="true">Insurance</option>
                    <option value="false">Not Insurance</option>
                  </select>
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Buy Price
                  </h1>
                  <Input
                    name="buy_price"
                    type={"number"}
                    handleChange={handleChange}
                    placeholder="Place Holder"
                  />
                  {errors.buy_price && touched.buy_price && (
                    <p className="text-softPink text-xs font-rom -mt-2">
                      {errors.buy_price}
                    </p>
                  )}
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Available for
                  </h1>
                  <select
                    onChange={handleChange}
                    name={"available_for"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="0">All Users</option>
                    <option value="1">Consignment</option>
                    <option value="4">Consignment Vip</option>
                    <option value="2">Gift</option>
                    <option value="3">Tester</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="px-8 w-full mb-2.5">
            <h1 className="md:text-base text-sm text-inputColor">
              Description
            </h1>
            <textarea
              name="description"
              onChange={handleChange}
              placeholder="Place Holder"
              className="md:h-20 h-14 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 py-1 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
            ></textarea>
            {errors.description && touched.description && (
              <p className="text-softPink text-xs font-rom -mt-2.5">
                {errors.description}
              </p>
            )}
          </div>

          <div className="px-8 mb-6">
            <div className="w-full">
              <h1>Select Coupon Effect (Minimal 1)</h1>
              <div className="my-2.5 flex justify-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.streetwear}
                      onChange={(e: any) =>
                        handleChangeChecked(e, check, setCheck)
                      }
                      name="streetwear"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Streetwear"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.hype}
                      onChange={(e: any) =>
                        handleChangeChecked(e, check, setCheck)
                      }
                      name="hype"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Hype"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.deluxe}
                      onChange={(e: any) =>
                        handleChangeChecked(e, check, setCheck)
                      }
                      name="deluxe"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Deluxe"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.exclusive}
                      onChange={(e: any) =>
                        handleChangeChecked(e, check, setCheck)
                      }
                      name="exclusive"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Exclusive"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check.luxury}
                      onChange={(e: any) =>
                        handleChangeChecked(e, check, setCheck)
                      }
                      name="luxury"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Luxury"
                />
              </div>
            </div>

            {check.streetwear && (
              <div className="my-5">
                <div className="flex justify-between items-end">
                  <h1 className="text-2xl">Streetwear</h1>
                  <h1
                    onClick={() =>
                      setCheck({
                        ...check,
                        streetwear: false,
                      })
                    }
                    className="hover:text-softPink cursor-pointer"
                  >
                    Delete
                  </h1>
                </div>
                <hr className="my-3" />
                <div className="pt-2">
                  <div className="flex justify-between">
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Quantity
                      </h1>
                      <Input
                        name="quantity"
                        type="number"
                        handleChange={(e: any) =>
                          setStreetwear({
                            ...streetwear,
                            quantity: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                    <div className="w-1/2 ml-7">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Validity Period (Day)
                      </h1>
                      <Input
                        name="validity_period"
                        type="number"
                        handleChange={(e: any) =>
                          setStreetwear({
                            ...streetwear,
                            validity_period: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div>
                      <h1 className="md:text-base text-sm text-inputColor">
                        Package Effect
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue: any) =>
                          loadCouponEffect(inputValue, "streetwear")
                        }
                        onChange={(coupon) =>
                          handleChangeCoupon(
                            coupon,
                            "streetwear",
                            setStreetwear,
                            streetwear
                          )
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {check.hype && (
              <div className="my-5">
                <div className="flex justify-between items-end">
                  <h1 className="text-2xl">Hype</h1>
                  <h1
                    onClick={() =>
                      setCheck({
                        ...check,
                        hype: false,
                      })
                    }
                    className="hover:text-softPink cursor-pointer"
                  >
                    Delete
                  </h1>
                </div>
                <hr className="my-3" />
                <div className="pt-2">
                  <div className="flex justify-between">
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Quantity
                      </h1>
                      <Input
                        name="quantity"
                        type="number"
                        handleChange={(e: any) =>
                          setHype({
                            ...hype,
                            quantity: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                    <div className="w-1/2 ml-7">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Validity Period (Day)
                      </h1>
                      <Input
                        name="validity_period"
                        type="number"
                        handleChange={(e: any) =>
                          setHype({
                            ...hype,
                            validity_period: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div>
                      <h1 className="md:text-base text-sm text-inputColor">
                        Package Effect
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue: any) =>
                          loadCouponEffect(inputValue, "hype")
                        }
                        onChange={(coupon) =>
                          handleChangeCoupon(coupon, "hype", setHype, hype)
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {check.deluxe && (
              <div className="my-5">
                <div className="flex justify-between items-end">
                  <h1 className="text-2xl">Deluxe</h1>
                  <h1
                    onClick={() =>
                      setCheck({
                        ...check,
                        deluxe: false,
                      })
                    }
                    className="hover:text-softPink cursor-pointer"
                  >
                    Delete
                  </h1>
                </div>
                <hr className="my-3" />
                <div className="pt-2">
                  <div className="flex justify-between">
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Quantity
                      </h1>
                      <Input
                        name="name"
                        type="number"
                        handleChange={(e: any) =>
                          setDeluxe({
                            ...deluxe,
                            quantity: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                    <div className="w-1/2 ml-7">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Validity Period (Day)
                      </h1>
                      <Input
                        name="name"
                        type="number"
                        handleChange={(e: any) =>
                          setDeluxe({
                            ...deluxe,
                            validity_period: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div>
                      <h1 className="md:text-base text-sm text-inputColor">
                        Package Effect
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue: any) =>
                          loadCouponEffect(inputValue, "deluxe")
                        }
                        onChange={(coupon) =>
                          handleChangeCoupon(
                            coupon,
                            "deluxe",
                            setDeluxe,
                            deluxe
                          )
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {check.exclusive && (
              <div className="my-5">
                <div className="flex justify-between items-end">
                  <h1 className="text-2xl">Exclusive</h1>
                  <h1
                    onClick={() =>
                      setCheck({
                        ...check,
                        exclusive: false,
                      })
                    }
                    className="hover:text-softPink cursor-pointer"
                  >
                    Delete
                  </h1>
                </div>
                <hr className="my-3" />
                <div className="pt-2">
                  <div className="flex justify-between">
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Quantity
                      </h1>
                      <Input
                        name="name"
                        type="number"
                        handleChange={(e: any) =>
                          setExclusive({
                            ...exclusive,
                            quantity: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                    <div className="w-1/2 ml-7">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Validity Period (Day)
                      </h1>
                      <Input
                        name="name"
                        type="number"
                        handleChange={(e: any) =>
                          setExclusive({
                            ...exclusive,
                            validity_period: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div>
                      <h1 className="md:text-base text-sm text-inputColor">
                        Package for
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue: any) =>
                          loadCouponEffect(inputValue, "exclusive")
                        }
                        onChange={(coupon) =>
                          handleChangeCoupon(
                            coupon,
                            "exclusive",
                            setExclusive,
                            exclusive
                          )
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {check.luxury && (
              <div className="my-5">
                <div className="flex justify-between items-end">
                  <h1 className="text-2xl">Luxury</h1>
                  <h1
                    onClick={() =>
                      setCheck({
                        ...check,
                        luxury: false,
                      })
                    }
                    className="hover:text-softPink cursor-pointer"
                  >
                    Delete
                  </h1>
                </div>
                <hr className="my-3" />
                <div className="pt-2">
                  <div className="flex justify-between">
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Quantity
                      </h1>
                      <Input
                        name="name"
                        type="number"
                        handleChange={(e: any) =>
                          setLuxury({
                            ...luxury,
                            quantity: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                    <div className="w-1/2 ml-7">
                      <h1 className="md:text-base text-sm text-inputColor">
                        Validity Period (Day)
                      </h1>
                      <Input
                        name="name"
                        type="number"
                        handleChange={(e: any) =>
                          setLuxury({
                            ...luxury,
                            validity_period: +e.target.value,
                          })
                        }
                        placeholder="Place Holder"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <div>
                      <h1 className="md:text-base text-sm text-inputColor">
                        Package for
                      </h1>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        loadOptions={(inputValue: any) =>
                          loadCouponEffect(inputValue, "luxury")
                        }
                        onChange={(coupon) =>
                          handleChangeCoupon(
                            coupon,
                            "luxury",
                            setLuxury,
                            luxury
                          )
                        }
                        className="my-3.5 font-rom text-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="rounded-md rounded-t-none border-2 border-t-0 border-softGray flex w-full md:h-18 h-16 mb-10">
          <div className="w-1/2 flex items-center justify-center border-r-2 border-softGray ">
            <button
              type="reset"
              onClick={() => {
                handleReset({});
                setCheck({
                  streetwear: false,
                  hype: false,
                  deluxe: false,
                  exclusive: false,
                  luxury: false,
                });
              }}
              className="focus:outline-none"
            >
              <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                Clear
              </h1>
            </button>
          </div>
          <div className="w-1/2 flex items-center justify-center">
            <button type="submit" className="focus:outline-none">
              {isSubmitting ? (
                <CircularLoad color={"black"} height={16} width={16} />
              ) : (
                <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                  Save
                </h1>
              )}
            </button>
          </div>
        </div>
     
      </div>
    </form>
  );
};

export default FormAddPackage;
