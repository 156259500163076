import { CrossStorageClient } from "cross-storage";
import ToastHandler from "../../components/toast/ToastHandler";
import axiosCelestine from "../../config/AxiosCelestine";
import LocalStorage from "../../config/LocalStorage";
import { validateRole } from "../../helpers/checkRole/CheckRole";
import { setWithExpiry } from "../../helpers/setToken/SetToken";
import { AcceptedRole } from "../../routes/interfaces";

class AuthRequest {
  static Login = async (
    values: any,
    setSubmitting: any,
    history: any,
    setFail: any
  ) => {
    try {
      const storage = new CrossStorageClient(
        process.env.REACT_APP_CROSS_STORAGE_URL || "",
        {}
      );
      axiosCelestine()
        .post("admin/auth/signin", {
          username: values.username,
          password: values.password,
        })
        .then(({ data }) => {
          setSubmitting(false);
          const token = data.token;
          const role = data.user.role.name;
          storage
            .onConnect()
            .then(function () {
              return storage.set("AUTH_TOKEN", token);
            })
            .then(() => {
              storage.set("ROLE", role);
            });
          LocalStorage.AUTH_TOKEN = token;
          LocalStorage.ROLE = role;
          document.body.classList.remove("h-screen", "overflow-hidden");
          if (validateRole(role, AcceptedRole.Inspector)) {
            history.push("/authentication/on-progress");
          } else if (validateRole(role, AcceptedRole.Labeler)) {
            history.push("/authentication-data");
          } else if (validateRole(role, AcceptedRole.Admin)) {
            history.push("/");
          }
          ToastHandler("#00C62E", "Login successfully!");
        })
        .catch((err) => {
          setSubmitting(false);
          setFail(true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  static refreshToken = async () => {
    try {
      const data = await axiosCelestine().get("auth/refresh");
      const token = data.data.token;
      setWithExpiry("retail_token", token, 10800000);
    } catch (err) {
      console.log(err);
    }
  };
}

export default AuthRequest;
