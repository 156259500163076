export const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/legit-check-brand",
    search: "limit=10&&offset=0&&sort=is_active&&isAsc=desc",
    name: "brand",
  },
];

export const optRoutesEdit = (id: string) => {
  return [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/legit-check-brand",
      search: "limit=10&&offset=0&&sort=is_active&&isAsc=desc",
      name: "brand",
    },
    {
      id: 3,
      path: `/legit-check-brand/edit-brand/${id}`,
      name: "Edit Legit Check brand",
    },
  ];
};

export const optSorting = [
  {
    value: "created_at",
    name: "Date",
  },
  {
    value: "is_active",
    name: "Status",
  },
];

export const default_sort_brand = [
  { real_name: "created_at", temp_name: "Date" },
  { real_name: "is_active", temp_name: "Status" },
];

export const queryparams: any[] = [
  { real_name: "is_active", show_name: "status" },
  { real_name: "legit_check_type_id", show_name: "legit_check_type" },
  { real_name: "brand", show_name: "brand" },
];
