import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
interface IProps {
  data: any;
}

const ProductImage: React.FC<IProps> = ({ data }) => {
  return (
    <div>
      <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
        <h1 className="font-medium md:text-xl text-base">Product Images</h1>
      </div>
      <hr />

      <div className="md:mx-8 mx-5 md:my-6 my-5">
        <div className="flex flex-col justify-center">
          <h1 className="md:text-base text-sm text-inputColor">
            Detail Images
          </h1>
        </div>
        <SRLWrapper>
          <div className="grid grid-cols-2 gap-3">
            {data?.checker_image.map((el: any, index: number) => (
              <div key={index}>
                <img
                  src={el}
                  alt={`img - ${(index + 1).toString()}`}
                  className="my-3 rounded-md shadow-md cursor-pointer"
                />
              </div>
            ))}
          </div>
        </SRLWrapper>
      </div>
    </div>
  );
};

export default ProductImage;
