import React, { useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import LoginGuard from "./helpers/guards/LoginGuard";
import { CrossStorageClient } from "cross-storage";
import LocalStorage from "./config/LocalStorage";
import Loading from "./components/loading/Loading";
import moment from "moment";
import Login from "./pages/auth/Login";
import jwtDecode from 'jwt-decode';

function App() {
  const storage = new CrossStorageClient(
    process.env.REACT_APP_CROSS_STORAGE_URL || "",
    {}
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    storage
      .onConnect()
      .then(function () {
        return storage.get("AUTH_TOKEN");
      })
      .then(function (res) {
        if (res) {
          const decoded: any = jwtDecode(res);
          if (decoded.exp * 1000 < moment().valueOf()) {
            storage.del("AUTH_TOKEN");
          } else {
            LocalStorage.AUTH_TOKEN = res;
            (window as any).LocalStorage = LocalStorage;
          }
        }
      })
      .then(function () {
        return storage.get("ROLE");
      })
      .then(function (res) {
        LocalStorage.ROLE = res;
        (window as any).LocalStorage = LocalStorage;
      })
      .catch(function (err) {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center pt-28">
        <Loading />
      </div>
    );
  }
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          exact
          component={Login}
        />
        <LoginGuard path="/" component={MainRoutes} />
        <Redirect from="/" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
