const optRoutes = [
  {
    id: 1,
    path: "/",
    name: "Home",
  },
  {
    id: 2,
    path: "/authentication-data",
    name: "Authentication Data",
  },
];

const optSorting = [
  {
    value: "created_at",
    name: "Transaction Date",
  },
  {
    value: "updated_at",
    name: "Last Modified",
  },
  {
    value: "status",
    name: "Status",
  },
];

const queryparamsAuthData: any[] = [
  { real_name: "legit_check_code", show_name: "legit_check_code" },
  { real_name: "product_name", show_name: "product_name" },
  { real_name: "category", show_name: "category" },
  { real_name: "status", show_name: "status" },
  {
    real_name: "transaction_from",
    show_name: "transaction_from",
  },
  { real_name: "transaction_to", show_name: "transaction_to" },
];

export { optRoutes, optSorting, queryparamsAuthData };
