import { cancel } from "../../assets/icons";

export interface NavDetailProps {
  handleBack: () => void;
  title1: string;
  title2: string;
}

const NavDetail: React.FC<NavDetailProps> = ({
  handleBack,
  title1,
  title2,
}) => {
  return (
    <nav className="font-med fixed z-40 flex flex-wrap items-center justify-between xl:px-16 md:px-12 px-8 py-2 w-full md:h-20 h-14 left-0 bg-black shadow-md">
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center justify-center w-full">
          <h1 className="text-softPink md:mr-1 md:text-2xl text-base text-center">
            {title1}
          </h1>
          <h1 className="text-white md:text-2xl text-base ml-1">{title2}</h1>
        </div>

        <div className="flex items-center justify-end">
          <img
            src={cancel}
            onClick={handleBack}
            className="md:w-5 w-4 cursor-pointer"
            alt=""
          />
        </div>
      </div>
    </nav>
  );
};

export default NavDetail;
