import { useLocation } from "react-router-dom";
import { useQueries } from "../../helpers/query_searchParams/QuerySearchParams";

const DefaultSort = (listSort: any[]) => {
  const query = useQueries(useLocation);
  const sort_query = query.get("sort");
  for (const key in listSort) {
    if (listSort[key].real_name === sort_query) {
      return listSort[key].temp_name;
    }
  }
};

export default DefaultSort;
