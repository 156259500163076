import React, { useEffect, useReducer, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router";
import AuthDataRequest from "../../../apis/authData/AuthDataRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../components/loading/Loading";
import NavDetail from "../../../components/navbar/NavDetail";
import {
  AuthDataContextProvider,
  initialAuthDataState,
} from "../../../store/authentication_data/AuthDataContext";
import { AuthDataReducer } from "../../../store/authentication_data/AuthDataReducer";
import AuthDataEdit from "../authDataComponent/authDataEditComponent/AuthDataEdit";
import ProductImage from "./auth_data_detail_sub_component/ProductImage";
import TransactionInformation from "./auth_data_detail_sub_component/TransactionInformation";

const AuthenticationDataDetail = () => {
  const params: { id: string } = useParams();
  const { goBack } = useHistory();
  const [authDataState, authDataDispatch] = useReducer(
    AuthDataReducer,
    initialAuthDataState
  );
  const { showEdit } = authDataState;
  const [load, setLoad] = useState<boolean>(true);
  const route = [
    {
      id: 1,
      path: "/",
      name: "Home",
    },
    {
      id: 2,
      path: "/authentication-data",
      name: "Authentication Data",
    },
    {
      id: 3,
      path: `/authentication-data/${params.id}`,
      name: "Details",
    },
  ];

  const { data, isLoading } = useQuery("getAuthDetail", () =>
    AuthDataRequest.getAuthDetail(params.id)
  );

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, []);

  return (
    <AuthDataContextProvider value={{ authDataDispatch, authDataState }}>
      {showEdit.status ? <AuthDataEdit /> : ""}
      <div>
        <NavDetail
          title1={"Transaction"}
          title2={"Details"}
          handleBack={() => goBack()}
        />

        <div className="pt-28">
          <DynamicBreadcrumb data={route} />

          {isLoading || load ? (
            <div className="flex justify-center mt-10">
              <Loading />
            </div>
          ) : (
            <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
              <div className="md:w-1/3 w-full h-full rounded-md border-2 border-softGray">
                <ProductImage data={data} />
              </div>
              <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
                <TransactionInformation data={data} />
              </div>
            </div>
          )}
        </div>
      </div>
    </AuthDataContextProvider>
  );
};

export default AuthenticationDataDetail;
