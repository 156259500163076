import ToastHandler from "../../../components/toast/ToastHandler";
import axiosCovid from "../../../config/AxiosCovid";
import ApplyChip from "../../../helpers/applyChip/ApplyChip";
import { queryparamsNotifSchedule } from "../../../pages/admin/notification/helpers/list";

class NotificationRequest {
  static sendNotifGlobal = async (payload: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const data = await axiosCovid().post(
        "admin/notification/broadcast",
        payload
      );
      ToastHandler("#00C62E", "Sent Completed");
      setSubmitting(false);
      return data;
    } catch (error) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ToastHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ToastHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static sendNotifPersonal = async (payload: any, setSubmitting: any) => {
    try {
      setSubmitting(true);
      const data = await axiosCovid().post("admin/notification/send", payload);
      ToastHandler("#00C62E", "Sent Completed");
      setSubmitting(false);
      return data;
    } catch (error) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ToastHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ToastHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static getNotificationScheduled = async (dispatch: any) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosCovid().get(
        "admin/notification/scheduled",
        {
          params: {
            ...(query.get("is_scheduled") !== null && {
              is_scheduled: query.get("is_scheduled"),
            }),
            // ...(query.get("available_for") !== null && {
            //   available_for: query.get("available_for") || filter.available_for,
            // }),
            // ...(query.get("name") !== null && {
            //   name: query.get("name") || filter.package_name,
            // }),
            limit: query.get("limit") !== null ? query.get("limit") : 10,
            offset: query.get("offset") !== null ? query.get("offset") : 0,
            sort: `${
              query.get("sort") !== null ? query.get("sort") : "updated_at"
            } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
          },
        }
      );
      ApplyChip(queryparamsNotifSchedule, dispatch);
      return results;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getNotificationScheduledById = async (id: string) => {
    try {
      const results: any = await axiosCovid().get(
        `admin/notification/show/${id}`
      );
      const { data } = results;
      return data;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static deleteSchedule = async (id: string, goBack: () => void) => {
    try {
      await axiosCovid().delete(`admin/notification/${id}`);
      ToastHandler("#00C62E", "Delete Completed");
      goBack();
    } catch (error) {
      ToastHandler("#ed4846", "Delete Failed");
    }
  };

  static updateNotif = async (
    payload: any,
    setSubmitting: any,
    id: string,
    goback: () => void
  ) => {
    try {
      setSubmitting(true);
      const data = await axiosCovid().patch(
        `admin/notification/${id}`,
        payload
      );
      ToastHandler("#00C62E", "Edit Completed");
      setSubmitting(false);
      goback();
      return data;
    } catch (error) {
      setSubmitting(false);
      if (typeof error.response.data.errors === "object") {
        ToastHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ToastHandler("#ed4846", error.response.data.errors || "Edit Failed");
      }
    }
  };
}

export default NotificationRequest;
