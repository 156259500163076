import React from "react";
import { Redirect, Switch } from "react-router-dom";
import Navbar from "../components/navbar/Navbar";
import ScrollToTop from "../helpers/scrollToTop/ScrollToTop";
import RoleGuard from "../helpers/guards/RoleGuard";
import AuthenticationDataRoute from "./labeler/authenticationDataRoutes/AuthenticationDataRoute";
import ConsignmentRoute from "./admin/consignmentRoutes/ConsignmentRoute";
import PackageListRoute from "./admin/packageListRoutes/PackageListRoute";
import BrandRoute from "./admin/brandRoutes/BrandRoute";
import TypeRoute from "./admin/TypeRoutes/TypeRoute";
import LocalStorage from "../config/LocalStorage";
import NotificationRoute from "./admin/notification_routes/NotificationRoute";
import { validateRole } from "../helpers/checkRole/CheckRole";
import { AcceptedRole } from "./interfaces";
import AuthenticationRoute from "./admin/authentication_routes/AuthenticationRoute";

const MainRoutes = () => {
  const role = LocalStorage.ROLE;

  return (
    <>
      <Navbar />
      <div className="xl:px-16 md:px-12 px-8">
        <ScrollToTop />
        <Switch>
          <RoleGuard
            role={role}
            path="/authentication"
            component={AuthenticationRoute}
            accepted_role={AcceptedRole.Inspector}
          />
          <RoleGuard
            role={role}
            path="/authentication-data"
            component={AuthenticationDataRoute}
            accepted_role={AcceptedRole.Labeler}
          />
          <RoleGuard
            role={role}
            path="/consignment"
            component={ConsignmentRoute}
            accepted_role={AcceptedRole.Admin}
          />
          <RoleGuard
            role={role}
            path="/package-list"
            component={PackageListRoute}
            accepted_role={AcceptedRole.Admin}
          />
          <RoleGuard
            role={role}
            path="/legit-check-brand"
            component={BrandRoute}
            accepted_role={AcceptedRole.Admin}
          />
          <RoleGuard
            role={role}
            path="/legit-check-type"
            component={TypeRoute}
            accepted_role={AcceptedRole.Admin}
          />
          <RoleGuard
            role={role}
            path="/notification"
            component={NotificationRoute}
            accepted_role={AcceptedRole.Admin}
          />

          {validateRole(role, AcceptedRole.Admin) ? (
            <Redirect from="/" exact to="/consignment" />
          ) : validateRole(role, AcceptedRole.Labeler) ? (
            <Redirect from="/" exact to="/authentication-data" />
          ) : validateRole(role, AcceptedRole.Inspector) ? (
            <Redirect from="/" exact to="/authentication/on-progress" />
          ) : (
            <Redirect from="/" exact to="/login" />
          )}
        </Switch>
      </div>
    </>
  );
};

export default MainRoutes;
