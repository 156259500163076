import React, { useContext } from "react";
import { cancel } from "../../../assets/icons";
import Input from "../../../components/input/Input";
import AsyncSelect from "react-select/async";
import {
  loadOptionsBrand,
  loadOptionsProduct,
  loadOptionsCategory,
} from "../../../helpers/loadOption/LoadOption";
import AuthDataContext from "../../../store/authentication_data/AuthDataContext";
import SmallButton from "../../../components/button/SmallButton";
import SetQueryString from "../../../helpers/query_searchParams/SetQueryString";
import { useHistory, useLocation } from "react-router-dom";
import { queryparamsAuthData } from "../helpers/List";

const AuthDataFilter: React.FC = () => {
  const context = useContext(AuthDataContext);
  const { authDataState, authDataDispatch } = context;
  const { showFilter } = authDataState;
  const { search, pathname } = useLocation();
  const history = useHistory();

  const handleShowFilter = () => {
    authDataDispatch({ type: "show_filter" });
  };

  const handleChangeFilter = (event: any, default_name?: string) => {
    let name;
    let value;
    if (!event?.label) {
      name = event.target.name;
      value = event.target.value;
    } else {
      value = event.value;
      name = default_name;
    }
    SetQueryString(
      queryparamsAuthData,
      value,
      name,
      search,
      pathname,
      history
    );
  };

  const applyFilter = () => {
    setTimeout(handleShowFilter, 500);
  };

  return (
    <div>
      {showFilter && (
        <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
          <div
            className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden h-4/5 shadow-xl transform transition-all md:max-w-2xl max-w-lg w-1/3"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="px-10 flex justify-between items-center w-full h-1/6">
              <h1
                className="md:text-2xl text-lg leading-6 font-medium text-inputColor"
                id="modal-headline"
              >
                Filter
              </h1>
              <img
                src={cancel}
                alt="back"
                className="cursor-pointer md:w-5 w-3"
                onClick={handleShowFilter}
              />
            </div>
            <hr className="w-full " />
            <div className="overflow-auto h-2/3">
              <div className="flex gap-8 px-10 items-center h-1/4">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Legit Check Code
                  </h1>
                  <Input
                    type={"text"}
                    placeholder={"Place holder"}
                    name={"legit_check_code"}
                    handleChange={handleChangeFilter}
                  />
                </div>
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Product Name
                  </h1>
                  <AsyncSelect
                    name="product_name"
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptionsProduct}
                    onChange={(e) => handleChangeFilter(e, "product_name")}
                    className="my-3.5 font-rom text-sm"
                  />
                </div>
              </div>
              <hr />
              <div className="flex gap-8 px-10 items-center h-1/4">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Category
                  </h1>
                  <AsyncSelect
                    name="category"
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptionsCategory}
                    onChange={(e) => handleChangeFilter(e, "product_category")}
                    className="my-3.5 font-rom text-sm"
                  />
                </div>
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Brand
                  </h1>
                  <AsyncSelect
                    name="brand"
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptionsBrand}
                    onChange={(e) => handleChangeFilter(e, "brand")}
                    className="my-3 font-rom text-sm"
                  />
                </div>
              </div>
              <hr className="w-full" />
              <div className="flex px-10 items-center h-1/4">
                <div className="w-full">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Status
                  </h1>
                  <select
                    onChange={handleChangeFilter}
                    name="status"
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                  >
                    <option selected disabled hidden>
                      Select Here
                    </option>
                    <option value="6">Authentic</option>
                    <option value="7">Fake</option>
                    <option value="8">Indefinable(No Refund)</option>
                    <option value="9">Indefinable(Refund)</option>
                    <option value="10">Invalid(Refund)</option>
                    <option value="11">Indefinable(Expired)</option>
                  </select>
                </div>
              </div>

              <hr className="w-full" />

              <div className="flex px-10 gap-8 items-center h-1/4">
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Transaction Date (From)
                  </h1>
                  <input
                    type="date"
                    onChange={handleChangeFilter}
                    name="transaction_from"
                    className="md:h-10 h-8 w-full font-rom rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                  />
                </div>
                <div className="w-1/2">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Transaction Date (To)
                  </h1>
                  <input
                    type="date"
                    onChange={handleChangeFilter}
                    name="transaction_to"
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                  />
                </div>
              </div>
              <hr className="w-full " />
            </div>
            <hr className="w-full " />
            <div className="flex justify-center items-center h-1/6">
              <SmallButton
                type={"button"}
                color={"bg-softPink"}
                textColor={"text-white"}
                hoverColor={"bg-red-300"}
                handleClick={applyFilter}
                text={"Apply"}
                loadColor={"white"}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthDataFilter;
