export enum ActionType {
  ShowFilter,
  HandleChip,
  ShowDeleteGuide,
  ShowAddPicture,
  ShowCancelAddPicture,
  ShowAddGuideDetail,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export interface ShowDeleteGuide {
  type: ActionType.ShowDeleteGuide;
  payload: {
    status: boolean;
    id: number;
  };
}

export interface ShowAddPicture {
  type: ActionType.ShowAddPicture;
}

export interface ShowCancelAddPicture {
  type: ActionType.ShowCancelAddPicture;
}

export interface ShowAddGuideDetail {
  type: ActionType.ShowAddGuideDetail;
}

export type AuthenticationAction =
  | ShowFilter
  | HandleChip
  | ShowAddPicture
  | ShowCancelAddPicture
  | ShowAddGuideDetail
  | ShowDeleteGuide;
