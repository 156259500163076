import axiosCelestine from "../../config/AxiosCelestine";
import axiosCovid from "../../config/AxiosCovid";
import axiosMercury from "../../config/AxiosMercury";

export const loadOptionsCategory = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("public/product-category", {
      params: {
        name: inputValue,
        limit: 30,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      values: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsCategoryGroup = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get(
      "admin/product-category-group",
      {
        params: {
          name: inputValue,
          limit: 30,
        },
      }
    );
    const option = data.data.map((el: any) => ({
      label: el.name,
      values: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsProduct = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("public/product/search", {
      params: {
        search: inputValue,
      },
    });

    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsBrand = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("public/product-brand", {
      params: {
        name: inputValue,
        sort: "created_at asc",
      },
    });

    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsUsername = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/user", {
      params: {
        username: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.username,
      values: el.username,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};
export const loadOptionsUsernameById = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/user", {
      params: {
        username: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.username,
      values: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadPackageList = async (inputValue: string) => {
  try {
    const { data } = await axiosCovid().get("admin/package-list", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      values: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadCouponEffect = async (inputValue: string, type?: string) => {
  try {
    const { data } = await axiosCovid().get("admin/package-coupon-effect", {
      params: {
        title: inputValue,
        legit_check_type_name: type,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.title,
      values: el.id,
      type: el.legit_check_type_name,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadLegitCheckBrand = async (inputValue: string) => {
  try {
    const { data } = await axiosCovid().get("admin/legit-check-brand", {
      params: {
        name: inputValue,
        limit: 50,
        sort: "created_at asc",
      },
    });

    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadLegitCheckBrandInspector = async (inputValue: string) => {
  try {
    const { data } = await axiosCovid().get("inspector/legit-check-brand", {
      params: {
        name: inputValue,
        limit: 50,
        is_active: true,
        parent_id: "0",
        sort: "created_at asc",
      },
    });

    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsTag = async (inputValue: string) => {
  try {
    const { data } = await axiosCelestine().get("admin/tag", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      values: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsStore = async (inputValue: string) => {
  try {
    const { data } = await axiosMercury().get("admin/retail-shop", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const loadOptionsProductStore = async (inputValue: string) => {
  try {
    const { data } = await axiosMercury().get("admin/retail-product", {
      params: {
        name: inputValue,
      },
    });
    const option = data.data.map((el: any) => ({
      label: el.name,
      value: el.id,
    }));
    return option;
  } catch (err) {
    console.log(err);
  }
};
