import React, { useReducer } from "react";
import { useQuery } from "react-query";
import AuthDataRequest from "../../../apis/authData/AuthDataRequest";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import Chip from "../../../components/chip/Chip";
import Loading from "../../../components/loading/Loading";
import Sorting from "../../../components/sorting/Sorting";
import AuthDataFilter from "./AuthDataFilter";
import AuthDataTable from "./AuthDataTable";
import {
  AuthDataContextProvider,
  initialAuthDataState,
} from "../../../store/authentication_data/AuthDataContext";
import { AuthDataReducer } from "../../../store/authentication_data/AuthDataReducer";
import CircularLoad from "../../../components/loading/CircularLoad";
import { optRoutes, optSorting, queryparamsAuthData } from "../helpers/List";
import { default_sort } from "../helpers/setDefaultSorting";
import NewSorting from "../../../components/sorting/NewSorting";
import NewChip from "../../../components/chip/NewChip";
import { useLocation } from "react-router-dom";
import { useQueries } from "../../../helpers/query_searchParams/QuerySearchParams";

const AuthenticationData = () => {
  const [authDataState, authDataDispatch] = useReducer(
    AuthDataReducer,
    initialAuthDataState
  );
  const query = useQueries(useLocation);

  const { data, isLoading, isFetching } = useQuery(
    [
      "getAuthData",
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
      query.get("product_name"),
      query.get("category"),
      query.get("status"),
      query.get("brand"),
      query.get("transaction_from"),
      query.get("transaction_to"),
    ],
    () => AuthDataRequest.getAuthData(authDataDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return (
    <AuthDataContextProvider value={{ authDataDispatch, authDataState }}>
      <div className="md:pt-28">
        <DynamicBreadcrumb data={optRoutes} />

        <div className="md:mt-12 mt-10 ">
          <div className="md:flex justify-between mt-4 hidden">
            <div className="flex">
              <h1
                onClick={() => authDataDispatch({ type: "show_filter" })}
                className="text-base mr-5 cursor-pointer hover:text-softPink"
              >
                Show Filter
              </h1>
            </div>
            <NewSorting option={optSorting} defaultSort={default_sort} />
          </div>
          <NewChip
            dispatchContext={authDataDispatch}
            stateContext={authDataState}
            queryParams={queryparamsAuthData}
          />
          <div className="flex justify-end mt-8 -mb-7">
            {isFetching ? (
              <div className="flex items-center">
                <CircularLoad color="#DF5354" height={20} width={20} />
                <h1 className="ml-2">Updating</h1>
              </div>
            ) : (
              <h1>Auto Update</h1>
            )}
          </div>
        </div>
        <AuthDataFilter />
        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="md:flex hidden">
              <AuthDataTable dataProps={data} />
            </div>
          </div>
        )}
      </div>
    </AuthDataContextProvider>
  );
};

export default AuthenticationData;
