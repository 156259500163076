import React, { useReducer } from "react";
import { useQuery } from "react-query";
import ConsignmentRequest from "../../../../apis/admin/consignment/ConsignmentRequest";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../../../components/loading/CircularLoad";
import Loading from "../../../../components/loading/Loading";
import Sorting from "../../../../components/sorting/Sorting";
import {
  ConsignmentContextProvider,
  initialConsignmentState,
} from "../../../../store/admin/consignment/ConsignmentContext";
import { ConsignmentReducer } from "../../../../store/admin/consignment/ConsignmentReducer";
import ConsignmentCard from "./ConsignmentCard";
import {
  default_sort_consignment,
  optRoutes,
  optSorting,
} from "../helpers/list";
import NonTablePagination from "../../../../components/pagination/NonTablePagination";
import ShowUpgrade from "./ShowUpgrade";
import ShowAccept from "./ShowAccept";
import ShowReject from "./ShowReject";
import { useLocation } from "react-router-dom";
import { useQueries } from "../../../../helpers/query_searchParams/QuerySearchParams";
import DefaultSort from "../../../../helpers/default_sort/DefaultSort";

const Consignment = () => {
  const query = useQueries(useLocation);
  const [consignmentState, consignmentDispatch] = useReducer(
    ConsignmentReducer,
    initialConsignmentState
  );
  const {
    isAsc,
    sortValue,
    limit,
    offset,
    showAccept,
    showReject,
    showUpgrade,
  } = consignmentState;

  const { data, isLoading, isFetching } = useQuery(
    [
      "getConsignment",
      sortValue,
      isAsc,
      limit,
      offset,
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () =>
      ConsignmentRequest.getConsignment(
        "getConsignment",
        sortValue,
        isAsc,
        limit,
        offset
      ),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );

  return (
    <ConsignmentContextProvider
      value={{ consignmentDispatch, consignmentState }}
    >
      <div className="pt-28">
        <DynamicBreadcrumb data={optRoutes} />

        {showUpgrade.status && <ShowUpgrade />}
        {showAccept.status && <ShowAccept />}
        {showReject.status && <ShowReject />}

        <div className="md:mt-12 mt-10 ">
          <div className="md:flex justify-between mt-4 hidden">
            <div className="flex"></div>
            <Sorting
              dispatchContext={consignmentDispatch}
              stateContext={consignmentState}
              option={optSorting}
              defaultSort={DefaultSort(default_sort_consignment)}
            />
          </div>
          <div className="flex justify-end my-4">
            {isFetching ? (
              <div className="flex items-center">
                <CircularLoad color="#DF5354" height={20} width={20} />
                <h1 className="ml-2">Updating</h1>
              </div>
            ) : (
              <h1>Auto Update</h1>
            )}
          </div>
        </div>

        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div className="w-full">
            {data?.data.map((el: any) => (
              <ConsignmentCard data={el} key={el.id} />
            ))}
            <NonTablePagination
              offset={offset}
              limit={limit}
              total={data?.meta?.total}
              length={data?.data?.length}
              dispatchContext={consignmentDispatch}
            />
          </div>
        )}
      </div>
    </ConsignmentContextProvider>
  );
};

export default Consignment;
