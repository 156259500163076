import React from "react";
import { Route, Switch } from "react-router-dom";
import LegitChekType from "../../../pages/admin/type/type/LegitChekType";

const TypeRoute = () => {
  return (
    <div className="font-med">
      <Switch>
        <Route path="/legit-check-type" exact component={LegitChekType} />
      </Switch>
    </div>
  );
};

export default TypeRoute;
