import React from "react";
import { Route, Switch } from "react-router-dom";
import Consignment from "../../../pages/admin/consignment/consignment_component/Consignment";
import ConsignmentDetail from "../../../pages/admin/consignment/consignment_detail_component/ConsignmentDetail";

const AuthenticationDataRoute = () => {
  return (
    <div className="font-med">
      <Switch>
        <Route path="/consignment" exact component={Consignment} />
        <Route path="/consignment/:id" exact component={ConsignmentDetail} />
      </Switch>
    </div>
  );
};

export default AuthenticationDataRoute;
