export interface InputProps {
  value?: any;
  handleChange?: any;
  type: string | any;
  name: string;
  placeholder?: string;
  disable?: boolean;
  required?: boolean;
}

const Input: React.FC<InputProps> = ({
  required,
  disable,
  value,
  handleChange,
  type,
  name,
  placeholder,
}) => {
  return (
    <input
      required={required}
      disabled={disable}
      type={type}
      value={value}
      onChange={handleChange}
      name={name}
      placeholder={placeholder}
      className="md:h-10 h-8 w-full font-rom rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray  focus:ring-1 focus:outline-none font-nor"
    />
  );
};

export default Input;
