import React, { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { AuthenticationRequest } from "../../../../apis/admin/authentication/AuthenticationRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import ToastHandler from "../../../../components/toast/ToastHandler";
import { ActionType } from "../../../../store/admin/authentication/action";
import AuthenticationContext from "../../../../store/admin/authentication/context";

const DeleteGuideModal = () => {
  const params: { code: string } = useParams();
  const { AuthenticationDispatch, AuthenticationState } = useContext(
    AuthenticationContext
  );
  const { showDeleteGuide } = AuthenticationState;

  const goBack = () => {
    AuthenticationDispatch({
      type: ActionType.ShowDeleteGuide,
      payload: {
        id: 0,
        status: false,
      },
    });
  };

  const DeleteAuthentication = () => {
    mutationDeleteGuide.mutate();
  };

  const queryClient = useQueryClient();
  const mutationDeleteGuide = useMutation(
    () =>
      AuthenticationRequest.deleteGuideDetail(params.code, showDeleteGuide.id),
    {
      onMutate: () => {
        console.log("mutate delete guide");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries(
            `getAuthentication-${params.code}`
          );
          ToastHandler("#00C62E", "Delete Guide Detail Completed");
          goBack()
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-1/4 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-8">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <div className="px-10 pb-3">
          <div className="flex flex-col items-center">
            <h1 className="text-xl">Delete Guide Detail</h1>
            <h1 className="text-base text-center w-60 text-inputColor mt-4 font-rom">
              Are you sure you want to delete the guide detail ?
            </h1>
          </div>

          <div className="flex justify-center my-8 outline-none">
            <SmallButton
              loadColor={"white"}
              type={"button"}
              handleClick={goBack}
              color={"bg-white"}
              border={"border-2"}
              borderColor={"border-breadscrumbColor"}
              hoverColor={"opacity-70"}
              text={"Cancel"}
              textColor={"text-inputColor"}
              height={10}
            />
            <div className="mx-1.5"></div>
            <SmallButton
              isSubmitting={mutationDeleteGuide.isLoading}
              loadColor={"white"}
              type={"button"}
              height={10}
              handleClick={DeleteAuthentication}
              color={"bg-softPink"}
              hoverColor={"bg-red-300"}
              text={"Delete"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteGuideModal;
