import moment from "moment";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { deleteIcon, edit } from "../../../../assets/icons";
import NewPagination from "../../../../components/pagination/NewPagination";
import NotificationContext from "../../../../store/admin/notification/NotificationContext";

interface IProps {
  data: any;
}

const NotificationTable: React.FC<IProps> = ({ data }) => {
  const { notificationDispatch } = useContext(NotificationContext);
  const history = useHistory();

  return (
    <div className="mb-20">
      <table className="w-full border relative text-center text-sm">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Subject</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Notification for</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Notification Action</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Notification Type</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Send Time</h1>
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r">{el.subject}</td>
              <td className="border-r">
                {el.is_global ? "Global" : "Personal"}
              </td>
              <td className="border-r">
                {el.payload?.action_name?.split("_").join(" ") || "None"}
              </td>
              <td className="border-r font-med">
                {el.is_recurring ? (
                  <h1 className="text-yellow-500">Repeat</h1>
                ) : (
                  <h1 className="text-success">Single Send</h1>
                )}
              </td>

              <td className="border-r">
                {el.is_recurring ? (
                  <div>
                    <h1>{el.recurring_types[0].split("_").join(" ")}</h1>
                    <h1>{`(${moment(el.scheduled_sent_time).format(
                      "HH:mm:ss"
                    )})`}</h1>
                  </div>
                ) : (
                  <div>
                    <h1>{moment(el.scheduled_sent_time).format("ll")}</h1>
                    <h1>{`(${moment(el.scheduled_sent_time).format(
                      "HH:mm:ss"
                    )})`}</h1>
                  </div>
                )}
              </td>

              <td className="">
                <div className="w-full flex justify-around px-3">
                  <img
                    onClick={() =>
                      history.push(
                        `/notification/edit-notification-schedule/${el._id}`
                      )
                    }
                    src={edit}
                    alt="edit"
                    className="w-5 cursor-pointer"
                  />
                  <img
                    onClick={() =>
                      notificationDispatch({
                        type: "show_delete",
                        payload: {
                          status: true,
                          id: el._id,
                        },
                      })
                    }
                    src={deleteIcon}
                    alt="delete"
                    className="w-4 cursor-pointer"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <NewPagination
        length={data?.data?.data?.length}
        total={data?.data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default NotificationTable;
