import React from "react";
import { Route, Switch } from "react-router-dom";
import Brand from "../../../pages/admin/brand/brand/Brand";
import Editbrand from "../../../pages/admin/brand/edit_brand/Editbrand";

const BrandRoute = () => {
  return (
    <div className="font-med">
      <Switch>
        <Route path="/legit-check-brand" exact component={Brand} />
        <Route path="/legit-check-brand/edit-brand/:id" exact component={Editbrand} />
      </Switch>
    </div>
  );
};

export default BrandRoute;
