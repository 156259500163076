import axios from "axios";
import LocalStorage from "./LocalStorage";

const axiosCelestine = () => {
  let value = LocalStorage.AUTH_TOKEN
  return axios.create({
    baseURL: process.env.REACT_APP_CELESTINE_API_BASE_URL,
    headers: {
      Authorization: `Bearer ${value}`,
    },
  });
};


export default axiosCelestine;
