import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { formatRupiah } from "../../../../helpers/format_rupiah/FormatRupiah";

interface IProps {
  data: any;
}

const BrandCard: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  return (
    <div className="w-full h-auto border-2 border-softGray rounded-md my-8 py-5 hover:border-blue-300 hover:shadow-lg">
      <div className="grid grid-cols-5">
        <div className="border-r-2 border-softGray flex justify-center">
          <img
            src={data.logo_image_url}
            alt="brand_img"
            className="w-32 m-1 rounded-md shadow-lg"
          />
        </div>

        <div className="flex flex-col px-7 py-4 border-r-2 border-softGray">
          <h1 className="text-xl">{data.name}</h1>
          <div className="mt-3.5">
            <h1 className="text-base font-rom">
              type: {data.legit_check_type_name || "-"}
            </h1>
            <div className="flex mt-0.5">
              <h1 className="mr-1 text-lg">Status :</h1>
              {data.is_active ? (
                <h1 className="text-success text-lg">Active</h1>
              ) : (
                <h1 className="text-softPink text-lg">Not Active</h1>
              )}
            </div>
          </div>
        </div>

        <div className="flex flex-col col-span-2 justify-between px-7 py-4 border-r-2 border-softGray">
          <h1 className="text-base font-rom">
            Available for:{" "}
            {data.list_product_category_group.map((el: any, index: any) =>
              index !== data.list_product_category_group.length - 1
                ? `${el.name}, `
                : el.name
            )}
          </h1>
          <div className="flex w-full justify-between">
            <div className="w-2/5">
              <h1 className="text-base font-rom">Price (Insurance)</h1>
              <h1 className="text-base mt-1">{formatRupiah(data.price | 0)}</h1>
            </div>
            <div className="w-1 border-l-2"></div>
            <div className="w-2/4 ml-1">
              <h1 className="text-base font-rom">Price (Standard)</h1>
              <h1 className="text-base mt-1">{formatRupiah(data.price_without_insurance | 0)}</h1>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-evenly mx-10">
          <div className="flex justify-evenly w-full">
            <h1
              onClick={() => history.push(`${url}/edit-brand/${data.id}`)}
              className="text-base hover:text-softPink cursor-pointer"
            >
              Edit
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandCard;
