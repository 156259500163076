import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import PackageListRequest from "../../../../apis/admin/package_list/PackageListRequest";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesEdit } from "../helpers/List";
import FormEditPackage from "./FormEditPackage";
import Loading from "../../../../components/loading/Loading";

const EditPackage = () => {
  const params: { id: string } = useParams();
  const history = useHistory();

  const { data, isLoading } = useQuery(
    "getPackageDetail",
    () => PackageListRequest.getPackageDetail(params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  return (
    <>
      <NavDetail
        title1={"Edit"}
        title2={"Package"}
        handleBack={() => history.goBack()}
      />
      <div className="md:pt-28">
        <DynamicBreadcrumb data={optRoutesEdit(params.id)} />

        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <FormEditPackage data={data} />
        )}
      </div>
    </>
  );
};

export default EditPackage;
