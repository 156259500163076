import { useFormik } from "formik";
import { useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import NotificationRequest from "../../../../../apis/admin/notification/NotificationRequest";

const FormHandleGeneral = () => {
  const [file, setFile] = useState<any>(null);
  const form_data = new FormData();
  const {
    handleChange,
    handleSubmit,
    setValues,
    errors,
    values,
    touched,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      radio: "global",
      username: {
        values: 0,
        label: "Select Here",
      },
      checkCategoryGroup: false,
      checkCategory: false,
      checkBrand: false,
      checkTag: false,
      subject: "",
      description: "",
      notification_action: "",
      product_detail: {
        values: 0,
        label: "Select Here",
      },
      product_category_group: {
        values: 0,
        label: "Select Here",
      },
      product_category: {
        values: 0,
        label: "Select Here",
      },
      brand: {
        values: 0,
        label: "Select Here",
      },
      tag: {
        values: 0,
        label: "Select Here",
      },
      store: {
        value: 0,
        label: "Select Here",
      },
      product_store: {
        value: 0,
        label: "Select Here",
      },
      package_list: {
        values: 0,
        label: "Select Here",
      },
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values: any) => {
      file !== null && form_data.append("image", file);
      form_data.append("subject", values.subject);
      form_data.append("description", values.description);
      if (values.radio === "global") {
        form_data.append("topic", "global_user");
      }
      if (values.radio === "personal" && values.username.values !== 0) {
        form_data.append("user_id", values.username.values);
        form_data.append("user_role", "user");
      }
      if (values.notification_action === "view_product_detail") {
        const pay_pDetail = JSON.stringify({
          type: "action",
          action_name: "view_product_detail",
          product_id: values.product_detail.value,
        });
        form_data.append("payload", pay_pDetail);
      }
      if (values.notification_action === "view_package") {
        const pay_pDetail = JSON.stringify({
          type: "action",
          action_name: "view_package",
          package_list_id: values.package_list.values,
        });
        form_data.append("payload", pay_pDetail);
      }
      if (values.notification_action === "view_featured_product_list") {
        const pay_featured_product = JSON.stringify({
          type: "action",
          action_name: "view_featured_product_list",
        });
        form_data.append("payload", pay_featured_product);
      }
      if (values.notification_action === "view_app_store_page") {
        const pay_app_page = JSON.stringify({
          type: "action",
          action_name: "view_app_store_page",
        });
        form_data.append("payload", pay_app_page);
      }
      if (values.notification_action === "view_product_list") {
        let pay_product_list: {
          type: string;
          action_name: string;
          product_category_group_id?: number;
          product_category_id?: number;
          tag_id?: number;
          product_brand_id?: number;
        } = {
          type: "action",
          action_name: "view_product_list",
        };
        if (
          values.checkCategoryGroup &&
          values.product_category_group.values !== 0
        ) {
          pay_product_list.product_category_group_id =
            values.product_category_group.values;
        }
        if (values.checkCategory && values.product_category.values !== 0) {
          pay_product_list.product_category_id = values.product_category.values;
        }
        if (values.checkTag && values.tag.values !== 0) {
          pay_product_list.tag_id = values.tag.values;
        }
        if (values.checkBrand && values.brand.value !== 0) {
          pay_product_list.product_brand_id = values.brand.value;
        }
        form_data.append("payload", JSON.stringify(pay_product_list));
      }
      if (values.notification_action === "view_multi_brand_store") {
        const pay_store = JSON.stringify({
          type: "action",
          action_name: "view_multi_brand_store",
          retail_shop_id: values.store.value,
        });
        form_data.append("payload", pay_store);
      }
      if (values.notification_action === "view_multi_brand_product") {
        const pay_product_store = JSON.stringify({
          type: "action",
          action_name: "view_multi_brand_product",
          retail_product_id: values.product_store.value,
        });
        form_data.append("payload", pay_product_store);
      }

      await mutation.mutate();
    },
  });
  const mutation = useMutation(
    values.radio === "global"
      ? () => NotificationRequest.sendNotifGlobal(form_data, setSubmitting)
      : () => NotificationRequest.sendNotifPersonal(form_data, setSubmitting),
    {
      onMutate: () => {
        console.log("mutate sent notif");
      },
      onSettled: async (data: any, error: any) => {
        if (data) {
          handleReset({});
          setFile(null);
          const removeFile: any = document.getElementsByClassName(
            "filepond--file-action-button filepond--action-remove-item"
          );
          removeFile[0].click();
        }
      },
    }
  );
  return {
    handleChange,
    handleSubmit,
    setValues,
    errors,
    values,
    touched,
    handleReset,
    isSubmitting,
    setSubmitting,
    setFile,
    file,
  };
};

export default FormHandleGeneral;
