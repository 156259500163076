import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../components/button/SmallButton";
import NotificationContext from "../../../../store/admin/notification/NotificationContext";
import NotificationRequest from "../../../../apis/admin/notification/NotificationRequest";
import { IinitialNotificationState } from "../../../../store/admin/notification/interfaces";

export interface ContextProps {
  notificationDispatch: any;
  notificationState: IinitialNotificationState;
}

const DeleteNotif = () => {
  const { notificationDispatch, notificationState } =
    useContext<ContextProps>(NotificationContext);
  const { showDelete } = notificationState;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const goBack = () => {
    notificationDispatch({
      type: "show_delete",
      payload: {
        id: "",
        status: false,
      },
    });
  };
  const deleteTags = async () => {
    await mutation.mutate();
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => NotificationRequest.deleteSchedule(showDelete.id, goBack),
    {
      onMutate: () => {
        setSubmitting(true);
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getNotificationSchedule");
        setSubmitting(false);
        if (error) {
          console.log(error);
          setSubmitting(false);
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white w-1/3 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-7">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <div className="px-10">
          <div className="flex flex-col items-center">
            <h1 className="text-xl">Delete Schedule</h1>
            <h1 className="text-base text-center w-60 text-inputColor mt-4 font-rom">
              Are you sure you want to delete the schedule ?
            </h1>
          </div>

          <div className="flex justify-center my-8 outline-none">
            <SmallButton
              loadColor={"white"}
              type={"button"}
              handleClick={goBack}
              color={"bg-white"}
              border={"border-2"}
              borderColor={"border-breadscrumbColor"}
              hoverColor={"opacity-70"}
              text={"Cancel"}
              textColor={"text-inputColor"}
            />
            <div className="mx-1.5"></div>
            <SmallButton
              isSubmitting={isSubmitting}
              loadColor={"white"}
              type={"button"}
              handleClick={deleteTags}
              color={"bg-softPink"}
              hoverColor={"bg-red-300"}
              text={"Delete"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteNotif;
