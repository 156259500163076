import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Input from "../../../../components/input/Input";
import NewFilePonds from "../../../../components/input/NewFilepond";
import CircularLoad from "../../../../components/loading/CircularLoad";
import * as Yup from "yup";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useMutation, useQueryClient } from "react-query";
import PackageListRequest from "../../../../apis/admin/package_list/PackageListRequest";
import StreetwearCard from "./edit_package_component/StreetwearCard";
import HypeCard from "./edit_package_component/HypeCard";
import DeluxeCard from "./edit_package_component/DeluxeCard";
import ExclusiveCard from "./edit_package_component/ExclusiveCard";
import LuxuryCard from "./edit_package_component/LuxuryCard";

interface IProps {
  data: any;
}

const FormEditPackage: React.FC<IProps> = ({ data }) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const [files, setFiles] = useState<any>(null);
  const [checkStreetwear, setCheckStreetwear] = useState<boolean>(false);
  const [checkHype, setCheckHype] = useState<boolean>(false);
  const [checkDeluxe, setCheckDeluxe] = useState<boolean>(false);
  const [checkExclusive, setCheckExclusive] = useState<boolean>(false);
  const [checkLuxury, setCheckLuxury] = useState<boolean>(false);
  const [streetwear, setStreetwear] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });
  const [hype, setHype] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });
  const [deluxe, setDeluxe] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });
  const [exclusive, setExclusive] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });
  const [luxury, setLuxury] = useState<any>({
    package_coupon_effect_id: null,
    quantity: null,
    validity_period: null,
    package_coupon_name: "",
    legit_check_type_name: "",
  });

  useEffect(() => {
    if (data.coupon_effect.length > 0) {
      for (const key in data.coupon_effect) {
        if (data.coupon_effect[key].legit_check_type_name === "streetwear") {
          setCheckStreetwear(true);
          setStreetwear({
            ...streetwear,
            package_coupon_effect_id:
              data.coupon_effect[key].package_coupon_effect_id,
            quantity: data.coupon_effect[key].quantity,
            validity_period: data.coupon_effect[key].validity_period,
            package_coupon_name: data.coupon_effect[key].package_coupon_name,
            legit_check_type_name:
              data.coupon_effect[key].legit_check_type_name,
          });
        }
        if (data.coupon_effect[key].legit_check_type_name === "hype") {
          setCheckHype(true);
          setHype({
            ...hype,
            package_coupon_effect_id:
              data.coupon_effect[key].package_coupon_effect_id,
            quantity: data.coupon_effect[key].quantity,
            validity_period: data.coupon_effect[key].validity_period,
            package_coupon_name: data.coupon_effect[key].package_coupon_name,
            legit_check_type_name:
              data.coupon_effect[key].legit_check_type_name,
          });
        }
        if (data.coupon_effect[key].legit_check_type_name === "deluxe") {
          setCheckDeluxe(true);
          setDeluxe({
            ...deluxe,
            package_coupon_effect_id:
              data.coupon_effect[key].package_coupon_effect_id,
            quantity: data.coupon_effect[key].quantity,
            validity_period: data.coupon_effect[key].validity_period,
            package_coupon_name: data.coupon_effect[key].package_coupon_name,
            legit_check_type_name:
              data.coupon_effect[key].legit_check_type_name,
          });
        }
        if (data.coupon_effect[key].legit_check_type_name === "exclusive") {
          setCheckExclusive(true);
          setExclusive({
            ...exclusive,
            package_coupon_effect_id:
              data.coupon_effect[key].package_coupon_effect_id,
            quantity: data.coupon_effect[key].quantity,
            validity_period: data.coupon_effect[key].validity_period,
            package_coupon_name: data.coupon_effect[key].package_coupon_name,
            legit_check_type_name:
              data.coupon_effect[key].legit_check_type_name,
          });
        }
        if (data.coupon_effect[key].legit_check_type_name === "luxury") {
          setCheckLuxury(luxury);
          setLuxury({
            ...luxury,
            package_coupon_effect_id:
              data.coupon_effect[key].package_coupon_effect_id,
            quantity: data.coupon_effect[key].quantity,
            validity_period: data.coupon_effect[key].validity_period,
            package_coupon_name: data.coupon_effect[key].package_coupon_name,
            legit_check_type_name:
              data.coupon_effect[key].legit_check_type_name,
          });
        }
      }
    }
    return function () {
      setCheckStreetwear(false);
      setCheckHype(false);
      setCheckDeluxe(false);
      setCheckExclusive(false);
      setCheckLuxury(false);
      setStreetwear({
        package_coupon_effect_id: null,
        quantity: null,
        validity_period: null,
        package_coupon_name: "",
        legit_check_type_name: "",
      });
      setHype({
        package_coupon_effect_id: null,
        quantity: null,
        validity_period: null,
        package_coupon_name: "",
        legit_check_type_name: "",
      });
      setDeluxe({
        package_coupon_effect_id: null,
        quantity: null,
        validity_period: null,
        package_coupon_name: "",
        legit_check_type_name: "",
      });
      setExclusive({
        package_coupon_effect_id: null,
        quantity: null,
        validity_period: null,
        package_coupon_name: "",
        legit_check_type_name: "",
      });
      setLuxury({
        package_coupon_effect_id: null,
        quantity: null,
        validity_period: null,
        package_coupon_name: "",
        legit_check_type_name: "",
      });
    };
  }, [data]);

  useEffect(() => {
    setValues({
      ...values,
      name: data.name,
      real_price: data.real_price,
      buy_price: data.buy_price,
      is_available_to_buy: data.is_available_to_buy,
      available_for: data.available_for,
      description: data.description,
      is_for_insurance: data.is_for_insurance,
    });
  }, [data]);

  const form_data = new FormData();
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      name: "",
      real_price: 0,
      buy_price: 0,
      is_available_to_buy: "true",
      available_for: "0",
      description: "",
      is_for_insurance: "true",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      real_price: Yup.number()
        .required("Real Price is required")
        .min(1, "Minimum price is IDR 1"),
      buy_price: Yup.number()
        .required("Buy Price is required")
        .min(1, "Minimum price is IDR 1"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values: any) => {
      files !== null && form_data.append("image", files);
      form_data.append("name", values.name);
      form_data.append("real_price", values.real_price);
      form_data.append("buy_price", values.buy_price);
      form_data.append("description", values.description);
      form_data.append("available_for", values.available_for);
      form_data.append("is_available_to_buy", values.is_available_to_buy);
      form_data.append("is_for_insurance", values.is_for_insurance);
      checkStreetwear &&
        streetwear.quantity &&
        streetwear.validity_period &&
        streetwear.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(streetwear));
      checkHype &&
        hype.quantity &&
        hype.validity_period &&
        hype.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(hype));
      checkDeluxe &&
        deluxe.quantity &&
        deluxe.validity_period &&
        deluxe.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(deluxe));
      checkExclusive &&
        exclusive.quantity &&
        exclusive.validity_period &&
        exclusive.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(exclusive));
      checkLuxury &&
        luxury.quantity &&
        luxury.validity_period &&
        luxury.package_coupon_effect_id &&
        form_data.append("list_coupon_effects", JSON.stringify(luxury));
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      PackageListRequest.editPackage(
        form_data,
        setSubmitting,
        () => history.goBack(),
        +params.id
      ),
    {
      onMutate: () => {
        console.log("mutate edit package");
      },
      onSettled: async (data: any, error: any) => {
        await queryClient.invalidateQueries("getPackageList");
        if (error) {
          console.log(error);
          handleReset({});
        }
      },
    }
  );

  const handleChangeChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    setCheck: any
  ) => {
    setCheck(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit} className="flex w-full justify-between my-14">
      <div className="md:w-1/3 w-full h-full rounded-md border-2 border-softGray">
        <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">Package Image</h1>
        </div>
        <hr />

        <div className="md:mx-8 mx-5 my-7">
          <div>
            <h1 className="md:text-base text-sm text-mobile text-inputColor">
              Display Image
            </h1>
            <div className="w-full flex justify-center">
              <img
                src={data?.image_path}
                alt="img_banner"
                className="my-3 rounded-lg shadow-lg w-11/12"
              />
            </div>
            <h1 className="md:text-xs font-rom text-inputColor md:mt-2 mt-1 mb-1">
              (4 x 3), (PNG,JPG,JPEG) max. 1 MB
            </h1>
            <NewFilePonds
              setFiles={setFiles}
              imageType={["image/png", "image/jpg", "image/jpeg"]}
              cropRatio={"4:3"}
              maxFileSize={"1MB"}
            />
          </div>
        </div>
      </div>
      <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
        <div className="rounded-md rounded-b-none border-2 border-softGray">
          <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Package List Information
            </h1>
          </div>
          <hr />

          <div className="md:flex w-full">
            <div className="md:px-8 mt-6 md:w-full flex justify-between">
              <div className="w-1/2 mr-7">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">Name</h1>
                  <Input
                    name="name"
                    type="text"
                    handleChange={handleChange}
                    value={values.name}
                    placeholder="Place Holder"
                  />
                  {errors.name && touched.name && (
                    <p className="text-softPink text-xs font-rom -mt-2">
                      {errors.name}
                    </p>
                  )}
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Real Price
                  </h1>
                  <Input
                    name="real_price"
                    type={"number"}
                    handleChange={handleChange}
                    value={values.real_price}
                    placeholder="Place Holder"
                  />
                  {errors.real_price && touched.real_price && (
                    <p className="text-softPink text-xs font-rom -mt-2">
                      {errors.real_price}
                    </p>
                  )}
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Package Status
                  </h1>
                  <select
                    onChange={handleChange}
                    value={values.is_available_to_buy}
                    name={"is_available_to_buy"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="true">On Selling</option>
                    <option value="false">Not Active</option>
                  </select>
                </div>
              </div>
              <div className="w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-inputColor">
                    Package for
                  </h1>
                  <select
                    onChange={handleChange}
                    value={values.is_for_insurance}
                    name={"is_for_insurance"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="true">Insurance</option>
                    <option value="false">Not Insurance</option>
                  </select>
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Buy Price
                  </h1>
                  <Input
                    name="buy_price"
                    type={"number"}
                    handleChange={handleChange}
                    value={values.buy_price}
                    placeholder="Place Holder"
                  />
                  {errors.buy_price && touched.buy_price && (
                    <p className="text-softPink text-xs font-rom -mt-2">
                      {errors.buy_price}
                    </p>
                  )}
                </div>
                <div className="my-1.5">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Available for
                  </h1>
                  <select
                    onChange={handleChange}
                    value={values.available_for}
                    name={"available_for"}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
                  >
                    <option value="0">All Users</option>
                    <option value="1">Consignment</option>
                    <option value="4">Consignment Vip</option>
                    <option value="2">Gift</option>
                    <option value="3">Tester</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="px-8 w-full mb-2.5">
            <h1 className="md:text-base text-sm text-inputColor">
              Terms and Conditions
            </h1>
            <textarea
              name="description"
              onChange={handleChange}
              value={values.description}
              placeholder="Place Holder"
              className="md:h-20 h-14 font-rom w-full rounded-md md:text-sm text-xs my-3 px-2 py-1 border-2 border-softGray shadow-sm focus:ring-1 focus:outline-none cursor-pointer"
            ></textarea>
            {errors.description && touched.description && (
              <p className="text-softPink text-xs font-rom -mt-2.5">
                {errors.description}
              </p>
            )}
          </div>

          <div className="px-8 mb-6">
            <div className="w-full">
              <h1>Select Coupon Effect (Minimal 1)</h1>
              <div className="my-2.5 flex justify-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkStreetwear}
                      onChange={(e: any) =>
                        handleChangeChecked(e, setCheckStreetwear)
                      }
                      name="streetwear"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Streetwear"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkHype}
                      onChange={(e: any) =>
                        handleChangeChecked(e, setCheckHype)
                      }
                      name="hype"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Hype"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkDeluxe}
                      onChange={(e: any) =>
                        handleChangeChecked(e, setCheckDeluxe)
                      }
                      name="deluxe"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Deluxe"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkExclusive}
                      onChange={(e: any) =>
                        handleChangeChecked(e, setCheckExclusive)
                      }
                      name="exclusive"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Exclusive"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkLuxury}
                      onChange={(e: any) =>
                        handleChangeChecked(e, setCheckLuxury)
                      }
                      name="luxury"
                      style={{ color: "#ed4846" }}
                    />
                  }
                  label="Luxury"
                />
              </div>
            </div>

            {checkStreetwear && (
              <StreetwearCard
                setCheckStreetwear={setCheckStreetwear}
                streetwear={streetwear}
                setStreetwear={setStreetwear}
              />
            )}

            {checkHype && (
              <HypeCard
                hype={hype}
                setCheckHype={setCheckHype}
                setHype={setHype}
              />
            )}

            {checkDeluxe && (
              <DeluxeCard
                deluxe={deluxe}
                setDeluxe={setDeluxe}
                setCheckDeluxe={setCheckDeluxe}
              />
            )}

            {checkExclusive && (
              <ExclusiveCard
                exclusive={exclusive}
                setCheckExclusive={setCheckExclusive}
                setExclusive={setExclusive}
              />
            )}

            {checkLuxury && (
              <LuxuryCard
                luxury={luxury}
                setCheckLuxury={setCheckLuxury}
                setLuxury={setLuxury}
              />
            )}
          </div>
        </div>

        <div className="rounded-md rounded-t-none border-2 border-t-0 border-softGray flex w-full md:h-18 h-16 mb-10">
          <div className="w-1/2 flex items-center justify-center border-r-2 border-softGray ">
            <button
              type="reset"
              onClick={() => {
                handleReset({});
                setCheckStreetwear(false);
                setCheckHype(false);
                setCheckDeluxe(false);
                setCheckExclusive(false);
                setCheckLuxury(false);
              }}
              className="focus:outline-none"
            >
              <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                Clear
              </h1>
            </button>
          </div>
          <div className="w-1/2 flex items-center justify-center">
            <button type="submit" className="focus:outline-none">
              {isSubmitting ? (
                <CircularLoad color={"black"} height={16} width={16} />
              ) : (
                <h1 className="md:text-base text-base cursor-pointer hover:text-softPink">
                  Save
                </h1>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormEditPackage;
