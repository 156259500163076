import { Route, Redirect } from "react-router-dom";
import LocalStorage from "../../config/LocalStorage";

const LoginGuard = ({ component: Component, ...rest }) => {
  let value = LocalStorage.AUTH_TOKEN;
  return (
    <Route
      {...rest}
      render={(props) =>
        !value ? (
          <Redirect to={{ pathname: "/login" }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default LoginGuard;
