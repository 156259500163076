export const ConsignmentReducer = (state: any, action: any) => {
  switch (action.type) {
    case "show_accept":
      let accept = action.payload;
      document.body.classList[accept.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAccept: accept };
    case "show_reject":
      let reject = action.payload;
      document.body.classList[reject.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showReject: reject };
    case "show_upgrade":
      let upgrade = action.payload;
      document.body.classList[upgrade.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showUpgrade: upgrade };
    case "show_filter":
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case "handle_asc":
      let new_asc = action.payload;
      return { ...state, isAsc: new_asc };
    case "handle_sort":
      let new_sort = action.payload;
      return { ...state, sortValue: new_sort };
    case "handle_limit":
      let new_limit = action.payload;
      return { ...state, limit: new_limit };
    case "handle_offset":
      let new_offset = action.payload;
      return { ...state, offset: new_offset };
    case "handle_filter":
      let new_filter = action.payload;
      return { ...state, filter: new_filter };
    case "reset_filter":
      let reset_filter = {
        legit_check_code: "",
        product_name: "",
        category: "",
        brand: "",
        status: "",
        transaction_from: "",
        transaction_to: "",
      };
      return { ...state, filter: reset_filter };
    case "handle_chip":
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
