import React from "react";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import DynamicBreadcrumb from "../../../../components/breadcrumb/DynamicBreadcrumb";
import NavDetail from "../../../../components/navbar/NavDetail";
import { optRoutesEdit } from "../helpers/list";
import Loading from "../../../../components/loading/Loading";
import { BrandRequest } from "../../../../apis/admin/brand/BrandRequest";
import FormEditBrand from "./FormEditBrand";

const Editbrand = () => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const goBack = () => {
    history.goBack();
  };

  const { data, isLoading } = useQuery(
    "getBrandDetail",
    () => BrandRequest.getBrandDetail(+params.id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
    }
  );

  const { data: dataCategoryGroup, isLoading: isLoadingCategoryGroup } =
    useQuery("getCategoryGroup", BrandRequest.getCategoryGroup);
  return (
    <div>
      <NavDetail
        title1={"Edit"}
        title2={"Legit Check Brand"}
        handleBack={goBack}
      />
      <div className="pt-28">
        <DynamicBreadcrumb data={optRoutesEdit(params.id)} />

        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <FormEditBrand
            data={data}
            dataCategoryGroup={dataCategoryGroup}
            isLoadingCategoryGroup={isLoadingCategoryGroup}
          />
        )}
      </div>
    </div>
  );
};

export default Editbrand;
