import { createContext } from "react";
import { IinitialAuthDataState, IAuthDataContext } from "./interfaces";

export const initialAuthDataState: IinitialAuthDataState = {
  showEdit: {
    code: "",
    status: false,
  },
  showFilter: false,
  chip: [],
};

const AuthDataContext = createContext<IAuthDataContext>({
  authDataState: initialAuthDataState,
  authDataDispatch: () => {},
});

export const AuthDataContextProvider = AuthDataContext.Provider;
export const AuthDataContextConsumer = AuthDataContext.Consumer;

export default AuthDataContext;
