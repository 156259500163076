import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import NotificationRequest from "../../../../../apis/admin/notification/NotificationRequest";
import * as Yup from "yup";

const FormHandleEditSchedule = (data: any) => {
  const history = useHistory();
  const params: { id: string } = useParams();
  const [file, setFile] = useState<any>(null);
  const [brand, setBrand] = useState<boolean>(false);
  const [tag, setTag] = useState<boolean>(false);
  const [category, setCategory] = useState<boolean>(false);
  const [category_group, setCategoryGroup] = useState<boolean>(false);
  const form_data = new FormData();

  useEffect(() => {
    setValues({
      ...values,
      subject: data?.subject,
      description: data?.description,
      username: {
        values: data?.recipient_payload?.user_id,
        label: data?.recipient_payload?.username,
      },
      radio: data?.is_global ? "global" : "personal",
      type: data?.is_recurring ? "repeat" : "single",
      recurring_types: data?.is_recurring && data?.recurring_types[0],
      scheduled_sent_time: moment(data?.scheduled_sent_time).format(
        "YYYY-MM-DD"
      ),
      time: moment(data?.scheduled_sent_time).format("HH:mm:ss"),
      notification_action: data?.payload?.action_name,
      product_category: {
        values: data?.payload?.product_category?.id || 0,
        label: data?.payload?.product_category?.name || "Select Here",
      },
      product_category_group: {
        values: data?.payload?.product_category_group?.id || 0,
        label: data?.payload?.product_category_group?.name || "Select Here",
      },
      tag: {
        values: data?.payload?.product_tag?.id || 0,
        label: data?.payload?.product_tag?.name || "Select Here",
      },
      brand: {
        values: data?.payload?.product_brand?.id || 0,
        label: data?.payload?.product_brand?.name || "Select Here",
      },
      product_store: {
        value: data?.payload?.retail_product?.id || 0,
        label: data?.payload?.retail_product?.name || "Select Here",
      },
      package_list: {
        values: data?.payload?.package_list?.id || 0,
        label: data?.payload?.package_list?.name || "Select Here",
      },
      store: {
        value: data?.payload?.retail_shop?.id || 0,
        label: data?.payload?.retail_shop?.name || "Select Here",
      },
      product_detail: {
        values: data?.payload?.product?.id || 0,
        label: data?.payload?.product?.name || "Select Here",
      },
    });

    return function () {
      setValues({
        ...values,
        radio: "global",
        type: "single",
        username: {
          values: 0,
          label: "Select Here",
        },
        checkCategoryGroup: false,
        checkCategory: false,
        checkBrand: false,
        checkTag: false,
        subject: "",
        description: "",
        notification_action: "0",
        recurring_types: "every_day",
        scheduled_sent_time: "",
        time: "",
        product_detail: {
          values: 0,
          label: "Select Here",
        },
        package_list: {
          values: 0,
          label: "Select Here",
        },
        product_category_group: {
          values: 0,
          label: "Select Here",
        },
        product_category: {
          values: 0,
          label: "Select Here",
        },
        brand: {
          values: 0,
          label: "Select Here",
        },
        tag: {
          values: 0,
          label: "Select Here",
        },
        store: {
          value: 0,
          label: "Select Here",
        },
        product_store: {
          value: 0,
          label: "Select Here",
        },
      });
    };
  }, [data]);

  useEffect(() => {
    data?.payload?.product_brand?.id > 0 && setBrand(true);
    data?.payload?.tag?.id > 0 && setTag(true);
    data?.payload?.product_category?.id > 0 && setCategory(true);
    data?.payload?.product_category_group?.id > 0 && setCategoryGroup(true);
    return () => {
      setBrand(false);
      setTag(false);
      setCategory(false);
      setCategoryGroup(false);
    };
  }, [data]);

  const {
    handleChange,
    handleSubmit,
    setValues,
    errors,
    values,
    touched,
    handleReset,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      radio: "global",
      type: "single",
      username: {
        values: 0,
        label: "Select Here",
      },
      checkCategoryGroup: false,
      checkCategory: false,
      checkBrand: false,
      checkTag: false,
      subject: "",
      description: "",
      notification_action: "0",
      recurring_types: "every_day",
      scheduled_sent_time: "",
      time: "",
      product_detail: {
        values: 0,
        label: "Select Here",
      },
      package_list: {
        values: 0,
        label: "Select Here",
      },
      product_category_group: {
        values: 0,
        label: "Select Here",
      },
      product_category: {
        values: 0,
        label: "Select Here",
      },
      brand: {
        values: 0,
        label: "Select Here",
      },
      tag: {
        values: 0,
        label: "Select Here",
      },
      store: {
        value: 0,
        label: "Select Here",
      },
      product_store: {
        value: 0,
        label: "Select Here",
      },
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: async (values: any) => {
      file !== null && form_data.append("image", file);
      form_data.append("subject", values.subject);
      form_data.append("description", values.description);
      if (values.radio === "global") {
        form_data.append("topic", "global_user");
      }
      if (values.radio === "personal" && values.username.values !== 0) {
        form_data.append("user_id", values.username.values);
        form_data.append("user_role", "user");
        const dateTime = moment(
          `${values.scheduled_sent_time} ${values.time}`,
          "YYYY-MM-DD HH:mm:ss"
        ).format();
        form_data.append("scheduled_sent_time", dateTime);
      }
      if (values.radio === "global" && values.type === "single") {
        const dateTime = moment(
          `${values.scheduled_sent_time} ${values.time}`,
          "YYYY-MM-DD HH:mm:ss"
        ).format();
        form_data.append("scheduled_sent_time", dateTime);
      } else if (values.radio === "global" && values.type === "repeat") {
        const dateTime = moment(
          `'3021-08-31' ${values.time}`,
          "YYYY-MM-DD HH:mm:ss"
        ).format();
        form_data.append("scheduled_sent_time", dateTime);
        form_data.append("recurring_types[]", values.recurring_types);
      }
      if (values.notification_action === "view_product_detail") {
        const pay_pDetail = JSON.stringify({
          type: "action",
          action_name: "view_product_detail",
          product_id: values.product_detail.value,
        });
        form_data.append("payload", pay_pDetail);
      }
      if (values.notification_action === "view_package") {
        const pay_pDetail = JSON.stringify({
          type: "action",
          action_name: "view_package",
          package_list_id: values.package_list.values,
        });
        form_data.append("payload", pay_pDetail);
      }
      if (values.notification_action === "view_featured_product_list") {
        const pay_featured_product = JSON.stringify({
          type: "action",
          action_name: "view_featured_product_list",
        });
        form_data.append("payload", pay_featured_product);
      }
      if (values.notification_action === "view_app_store_page") {
        const pay_app_page = JSON.stringify({
          type: "action",
          action_name: "view_app_store_page",
        });
        form_data.append("payload", pay_app_page);
      }
      if (values.notification_action === "view_product_list") {
        let pay_product_list: {
          type: string;
          action_name: string;
          product_category_group_id?: number;
          product_category_id?: number;
          tag_id?: number;
          product_brand_id?: number;
        } = {
          type: "action",
          action_name: "view_product_list",
        };
        if (category_group && values.product_category_group.values !== 0) {
          pay_product_list.product_category_group_id =
            values.product_category_group.values;
        }
        if (category && values.product_category.values !== 0) {
          pay_product_list.product_category_id = values.product_category.values;
        }
        if (tag && values.tag.values !== 0) {
          pay_product_list.tag_id = values.tag.values;
        }
        if (brand && values.brand.value !== 0) {
          pay_product_list.product_brand_id = values.brand.value;
        }
        form_data.append("payload", JSON.stringify(pay_product_list));
      }
      if (values.notification_action === "view_multi_brand_store") {
        const pay_store = JSON.stringify({
          type: "action",
          action_name: "view_multi_brand_store",
          retail_shop_id: values.store.value,
        });
        form_data.append("payload", pay_store);
      }
      if (values.notification_action === "view_multi_brand_product") {
        const pay_product_store = JSON.stringify({
          type: "action",
          action_name: "view_multi_brand_product",
          retail_product_id: values.product_store.value,
        });
        form_data.append("payload", pay_product_store);
      }

      await mutation.mutate();
    },
  });

  const mutation = useMutation(
    () =>
      NotificationRequest.updateNotif(form_data, setSubmitting, params.id, () =>
        history.goBack()
      ),
    {
      onMutate: () => {
        console.log("mutate edit notif");
      },
    }
  );
  return {
    handleChange,
    handleSubmit,
    setValues,
    errors,
    values,
    touched,
    handleReset,
    isSubmitting,
    setFile,
    brand,
    setBrand,
    tag,
    setTag,
    category,
    setCategory,
    category_group,
    setCategoryGroup,
  };
};

export default FormHandleEditSchedule;
