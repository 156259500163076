import React from "react";
import { Route, Switch } from "react-router-dom";
import AddPackage from "../../../pages/admin/package_list/add_package_list/AddPackage";
import EditPackage from "../../../pages/admin/package_list/edit_package_list/EditPackage";
import PackageList from "../../../pages/admin/package_list/package_list_component/PackageList";

const PackageListRoute = () => {
  return (
    <div className="font-med">
      <Switch>
        <Route path="/package-list" exact component={PackageList} />
        <Route path="/package-list/add-package" exact component={AddPackage} />
        <Route
          path="/package-list/edit-package/:id"
          exact
          component={EditPackage}
        />
      </Switch>
    </div>
  );
};

export default PackageListRoute;
