import ToastHandler from "../../../components/toast/ToastHandler";
import axiosCovid from "../../../config/AxiosCovid";

class ConsignmentRequest {
  static getConsignment = async (
    key: string,
    sortValue: string,
    isAsc: string,
    limit: number,
    offset: number,
  ) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosCovid().get("admin/consignment", {
        params: {
          limit: query.get("limit") !== null ? query.get("limit") : limit,
          offset: query.get("offset") !== null ? query.get("offset") : offset,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : sortValue
          } ${query.get("isAsc") !== null ? query.get("isAsc") : isAsc}`,
        },
      });
      const data = results.data
      return data;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static getConsignmentDetail = async (id: string) => {
    try {
      const res = await axiosCovid().get(`admin/consignment/${id}`);
      const result = res.data;
      return result;
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
    }
  };

  static upgradeConsignment = async (
    id: string,
    goBack: any,
    setSubmitting: any
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().post(`admin/consignment/${id}/approve/vip`);
      ToastHandler("#00C62E", "Consignment Upgraded");
      setSubmitting(false);
      goBack();
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
      setSubmitting(false);
    }
  };

  static acceptConsignment = async (
    id: string,
    goBack: any,
    setSubmitting: any
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().post(`admin/consignment/${id}/approve`);
      ToastHandler("#00C62E", "Consignment Accepted");
      setSubmitting(false);
      goBack();
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
      setSubmitting(false);
    }
  };

  static rejectConsignment = async (
    id: string,
    goBack: any,
    setSubmitting: any,
    values: any
  ) => {
    try {
      setSubmitting(true);
      await axiosCovid().post(`admin/consignment/${id}/reject`, {
        rejected_reason: values.rejected_reason,
      });
      ToastHandler("#00C62E", "Consignment Rejected");
      setSubmitting(false);
      goBack();
    } catch (error) {
      ToastHandler("#DF5354", error.response.data.errors);
      setSubmitting(false);
    }
  };
}

export default ConsignmentRequest;
