import Input from "../../../../../components/input/Input";
import { loadCouponEffect } from "../../../../../helpers/loadOption/LoadOption";
import { handleChangeCoupon } from "../../helpers/FormHandle";
import AsyncSelect from "react-select/async";

export interface HypeCardProps {
    setCheckHype: (check: boolean) => void;
    setHype: (hype: any) => void;
    hype: any;
}
 
const HypeCard: React.FC<HypeCardProps> = ({hype, setCheckHype, setHype}) => {
    return ( 
        <div className="my-5">
        <div className="flex justify-between items-end">
          <h1 className="text-2xl">Hype</h1>
          <h1
            onClick={() => setCheckHype(false)}
            className="hover:text-softPink cursor-pointer"
          >
            Delete
          </h1>
        </div>
        <hr className="my-3" />
        <div className="pt-2">
          <div className="flex justify-between">
            <div className="w-1/2">
              <h1 className="md:text-base text-sm text-inputColor">
                Quantity
              </h1>
              <Input
                name="quantity"
                type="number"
                value={hype.quantity}
                handleChange={(e: any) =>
                  setHype({
                    ...hype,
                    quantity: +e.target.value,
                  })
                }
                placeholder="Place Holder"
              />
            </div>
            <div className="w-1/2 ml-7">
              <h1 className="md:text-base text-sm text-inputColor">
                Validity Period (Day)
              </h1>
              <Input
                name="validity_period"
                type="number"
                value={hype.validity_period}
                handleChange={(e: any) =>
                  setHype({
                    ...hype,
                    validity_period: +e.target.value,
                  })
                }
                placeholder="Place Holder"
              />
            </div>
          </div>
          <div className="w-full">
            <div>
              <h1 className="md:text-base text-sm text-inputColor">
                Package Effect
              </h1>
              <AsyncSelect
                cacheOptions
                value={{
                  value: hype.package_coupon_effect_id,
                  label: hype.package_coupon_name,
                }}
                loadOptions={(inputValue: any) =>
                  loadCouponEffect(inputValue, "hype")
                }
                onChange={(coupon) =>
                  handleChangeCoupon(coupon, "hype", setHype, hype)
                }
                className="my-3.5 font-rom text-sm"
              />
            </div>
          </div>
        </div>
      </div>
   
     );
}
 
export default HypeCard;