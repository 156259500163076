import { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import NotificationRequest from "../../../../../apis/admin/notification/NotificationRequest";
import { useQueries } from "../../../../../helpers/query_searchParams/QuerySearchParams";
import NotificationContext from "../../../../../store/admin/notification/NotificationContext";

const UseFetchNotifSchedule = () => {
  const { notificationDispatch } = useContext<any>(NotificationContext);
  const query = useQueries(useLocation);

  const { data, isLoading, isFetching } = useQuery(
    [
      "getNotificationSchedule",
      query.get("is_scheduled"),
      query.get("limit"),
      query.get("offset"),
      query.get("sort"),
      query.get("isAsc"),
    ],
    () => NotificationRequest.getNotificationScheduled(notificationDispatch),
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
      refetchOnReconnect: true,
    }
  );
  return { data, isLoading, isFetching };
};

export default UseFetchNotifSchedule;
