export const optRoutes = [
  {
    id: 1,
    path: "/legit-check-type",
    name: "Home",
  },
  {
    id: 2,
    path: "/legit-check-type",
    name: "Type",
  },
];
