import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { breadcrumbnext } from "../../assets/icons";

interface IProps {
  data: any[];
}

const DynamicBreadcrumb: React.FC<IProps> = ({ data }) => {
  const { url } = useRouteMatch();

  return (
    <div className="flex flex-row justify-start text-breadscrumbColor items-center md:text-base text-sm">
      {data.map((el: any) => (
        <ul className="flex flex-row items-center" key={el.id}>
          <li className="inline-flex items-center mr-1">
            <Link
              className={
                el.path === url
                  ? "text-black cursor-default"
                  : "hover:text-black"
              }
              to={{ pathname: el.path, search: el.search, hash: el.hash }}
            >
              {el.name}
            </Link>
          </li>
          {el.id !== data.length ? (
            <li className="inline-flex items-center">
              <img src={breadcrumbnext} alt="next" className="h-3 w-3 mr-1" />
            </li>
          ) : (
            ""
          )}
        </ul>
      ))}
    </div>
  );
};

export default DynamicBreadcrumb;
