import React from "react";
import { Link, useLocation } from "react-router-dom";
import DynamicBreadcrumb from "../../../components/breadcrumb/DynamicBreadcrumb";
import General from "./general/General";
import { optRoutes } from "./helpers/list";
import Schedule from "./schedule/Schedule";

const Notification = () => {
  const { hash } = useLocation();
  return (
    <div className="md:pt-28 pt-14">
      <DynamicBreadcrumb data={optRoutes} />

      <div className="mt-16 mb-5 w-full grid grid-cols-2 gap-10">
        <div className="flex flex-col">
          <Link
            to={{
              hash: "#general",
            }}
            className="self-center cursor-pointer"
          >
            General
          </Link>
        </div>
        <div className="flex flex-col">
          <Link
            to={{
              hash: "#schedule",
              search: "limit=10&&offset=0&&sort=created_at&&isAsc=desc",
            }}
            className="self-center cursor-pointer"
          >
            Schedule
          </Link>
        </div>
      </div>
      <div>
        <hr
          className={
            "border-t-4 border-solid border-softPink w-1/2 transform-gpu transition duration-300 " +
            (hash === "#schedule" && "translate-x-full")
          }
        />
        <hr className="border-t-2 -mt-0.5 border-solid" />
      </div>
      <div>
        <div>{hash === "#general" && <General />}</div>
        <div>{hash === "#schedule" && <Schedule />}</div>
      </div>
    </div>
  );
};

export default Notification;
