import { useState } from "react";
import { down, up } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";

export interface ConsignmentInfoProps {
  data: any;
}

const ConsignmentInfo: React.FC<ConsignmentInfoProps> = ({ data }) => {
  const [dropdown, setdropdown] = useState<boolean>(true);

  const handledropdown = () => {
    setdropdown(!dropdown);
  };

  return (
    <>
      <div className="md:w-1/3 w-full h-full rounded-md border-2 border-softGray">
        <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">Consignment Logo</h1>
        </div>
        <hr />

        <div className="md:mx-8 mx-5 md:my-6 my-5">
          <div className="flex flex-col w-full items-center">
            <h1 className="md:text-base text-sm text-inputColor mb-4 self-start">
              Display Image
            </h1>
            <img
              src={data.logo_image_path}
              alt="logo_image_path"
              className="rounded-md shadow-md w-11/12"
            />
          </div>
        </div>
      </div>

      <div className="md:w-detailContainer md:mt-0 mt-10 w-full h-full">
        <div className="rounded-md border-2 border-softGray">
          <div className="h-16 flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-lg text-base">
              Consignment Information
            </h1>

            <div className="flex justify-between items-center">
              {dropdown ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdown}
                />
              ) : (
                <img
                  src={down}
                  alt="down"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdown}
                />
              )}
            </div>
          </div>
          {dropdown ? <hr className="border-t-2" /> : ""}

          {dropdown ? (
            <div className="py-2">
              <div className="md:flex w-full mt-4">
                {/*transactions data left*/}
                <div className="md:mx-8 mx-5 md:w-1/2">
                  <div>
                    <h1 className="md:text-base text-sm text-inputColor">
                      Store Name
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"id"}
                      value={data?.store_name}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Request as Consignment at
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"email"}
                      value={data?.user?.email}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Status
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"method"}
                      value={
                        data.is_verified
                          ? "Verified"
                          : data.is_expired
                          ? "Expired"
                          : !data.is_verified && !data.is_expired
                          ? "Pending"
                          : "-"
                      }
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Address
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"address"}
                      value={data?.address}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Province
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"address_province_name"}
                      value={data?.address_province_name}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Instagram
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"instagram"}
                      value={data.instagram}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Joined as Consignment at
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"date"}
                      // value={moment(data?.created_at).format('lll')}
                      placeholder={"Place holder"}
                    />
                  </div>
                </div>

                {/*transactions data right*/}
                <div className="md:mx-8 mx-5 md:w-1/2">
                  <div>
                    <h1 className="md:text-base text-sm text-inputColor">
                      Owner Name
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"owner_name"}
                      value={data?.owner_name}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Verification Expired Before
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"gender"}
                      value={data?.user?.gender}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Verification using
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"verification_using"}
                      value={data?.verification_using}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      City
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"address_city_name"}
                      value={data?.address_city_name}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Consignment Type
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"by"}
                      value={
                        data.consignment_store && data.reseller_store
                          ? "Consignment & Reseller"
                          : data.reseller_store
                          ? "Reseller"
                          : data.consignment_store
                          ? "Consignment"
                          : "-"
                      }
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Website
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"website"}
                      value={data.website || "-"}
                      placeholder={"Place holder"}
                    />
                  </div>
                  <div className="my-4 mb-5">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Joined as Consignment VIP at
                    </h1>
                    <Input
                      disable={true}
                      type={"text"}
                      name={"by"}
                      value={data?.user?.verified_phone}
                      placeholder={"Place holder"}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default ConsignmentInfo;
