import { createContext } from "react";
import { ITypeContext, IinitialTypeState } from "./interfaces";

export const initialTypeState: IinitialTypeState = {
  showEdit: {
      id: 0,
      status: false
  },
};

const TypeContext = createContext<ITypeContext>({
  typeState: initialTypeState,
  typeDispatch: () => {},
});

export const TypeContextProvider = TypeContext.Provider;
export const TypeContextConsumer = TypeContext.Consumer;

export default TypeContext;
