import React, { useContext } from "react";
import { cancel } from "../../../../assets/icons";
import Input from "../../../../components/input/Input";
import AuthDataContext from "../../../../store/authentication_data/AuthDataContext";
import AsyncSelect from "react-select/async";
import AuthDataRequest from "../../../../apis/authData/AuthDataRequest";
import { useFormik } from "formik";
import SmallButton from "../../../../components/button/SmallButton";
import {
  loadOptionsCategory,
  loadOptionsProduct,
  loadOptionsBrand,
} from "../../../../helpers/loadOption/LoadOption";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

const AuthDataEdit: React.FC = () => {
  const params: { id: string } = useParams();
  const context = useContext(AuthDataContext);
  const { authDataDispatch, authDataState } = context;
  const { showEdit } = authDataState;
  const { values, handleChange, handleSubmit, setValues, dirty } = useFormik({
    initialValues: {
      update_product_type: "select",
      product_id: "",
      legit_check_brand_id: "",
      product_category_id: "",
      name: "",
    },
    onSubmit: async (values: any) => {
      await mutation.mutate(values);
    },
  });

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => AuthDataRequest.patchAuthData(showEdit.code, values, handleHideModal),
    {
      onSettled: async (data: any, error: any) => {
        if (data && params.id) {
          await queryClient.invalidateQueries("getAuthDetail");
        } else if (data) {
          await queryClient.invalidateQueries("getAuthData");
        }
        if (error) {
          console.log(error);
        }
      },
    }
  );

  const handleHideModal = () => {
    authDataDispatch({
      type: "show_edit",
      payload: {
        code: "",
        status: false,
      },
    });
  };

  return (
    <div>
      {showEdit.status === false ? (
        ""
      ) : (
        <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
          <form
            onSubmit={handleSubmit}
            className="bg-white md:rounded-lg w-1/3 h-auto rounded-t-none rounded-b-lg overflow-hidden shadow-xl transform transition-all"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="px-10 py-7 flex justify-between w-full">
              <h1
                className="md:text-2xl text-lg leading-6 font-medium text-inputColor"
                id="modal-headline"
              >
                Edit Product
              </h1>
              <img
                src={cancel}
                alt="back"
                className="cursor-pointer md:w-5 w-3"
                onClick={handleHideModal}
              />
            </div>
            <hr className="w-full" />
            <div
              className={
                "px-10 w-full overflow-auto " +
                (values.update_product_type === "select" ? "h-60 pt-5" : "py-5")
              }
            >
              <div className="w-full">
                <h1 className="md:text-base text-sm text-inputColor">
                  Choose Type
                </h1>
                <select
                  onChange={handleChange}
                  name="update_product_type"
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-softGray focus:ring-2 cursor-pointer focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 appearance-none"
                >
                  <option value="select">Product Code (SKU) / Name</option>
                  <option value="manual">Manual Input</option>
                </select>
              </div>
              {values.update_product_type === "select" ? (
                <div className="w-full my-2">
                  <h1 className="md:text-base text-sm text-inputColor">
                    Product
                  </h1>
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptionsProduct}
                    onChange={(e: any) =>
                      setValues({ ...values, product_id: e.value })
                    }
                    className="my-3.5 font-rom text-sm"
                  />
                </div>
              ) : (
                <div>
                  <div className="w-full my-2">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Product Name
                    </h1>
                    <Input
                      type={"text"}
                      placeholder={"Place holder"}
                      name={"name"}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className="w-full my-2">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Product Brand
                    </h1>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptionsBrand}
                      onChange={(e: any) =>
                        setValues({ ...values, legit_check_brand_id: e.value })
                      }
                      className="my-3 font-rom text-sm"
                    />
                  </div>
                  <div className="w-full my-2">
                    <h1 className="md:text-base text-sm text-inputColor">
                      Product Category
                    </h1>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions
                      loadOptions={loadOptionsCategory}
                      onChange={(e: any) =>
                        setValues({ ...values, product_category_id: e.values })
                      }
                      className="my-3 font-rom text-sm"
                    />
                  </div>
                </div>
              )}
            </div>
            <hr className="w-full" />
            <div className="flex justify-center w-full py-5">
              <SmallButton
                width="40"
                height={10}
                isSubmitting={mutation.isLoading}
                dirty={dirty}
                loadColor={"white"}
                type={"submit"}
                color={"bg-softPink"}
                hoverColor={"bg-red-300"}
                text={"Edit"}
                textColor={"text-white"}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AuthDataEdit;
