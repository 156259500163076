import { createContext } from "react";
import { IPackageListContext, IinitialPackageListState } from "./interfaces";

export const initialPackageListState: IinitialPackageListState = {
  showSend: false,
  showFilter: false,
  isAsc: "desc",
  sortValue: "created_at",
  limit: 10,
  offset: 0,
  filter: {
    available_for: "",
    package_name: "",
    status: "",
  },
  chip: [],
};

const PackageListContext = createContext<IPackageListContext>({
  packageListState: initialPackageListState,
  packageListDispatch: () => {},
});

export const PackageListContextProvider = PackageListContext.Provider;
export const PackageListContextConsumer = PackageListContext.Consumer;

export default PackageListContext;
